import type { NutritionLabel } from "./types";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const IMAGES_URL = `${process.env.REACT_APP_CDN_URL}/images/` || "";
export const VIDEOS_URL = `${process.env.REACT_APP_CDN_URL}/videos/` || "";
export const API_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/qr` || "";

export const NUTRITIONS_LABEL: NutritionLabel[] = [
  {
    defaultUnit: "g",
    id: "nutri_totfat",
    label: "Total Fat",
  },
  {
    defaultUnit: "g",
    id: "nutri_saturfat",
    label: "Saturated Fat",
  },
  {
    defaultUnit: "g",
    id: "nutri_transfat",
    label: "Trans Fat",
  },
  {
    defaultUnit: "mg",
    id: "nutri_choles",
    label: "Cholesterol",
  },
  {
    defaultUnit: "mg",
    id: "nutri_sodium",
    label: "Sodium",
  },
  {
    defaultUnit: "g",
    id: "nutri_totcarbo",
    label: "Total Carbohydrate",
  },
  {
    defaultUnit: "g",
    id: "nutri_dietfiber",
    label: "Dietary Fiber",
  },
  {
    defaultUnit: "g",
    id: "nutri_totsugar",
    label: "Total Sugars",
  },
  {
    defaultUnit: "mcg",
    id: "nutri_vitd",
    label: "Vitamin D",
  },
  {
    defaultUnit: "mcg",
    id: "nutri_vitc",
    label: "Vitamin C",
  },
  {
    defaultUnit: "g",
    id: "nutri_protein",
    label: "Protein",
  },
  {
    defaultUnit: "mg",
    id: "nutri_calcium",
    label: "Calcium",
  },
  {
    defaultUnit: "mg",
    id: "nutri_iron",
    label: "Iron",
  },
  {
    defaultUnit: "mg",
    id: "nutri_potass",
    label: "Potassium",
  },
];

export const STAT_ORDER = [
  {
    id: "stat_waiting",
    title: [
      {
        lang: "en",
        content: "Waiting for Approval",
      },
      {
        lang: "ar",
        content: "بانتظار الموافقة",
      },
    ],
  },
  {
    id: "stat_accepted",
    title: [
      {
        lang: "en",
        content: "Accepted",
      },
      {
        lang: "ar",
        content: "تمت الموافقة",
      },
    ],
  },
  {
    id: "stat_preparing",
    title: [
      {
        lang: "en",
        content: "Preparing",
      },
      {
        lang: "ar",
        content: "يتم التحضير",
      },
    ],
  },
  {
    id: "stat_ready",
    title: [
      {
        lang: "en",
        content: "Ready",
      },
      {
        lang: "ar",
        content: "طلبك جاهز",
      },
    ],
  },
  {
    id: "stat_cancelled",
    title: [
      {
        lang: "en",
        content: "Cancelled",
      },
      {
        lang: "ar",
        content: "طلبك ملغي",
      },
    ],
  },
  {
    id: "stat_ondelivery",
    title: [
      {
        lang: "en",
        content: "On Delivery",
      },
      {
        lang: "ar",
        content: "في الطريق إليك",
      },
    ],
  },
  {
    id: "stat_atdelivery",
    title: [
      {
        lang: "en",
        content: "At Delivery",
      },
      {
        lang: "ar",
        content: "في الطريق إليك",
      },
    ],
  },
  {
    id: "stat_delivered",
    title: [
      {
        lang: "en",
        content: "Delivered",
      },
      {
        lang: "ar",
        content: "تم التوصيل",
      },
    ],
  },
];
