import { useHistory } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/logo.svg";

function NotFoundPage() {
  const { push } = useHistory();

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <button type="button" onClick={() => push("/")}>
        <Logo className="h-16 w-16 mb-8" />
      </button>
      <p className="font-bold text-2xl">404</p>
      <p>Page Not Found</p>
    </div>
  );
}

export default NotFoundPage;
