import clsx from "clsx";
import {
  memo, type ChangeEvent, useEffect, useState,
} from "react";

import { ReactComponent as CashIcon } from "../../../assets/icons/ic_payment_cash.svg";
import { ReactComponent as CreditIcon } from "../../../assets/icons/ic_payment_credit.svg";
import { useGetPaymentListQuery } from "../../../services";
import { useAppSelector } from "../../../app/hooks";

interface PaymentTypeFormProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  required?: boolean;
  cardText?: {
    cash: {
      title: string;
      description: string;
    };
    credit: {
      title: string;
      description: string;
    }
  }
}

function CartLoadingAnimation() {
  return (
    <>
      <div
        className={clsx(
          "flex items-start w-full gap-4",
          // isRTL ? "flex-row-reverse" : "flex-row",
          "flex-row",
          "relative p-4 shadow-card-item rounded cursor-pointer",
          "transition-all duration-200 ease-in-out",
          "border hover:border-primary focus:border-primary",
          "border-transparent",
        )}
      >
        <div className="bg-gray skeleton h-9 min-w-[36px] rounded-md" />
        <div className="w-full">
          <div className="bg-gray skeleton h-[28px] w-20 rounded-md mb-2" />
          <div className="bg-gray skeleton h-6 w-full rounded-md" />
        </div>
        <div className={clsx(
          "min-w-[24px] h-6",
          "rounded-full bg-gray skeleton",
        )}
        />
      </div>
      <div
        className={clsx(
          "flex items-start w-full gap-4",
          // isRTL ? "flex-row-reverse" : "flex-row",
          "flex-row",
          "relative p-4 shadow-card-item rounded cursor-pointer",
          "transition-all duration-200 ease-in-out",
          "border hover:border-primary focus:border-primary",
          "border-transparent",
        )}
      >
        <div className="bg-gray skeleton h-9 min-w-[36px] rounded-md" />
        <div className="w-full">
          <div className="bg-gray skeleton h-[28px] w-20 rounded-md mb-2" />
          <div className="bg-gray skeleton h-6 w-full rounded-md" />
        </div>
        <div className={clsx(
          "min-w-[24px] h-6",
          "rounded-full bg-gray skeleton",
        )}
        />
      </div>
    </>
  );
}

const PaymentTypeForm = memo(({
  onChange, value, required, cardText,
}: PaymentTypeFormProps) => {
  const { data, isLoading } = useGetPaymentListQuery();
  const { currentLanguage } = useAppSelector(({ globalState }) => globalState);

  const isRTL = currentLanguage?.code === "ar";

  const [payments, setPayments] = useState(
    [
      {
        type: "cash",
        name: "payment_type",
        value: "pay_cash",
        title: isRTL ? "الدفع نقدًا" : "Cash",
        isShow: false,
        description: isRTL ? "اطلب الفاتورة للدفع نقدًا" : "Request the check to pay with cash ",
        icon: CashIcon,
      },
      {
        type: "credit",
        name: "payment_type",
        value: "pay_credit",
        title: isRTL ? " بطاقة الائتمان" : "Credit Card ",
        isShow: false,
        description: isRTL ? "اطلب الفاتورة للدفع ببطاقة الائتمان" : "Request the check to pay with credit card",
        icon: CreditIcon,
      },
    ],
  );

  useEffect(() => {
    if (data?.data) {
      const isShow = (id: string) => !!(data?.data?.find((payId) => payId === id));

      setPayments((prevState) => prevState.map((payment) => {
        if (isShow(payment.value)) {
          return {
            ...payment,
            isShow: true,
          };
        }

        return payment;
      }));
    }
  }, [data]);

  // const payments = [
  //   {
  //     type: "cash",
  //     name: "payment_type",
  //     value: "pay_cash",
  //     title: "Cash",
  //     description: "Request the check to pay with cash ",
  //     icon: CashIcon,
  //   },
  //   {
  //     type: "credit",
  //     name: "payment_type",
  //     value: "pay_credit",
  //     title: " Credit Card ",
  //     description: "Request the check to pay with credit card",
  //     icon: CreditIcon,
  //   },
  // ];

  return (
    <div
      className="flex flex-col w-full gap-4"
      style={{
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      {isLoading && <CartLoadingAnimation />}

      {payments.map(({
        type, name, value: paymentValue, title, description, icon: Icon, isShow,
      }, index) => {
        const key = index.toString();

        return (
          <label
            htmlFor={paymentValue}
            className={clsx(
              "flex items-start w-full gap-4",
              // isRTL ? "flex-row-reverse" : "flex-row",
              "flex-row",
              "relative p-4 shadow-card-item rounded cursor-pointer",
              "transition-all duration-200 ease-in-out",
              "border hover:border-primary focus:border-primary",
              value === paymentValue ? "border-primary" : "border-transparent",
              isLoading ? "opacity-0 cursor-not-allowed" : (isShow ? "opacity-100 cursor-pointer" : "opacity-0 hidden invisble"),
            )}
            key={key}
            title={`Pay with ${title}`}
          >
            <Icon className="min-w-[36px] h-9" />

            <div className="w-full">
              <input
                type="radio"
                name={name}
                id={paymentValue}
                // hidden
                className="opacity-0 absolute left-0 top-0 w-full h-full"
                value={paymentValue}
                checked={value === paymentValue}
                onChange={onChange}
                required={required}
              />

              <p className="text-xl">{cardText ? cardText[type as "cash" | "credit"].title : title}</p>
              <p>{cardText ? cardText[type as "cash" | "credit"].description : description}</p>
            </div>

            <div className={clsx(
              "min-w-[24px] h-6",
              "rounded-full border border-primary",
              value === paymentValue && "bg-primary",
            )}
            />
          </label>
        );
      })}
    </div>
  );
});

export default PaymentTypeForm;
