import { OrderStatusResponseType } from "../../app/types";

import { ReactComponent as CheckIcon } from "../../assets/icons/ic_check.svg";

interface Props {
  data: Array<OrderStatusResponseType & {
    title?: {
      lang: string;
      content: string;
    }[] | string;
  }>;
  lang: string;
}

function CardOrderStatus({ data, lang }: Props) {
  const lastIndex = data.length - 1;
  const isRTL = lang === "ar";

  return (
    <ol
      className="relative text-gray-500 w-full px-6 py-8 bg-white shadow-card-order-status rounded-[28px]"
      style={{
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      {data.map(({
        id, subtitle, is_on, title,
      }, index) => {
        const isLast = index === lastIndex;
        const titleByLang = Array.isArray(title) ? (title?.find(({ lang: titleLang }) => titleLang === lang)?.content || "") : title;

        return (
          <li
            key={id}
            className={`w-full flex flex-row items-center p-4 relative ${isLast ? "" : "pb-10"} ${isRTL ? "mr-12" : "ml-12"}`}
          >
            {(() => {
              switch (index) {
              case 0:
                return <span className={`w-[1px] bg-primary h-1/2 -bottom-2 absolute ${isRTL ? "-right-7" : "-left-7"}`} />;

              case lastIndex:
                return <span className={`w-[1px] bg-primary h-1/2 -top-2 absolute ${isRTL ? "-right-7" : "-left-7"}`} />;

              default:
                return <span className={`w-[1px] bg-primary h-[120%] absolute ${isRTL ? "-right-7" : "-left-7"}`} />;
              }
            })()}

            <div className={`absolute z-10 flex items-center justify-center h-[42px] w-[42px] rounded-full ${isRTL ? "-right-12" : "-left-12"} ${is_on ? "bg-primary" : "bg-white border border-primary"}`}>
              <CheckIcon className={`w-4 h-4 ${is_on ? "text-white" : "text-active"}`} />
            </div>

            <div className="w-full">
              <h3 className={`font-medium text-lg leading-relaxed ${is_on && "text-active"}`}>{titleByLang}</h3>
              <p className="text-sm">{subtitle}</p>
            </div>
          </li>
        );
      })}
    </ol>
  );
}

export default CardOrderStatus;
