import clsx from "clsx";
import Leaflet from "leaflet";
import {
  memo,
  useEffect,
  // useEffect,
  useState,
} from "react";

import MapPinMarker from "../../assets/map-pin.png";
import { ReactComponent as BackIcon } from "../../assets/icons/ic_back.svg";

// import { useGeolocation } from "../../hooks";
import {
  Map,
  MapMarker,
} from "../maps";

import "leaflet/dist/leaflet.css";

// const Map = lazy(() => import("../maps/map"));
// const MapMarker = lazy(() => import("../maps/marker"));

type OnSubmitProps = {
  center: {
    lat: number;
    lng: number;
  }
  address: string;
}

interface ModalChooseOnMapProps {
  open: boolean;
  onClose?: () => void;
  defaultMapCenter: Leaflet.LatLngLiteral;
  // onClose: () => void;
  onSubmit: (props: OnSubmitProps) => void;
  // onAddressChange?: (address: string) => void;
}

function ModalChooseOnMap({
  open, onClose, defaultMapCenter, onSubmit,
}: ModalChooseOnMapProps) {
  // const { coordinates } = useGeolocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [mapCenter, setMapCenter] = useState<Leaflet.LatLngLiteral>({
    lat: defaultMapCenter.lat,
    lng: defaultMapCenter.lng,
  });

  const icon = new Leaflet.Icon({
    iconUrl: MapPinMarker,
    iconSize: new Leaflet.Point(56, 56, false),
    iconAnchor: new Leaflet.Point(28, 56),
  });

  const handleOnMarkerDrag = (center: Leaflet.LatLngLiteral) => {
    const fetchAddress = async () => {
      setIsLoading(true);

      const response = await fetch(`https://api.geoapify.com/v1/geocode/reverse?lat=${center.lat}&lon=${center.lng}&apiKey=${process.env.REACT_APP_GEOAPIFY_API_KEY}`, {
        method: "GET",
      });
      const data = await response.json();

      setAddress(data.features[0]?.properties.formatted || "");
      setMapCenter((state) => ({
        ...state,
        lat: center.lat,
        lng: center.lng,
      }));

      return response;
    };

    fetchAddress()
      .finally(() => setIsLoading(false));
  };

  // useEffect(() => {
  //   setMapCenter((state) => ({
  //     ...state,
  //     lat: defaultMapCenter.lat,
  //     lng: defaultMapCenter.lng,
  //   }));
  // }, [defaultMapCenter.lat, defaultMapCenter.lng]);

  // useEffect(() => {
  //   if (mapCenter.lat === 0 || mapCenter.lng === 0) {
  //     setMapCenter((prevState) => ({
  //       ...prevState,
  //       lat: defaultMapCenter?.lat || coordinates.lat,
  //       lng: defaultMapCenter?.lng || coordinates.lng,
  //     }));
  //   }
  // }, [defaultMapCenter, coordinates]);

  return (
    <div
      className={clsx(
        "fixed inset-0 z-30 lg:right-auto",
        "w-full lg:w-1/3 h-screen",
        "bg-white overflow-hidden hide-scrollbar",
        "flex flex-col justify-start items-center",
        "transition-opacity duration-200 ease-in-out",
        open ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none",
      )}
    >
      <button
        className="absolute p-3 top-4 left-4 shadow-button rounded z-[1000] bg-white"
        onClick={() => {
          if (onClose) onClose();
        }}
        type="button"
        title="Close maps"
      >
        <BackIcon className="w-4 h-4" />
      </button>

      <div id="map" className="relative w-full h-full bg-gray">
        <div className={clsx(
          "absolute inset-0 bg-white z-[999] transition-all duration-200 ease-in-out",
          isLoading ? "visible bg-opacity-50" : "invisible bg-opacity-0",
        )}
        />

        {/* {open && ( */}
        <Map
          center={mapCenter}
          zoom={17}
          mapClassName={clsx("w-full h-full")}
        >
          <MapMarker
            icon={icon}
            position={mapCenter}
            onDrag={handleOnMarkerDrag}
          />
        </Map>
        {/* )} */}
      </div>

      <div className="flex flex-col flex-1 w-full h-auto bg-white">
        {/* <div className="bg-white h-[150px] w-full flex flex-col absolute bottom-0 z-[1000]"> */}
        <div className="flex-1 w-full h-auto p-2">
          <p className="text-lg">Address:</p>
          {/* <p className="line-clamp-2">{address}</p> */}
          <p>{address}</p>
        </div>

        <button
          type="button"
          onClick={() => {
            onSubmit({
              center: mapCenter,
              address,
            });
          }}
          className={clsx(
            "w-full button-primary rounded-none disabled:opacity-50 disabled:cursor-not-allowed",
          )}
        >
          Confirm Your Address
        </button>
      </div>
    </div>
  );
}

export default memo(ModalChooseOnMap);
