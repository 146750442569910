import { ReactComponent as Logo } from "../assets/logo.svg";

function UnAuthPage() {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <Logo className="h-16 w-16 mb-8" />
      <p className="font-bold text-2xl">401</p>
      <p>Unauthorized User</p>
    </div>
  );
}

export default UnAuthPage;
