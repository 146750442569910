export default {
  en: {
    order_dinein_qrmobile: "Dine-in-Mobile",
    order_delivery: "Delivery",
    order_pickup: "Pick-up",
    order_drivetru: "Drive-through",
  },
  ar: {
    order_dinein_qrmobile: "Dine-in-Mobile",
    order_delivery: "توصيل",
    order_pickup: "حجز طلب",
    order_drivetru: "الطلب من السيارة",
  },
};
