/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IMAGES_URL } from "../../app/constants";
import { useAppSelector } from "../../app/hooks";
import type { HomeItemResponses, IngredientsWarningResponse } from "../../app/types";

import { ReactComponent as NewIcon } from "../../assets/icons/ic_label_new.svg";
import { ReactComponent as SignatureIcon } from "../../assets/icons/ic_label_signature.svg";
import CardPlaceholder from "../../assets/image_placeholder.png";

export type ListItemCardProps = HomeItemResponses & {
  goToDetail: () => void
}

export function ListItemCard({
  id,
  description,
  goToDetail,
  image,
  name,
  ingredients_warning,
  is_new,
  prices,
  is_signature,
}: ListItemCardProps) {
  const { mainData, homeData, ingredientsWarningData } = useAppSelector(({ globalState }) => globalState);

  const itemImage = image ? `${IMAGES_URL + image}` : CardPlaceholder;

  function ingredientsWarningIcon(ingredient: string[]) {
    const copiedIngredientsWarning = Array.from(ingredientsWarningData || []);
    const ingredientsIcon: IngredientsWarningResponse[] = [];

    ingredient.forEach((ingredientId) => {
      const ingredientWarning = copiedIngredientsWarning.find((item) => item.id === ingredientId);

      if (ingredientWarning) {
        ingredientsIcon.push(ingredientWarning);
      }
    });

    return ingredientsIcon.sort((a, b) => (a.id > b.id ? 1 : -1));
  }

  // Item prices
  let itemPrices = prices?.map((price) => `${homeData?.currency} ${price}`);

  if (prices && prices?.length > 2) {
    const itemPricesLength = prices.length;

    itemPrices = [prices[0], prices[itemPricesLength - 1]].map((price) => `${homeData?.currency} ${price}`);
  }

  return (
    <li
      key={id}
      className="rounded shadow-card-item bg-white mb-4 p-4 relative overflow-hidden transition-transform duration-200 active:translate-y-1.5"
      onClick={goToDetail}
    >
      <div className="flex gap-4">
        <section className="flex-1">
          <p className=" text-base text-black mb-1">{name}</p>
          <p className="m-0 text-[14px] leading-5 text-black mb-2">{description}</p>

          {mainData?.price_is_show && (
            <p className="text-active  text-sm">{itemPrices?.join(" - ")}</p>
          )}
        </section>

        <section className="w-[100px]">
          <div className="aspect-w-1 aspect-h-1 rounded overflow-hidden">
            <img
              src={itemImage}
              alt={name}
              className="object-cover"
            />
          </div>
        </section>
      </div>

      {(is_signature
      || is_new
      || (ingredients_warning && ingredients_warning.length > 0)) && (
        <div className="flex gap-2 mt-2 items-center py-1">
          {is_signature && <SignatureIcon className="h-6 w-6" />}
          {is_new && <NewIcon className="h-6 w-6" />}

          {(ingredients_warning && ingredients_warning?.length > 0) ? (
            <>
              {ingredientsWarningIcon(ingredients_warning || []).map((ingredient) => (
                <img
                  className="h-6 w-6 transform scale-95"
                  key={ingredient.id}
                  src={`${IMAGES_URL}ingredients/${ingredient.icon}`}
                  alt={ingredient.id}
                  style={{
                    filter:
                        "invert(49%) sepia(68%) saturate(3246%) hue-rotate(334deg) brightness(95%) contrast(81%)",
                  }}
                />
              ))}
            </>
          ) : ""}
        </div>
      )}
    </li>
  );
}
