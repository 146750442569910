/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  ChangeEvent, Dispatch, Fragment, SetStateAction, useMemo,
} from "react";

import { FeedbackQuestionRequest } from "../../app/types";

import { ReactComponent as EmoticonNeutralFace } from "../../assets/emojis/emot_neutral_face.svg";
import { ReactComponent as EmoticonSadFace } from "../../assets/emojis/emot_sad_face.svg";
import { ReactComponent as EmoticonSmilingFace } from "../../assets/emojis/emot_smiling_face.svg";

interface FeedbackAnswerProps {
  type: string;
  id: string;
  answers: FeedbackQuestionRequest[];
  setAnswers: Dispatch<SetStateAction<FeedbackQuestionRequest[]>>;
}

export function FeedbackAnswerForms({
  type, id, answers, setAnswers,
}: FeedbackAnswerProps): JSX.Element {
  const handleAnswerChange = (e: ChangeEvent<HTMLInputElement>, q_id: string) => {
    const changedAnswer = answers.map((answer) => {
      if (answer.q_id === q_id) {
        return {
          ...answer,
          answer: e.target.value,
        };
      }

      return answer;
    });
    setAnswers(changedAnswer);
  };

  const value = useMemo(() => answers.find((answer) => answer.q_id === id)?.answer ?? "", [answers, id]);

  if (type === "yesno") {
    return (
      <div className="flex items-center justify-center gap-2 w-full text-md hide-scrollbar mb-4">
        {["1", "0"].map((number) => (
          <Fragment key={`${number}-${Math.floor(Math.random() * 1000000)}`}>
            <input
              type="radio"
              name="yesno"
              id={id + number}
              value={number}
              className="hidden"
              onChange={(e) => handleAnswerChange(e, id)}
            />

            {number === "1" ? (
              <label
                htmlFor={id + number}
                className={`py-2 px-6 rounded-full border font-bold cursor-pointer ${
                  value === number ? "border-success text-success bg-opacity-10" : "border-gray text-secondary"
                }`}
              >
                Yes
              </label>
            ) : (
              <label
                htmlFor={id + number}
                className={`py-2 px-6 rounded-full border font-bold cursor-pointer ${
                  value === number ? "border-error text-error bg-opacity-10" : "border-gray text-secondary"
                }`}
              >
                No
              </label>
            )}
          </Fragment>
        ))}
      </div>
    );
  }

  if (type === "smiley") {
    return (
      <div className="flex items-center justify-center gap-2 w-full text-xl mb-4">
        {["1", "2", "0"].map((number) => (
          <Fragment key={number}>
            <input
              type="radio"
              name="smiley"
              id={id + number}
              value={number}
              className="hidden"
              onChange={(e) => handleAnswerChange(e, id)}
            />
            <label
              htmlFor={id + number}
              className={`cursor-pointer ${number === value ? "" : "filter grayscale"}`}
            >
              {number === "2" && <EmoticonSmilingFace className="text-yellow w-[36px] h-[36px]" />}
              {number === "1" && <EmoticonNeutralFace className="text-yellow mx-1 w-[36px] h-[36px]" />}
              {number === "0" && <EmoticonSadFace className="text-yellow w-[36px] h-[36px]" />}
            </label>
          </Fragment>
        ))}
      </div>
    );
  }

  if (type === "rating") {
    return (
      <div className="flex items-center justify-center w-full gap-2 text-2xl mb-4">
        {Array(5)
          .fill("★")
          .map((number, index) => (
            <Fragment key={index.toString()}>
              <input
                type="radio"
                name="smiley"
                id={id + index.toString()}
                value={(index + 1).toString()}
                className="hidden"
                onChange={(e) => handleAnswerChange(e, id)}
              />
              <label
                htmlFor={id + index.toString()}
                className={`text-3xl cursor-pointer ${
                  (Number(value) ?? 0) <= index ? "text-[#FFE8A4]" : "text-yellow"
                }`}
              >
                {number}
              </label>
            </Fragment>
          ))}
      </div>
    );
  }

  return (
    <input
      type="text"
      className="input-base rounded-[28px] px-4 py-2 mb-4"
      onChange={(e) => handleAnswerChange(e, id)}
      value={value}
    />
  );
}
