import { useHistory } from "react-router-dom";
import React, { useState } from "react";

import { ReactComponent as UserIcon } from "../../../assets/icons/ic_user.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/ic_mail.svg";
import { ReactComponent as PhoneOutIcon } from "../../../assets/icons/ic_phone_outgoing.svg";
import { ReactComponent as CarPlateNumberIcon } from "../../../assets/icons/ic_car_plate_number.svg";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PriceSummariesWithCheckoutButton } from "../../../components";
import { setData, submitOrder } from "../../../features/orders";
import { CheckoutForm, PaymentTypeForm } from "../../../components/forms/checkouts";

export interface OrderPickUpFormTypes {
  cust_name: string,
  cust_email: string,
  cust_phone: string,
  cust_plate_num: string,
  payment_type_id: string,
  notes: string,
}

function FinishOrderPickUp() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { currentLanguage, currency } = useAppSelector(({ globalState }) => globalState);

  const [formState, setFormState] = useState<OrderPickUpFormTypes>({
    cust_name: "",
    cust_email: "",
    cust_phone: "",
    cust_plate_num: "",
    payment_type_id: "",
    notes: "",
  });

  const isRTL = currentLanguage?.code === "ar";

  const handleOnChangeForm = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value, name } = event.target;

    if (!(Object.keys(formState).find((key) => key === name))) return;

    if (name === "cust_phone") {
      const pattern = /^[0-9+-.]/gi;
      const regex = new RegExp(pattern);

      if (value.length > 0 && !regex.test(value)) {
        // console.log("not valid");
        return;
      }
    }

    setFormState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSetPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setFormState((prevState) => ({
      ...prevState,
      payment_type_id: value,
    }));
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(setData({
      ...formState,
    }));
    dispatch(submitOrder(true));

    history.replace("order-summary");
  };

  return (
    <form
      onSubmit={handleOnSubmit}
      className="p-4"
      style={{
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      {/* Name */}
      <CheckoutForm.Input
        className="mb-4"
        isRTL={isRTL}
        id="input-name"
        name="cust_name"
        label={isRTL ? "الاسم" : "Name"}
        showIcon
        icon={UserIcon}
        value={formState.cust_name}
        required
        onChange={handleOnChangeForm}
      />

      {/* Email */}
      <CheckoutForm.Input
        className="mb-4"
        isRTL={isRTL}
        id="input-email"
        name="cust_email"
        label={isRTL ? "البريد الالكتروني" : "E-mail"}
        showIcon
        icon={EmailIcon}
        value={formState.cust_email}
        onChange={handleOnChangeForm}
      />

      {/* Phone number */}
      <CheckoutForm.Input
        className="mb-4"
        isRTL={isRTL}
        id="input-phone"
        name="cust_phone"
        label={isRTL ? "رقم الهاتف" : "Phone number"}
        type="text"
        showIcon
        icon={PhoneOutIcon}
        value={formState.cust_phone}
        required
        onChange={handleOnChangeForm}
      />

      {/* Plate Number */}
      <CheckoutForm.Input
        className="mb-4"
        isRTL={isRTL}
        id="input-plate_num"
        name="cust_plate_num"
        label={isRTL ? "رقم اللوحة" : "Plate number"}
        value={formState.cust_plate_num}
        showIcon
        icon={CarPlateNumberIcon}
        required
        onChange={handleOnChangeForm}
      />

      {/* Payment Type */}
      <h3 className="text-lg text-center mb-6 mt-2">{isRTL ? "نوع الدفع" : "Payment Type"}</h3>

      <PaymentTypeForm
        value={formState.payment_type_id}
        onChange={handleSetPayment}
        required
        cardText={{
          cash: {
            title: isRTL ? "الدفع نقدًا" : "Cash",
            description: isRTL ? "ادفع نقدًا عند استلام طلبك" : "Pay at delivery with cash",
          },
          credit: {
            title: isRTL ? " بطاقة الائتمان" : "Credit Card",
            description: isRTL ? "ادفع ببطاقة الائتمان عند استلام طلبك" : "Pay at delivery with credit card",
          },
        }}
      />

      {/* Notes */}
      <div className="text-black text-center w-full mb-8 mt-6">
        <h3 className="text-lg">{isRTL ? "إضافة ملاحظة" : "Add a note"}</h3>
        <p className="text-sm">{isRTL ? "أضف ملاحظة حول طلبك" : "Add a note about your order"}</p>

        <textarea
          name="notes"
          className="input-base border-black rounded-lg mt-2"
          placeholder={isRTL ? "اختياري" : "Optional"}
          rows={4}
          value={formState.notes}
          onChange={handleOnChangeForm}
        />
      </div>

      <PriceSummariesWithCheckoutButton
        button={{
          type: "submit",
        }}
        rewriteClassName="fixed bottom-0 left-0 z-20 w-full bg-white"
        currency={currency}
        showPromoCodeForm
      />
    </form>
  );
}

export default FinishOrderPickUp;
