import {
  memo, useState, type Dispatch, type FormEvent, type SetStateAction, useEffect,
} from "react";

import clsx from "clsx";
import { toast } from "react-toastify";
import { ReactComponent as BackIcon } from "../../assets/icons/ic_back.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/ic_star.svg";
import { ReactComponent as IconToastError } from "../../assets/icons/ic_toast_error.svg";

import { useAppSelector } from "../../app/hooks";
import type { DiscountTypes } from "../../app/types";
import { useCheckPromoMutation } from "../../services";
import Portal from "../portal";

function ErrorToast({ message }: { message: string }) {
  return (
    <div
      className={clsx(
        "bg-[#F7E4E4] text-[#D24740]",
        "flex flex-row items-start gap-4",
        "p-5 rounded-lg shadow-card-item",
        "transition-all duration-300 ease-in-out",
      )}
    >
      <IconToastError className={clsx("w-6 h-6")} />
      <span>{message}</span>
    </div>
  );
}

interface PromoCodeProps {
  defaultValue?: DiscountTypes | null;
  discount: DiscountTypes | null;
  currency?: string;
  setDiscount: Dispatch<SetStateAction<DiscountTypes | null>>;
  totalPrice?: number;
}

function PromoCode({
  defaultValue, discount, currency = "USD", setDiscount, totalPrice = 0,
}: PromoCodeProps) {
  const { currentLanguage, orderType } = useAppSelector(({ globalState }) => globalState);

  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [minimumOrderValue, setMinimumOrderValue] = useState(0);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const [checkPromo, { isLoading: isLoadingCheckPromo }] = useCheckPromoMutation();

  const isRTL = currentLanguage?.code === "ar";

  /**
   * Handle on submit promo code
   * @param {FormEvent<HTMLFormElement>} event form event
   */
  const handleOnSubmit = () => {
    // const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (!code || code.length === 0) return;

    checkPromo({
      code,
      order_type: (() => {
        switch (orderType) {
        case "dine-in":
          return "dinein";
        case "delivery":
          return "delivery";
        default:
          return "";
        }
      })(),
    })
      .unwrap()
      .then((res) => {
        if (res?.data) {
          if (orderType === "delivery" && totalPrice < res?.data.min_order) {
            setShowErrorToast(true);
            setMinimumOrderValue(res?.data.min_order || 0);

            throw new Error(`Minimum delivery order amount ${currency} ${res.data.min_order}`);
          }

          if (isInvalid) setIsInvalid(false);

          setDiscount((prevState) => ({
            ...prevState,
            discount_code: code,
            min_order: res.data?.min_order || 0,
            promo_amount: res.data?.promo_amount || 0,
            type: res.data?.type || "PERCENT",
          }));

          setIsValid(true);
        } else {
          throw new Error(res.message || "Promo not found");
        }
      })
      .catch(() => {
        if (isValid) setIsValid(false);
        if (discount) setDiscount(null);

        setIsInvalid(true);
        setTimeout(() => setShowErrorToast(false), 7500);
      });
  };

  /**
   * Remove promo code
   */
  const RemovePromoCode = () => {
    setDiscount(null);
    setIsValid(false);
    setIsInvalid(false);
    setCode("");
  };

  useEffect(() => {
    if (defaultValue) {
      setCode(defaultValue.discount_code || "");
      setIsValid(true);
    }
  }, [defaultValue]);

  return (
    <>
      {/* {showErrorToast && ( */}
      <Portal>
        <div
          className={clsx(
            "fixed z-50 top-0 left-0 w-full lg:w-1/3",
            "p-4",
            "flex flex-col gap-4",
            "transition-all duration-300 ease-in-out",
            showErrorToast ? "opacity-100 translate-y-0 visible" : "opacity-0 -translate-y-2 invisible",
          )}
          id="error-toast-container"
        >
          <ErrorToast message="Your cart is below minimum order amount." />
          <ErrorToast message={`Minimum delivery order amount ${currency} ${minimumOrderValue}`} />
        </div>
      </Portal>
      {/* )} */}

      <div
        className={clsx("text-black text-center w-full px-4 relative", "flex flex-row items-center justify-center")}
        style={{
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        <StarIcon className={`w-6 h-6 absolute z-[5] ${isRTL ? "right-4" : "left-4"}`} />

        <input
          type="text"
          className={clsx(
            "py-3 w-full font-montserrat text-sm disabled:bg-white disabled:opacity-50",
            "border-b outline-none hover:border-b-black focus:border-b-black border-b-[#E5E5E5]",
            "disabled:cursor-not-allowed disabled:opacity-50",
            isRTL ? "pr-10" : "pl-10",
          )}
          placeholder={isRTL ? "كود الخصم" : "Promo Code"}
          onChange={({ target }) => setCode(target.value)}
          disabled={isValid || isLoadingCheckPromo}
          value={code}
        />

        {isValid ? (
          <button
            type="button"
            className={clsx(
              "absolute z-[5] text-sm font-medium",
              "flex items-center justify-center gap-2",
              "disabled:cursor-not-allowed disabled:opacity-50",
              isRTL ? "left-4" : "right-4",
            )}
            style={{
              direction: isRTL ? "rtl" : "ltr",
            }}
            title="Remove Promo Code"
            onClick={() => RemovePromoCode()}
            disabled={isLoadingCheckPromo}
          >
            {isRTL ? "تفعيل" : "Remove"}
          </button>
        ) : (
          <button
            type="button"
            // type="submit"
            className={clsx(
              "absolute z-[5] text-sm font-medium",
              "flex items-center justify-center gap-2",
              "disabled:cursor-not-allowed disabled:opacity-50",
              isRTL ? "left-4" : "right-4",
            )}
            style={{
              direction: isRTL ? "rtl" : "ltr",
            }}
            title="Apply Promo Code"
            disabled={isLoadingCheckPromo}
            onClick={handleOnSubmit}
          >
            {isRTL ? "تفعيل" : "Apply"}
            {" "}
            <BackIcon className={`h-3 w-3 ${isRTL ? "" : "rotate-180"}`} />
          </button>
        )}
      </div>

      {isValid && (
        <p className="my-2 text-sm text-center">
          {isRTL ? "تم تطبيق الخصم بنجاح" : "The discount is applied successfully"}
        </p>
      )}

      {isInvalid && (
        <p className="my-2 text-sm text-center text-red-500">
          {isRTL ? "هذا الرمز الترويجي غير صالح" : "This promo code is not valid"}
        </p>
      )}
    </>
  );
}

export default memo(PromoCode);
