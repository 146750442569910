import { type LatLngExpression } from "leaflet";
import { memo, type ReactNode } from "react";
import * as ReactLeaflet from "react-leaflet";

import "leaflet/dist/leaflet.css";

interface MapProps {
  children?: ReactNode;
  center: LatLngExpression;
  zoom?: number;
  mapClassName?: string;
  enableZoomControl?: boolean;
}

const { MapContainer, TileLayer } = ReactLeaflet;

const Map = memo(({
  children, center, zoom, mapClassName, enableZoomControl = false,
}: MapProps) => (
  <MapContainer
    center={center}
    zoom={zoom || 16}
    className={mapClassName}
    zoomControl={enableZoomControl}
  >
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />

    {children}
  </MapContainer>
));

export default Map;
