import { memo } from "react";

import { ReactComponent as BackIcon } from "../../assets/icons/ic_back.svg";

import { NUTRITIONS_LABEL } from "../../app/constants";
import { useAppSelector } from "../../app/hooks";
import type { NutritionFactTypes } from "../../app/types";

interface ModalNutritionFactProps {
  open: boolean;
  close: () => void;
  nutrition_facts?: NutritionFactTypes;
}

function ModalNutritionFact({ open, close, nutrition_facts }: ModalNutritionFactProps) {
  const { currentLanguage } = useAppSelector(({ globalState }) => globalState);
  const langCode = currentLanguage?.code;
  const isRTL = currentLanguage?.type === "RTL";

  const getNutritionLabel = (id: string) => {
    const label = NUTRITIONS_LABEL.find(({ id: nutritionId }) => nutritionId === id)?.label ?? null;

    return label;
  };

  return (
    <div
      className={
        `fixed z-30 inset-0 h-screen overflow-y-auto hide-scrollbar lg:right-auto w-full lg:w-[calc(33.333333%+2px)] bg-white flex flex-col justify-start items-center pb-0 pt-6 px-4 gap-4 transition-opacity duration-200 ease-in text-[#272727] border-r border-r-gray ${
          open ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"}`
      }
      style={{ minWidth: 320 }}
    >
      <button
        type="button"
        className="absolute p-3 top-4 left-4 shadow-button rounded z-20 bg-white"
        onClick={close}
      >
        <BackIcon className="h-4 w-4" />
      </button>

      <h2 className={`text-[22px] leading-[30.01px] mb-8 ${langCode === "ar" ? "" : "font-semibold"}`}>{langCode === "ar" ? "معلومات التغذية" : "Nutrition Facts"}</h2>

      <div
        className="w-full"
      >
        <div
          className="flex flex-col w-full mb-0.5"
          style={{
            direction: isRTL ? "rtl" : "ltr",
          }}
        >
          <div className="flex flex-row items-center justify-between text-[19px] leading-[25px] border-b pb-2 mb-2">
            <p className={langCode === "ar" ? "" : "font-bold"}>{langCode === "ar" ? "حجم الوجبة" : "Serving size"}</p>
            <p
              style={{
                direction: "ltr",
              }}
              className="font-bold"
            >
              1 (
              {nutrition_facts?.nutri_serving_size || ""}
              {nutrition_facts?.nutri_serving_unit || ""}
              )
            </p>
          </div>
          <small className={`text-[13.5px] leading-[18.41px] ${langCode === "ar" ? "" : "font-bold"}`}>{langCode === "ar" ? "القيمة لكل خدمة" : "Amount per serving"}</small>
        </div>

        <div
          className="flex flex-row items-end justify-between text-[19px] leading-[25px] border-b pb-2 mb-2 font-bold"
          style={{
            direction: isRTL ? "rtl" : "ltr",
          }}
        >
          <p className={`${langCode === "ar" ? "" : "font-semibold"}`}>{langCode === "ar" ? "سعرات حراريه" : "Calories"}</p>
          <p className="text-[25px] leading-[34.1px]">{nutrition_facts?.nutri_calories || 0}</p>
        </div>

        <p className="font-bold border-b pb-2 mb-4">
          Calories from fat
          {" "}
          <span className="font-normal">
            {nutrition_facts?.nutri_calories_fat || 0}
            cal
          </span>
        </p>

        <p className="text-right font-bold text-[13.5px] leading-[18.41px] border-b pb-1.5">
          % Daily Value*
        </p>

        {nutrition_facts?.nutritions.map(({
          daily_value, value, value_unit, nutri_id,
        }, index) => {
          const key = index.toString();

          if (nutri_id === "nutri_saturfat" || nutri_id === "nutri_dietfiber" || nutri_id === "nutri_totsugar") {
            return (
              <div
                className="flex flex-row items-center justify-between border-b py-1.5 pl-4"
                key={key}
              >
                <p>
                  {getNutritionLabel(nutri_id)}
                  {" "}
                  <span className="ml-0.5">
                    {value || 0}
                    {value_unit}
                  </span>
                </p>

                {daily_value && daily_value > 0 && (
                  <p className="font-bold">
                    {daily_value}
                    %
                  </p>
                )}
              </div>
            );
          }

          if (nutri_id === "nutri_transfat") {
            return (
              <div
                className="flex flex-row items-center justify-between border-b py-1.5 pl-4"
                key={key}
              >
                <p>
                  <span className="font-black">Trans</span>
                  {" "}
                  fat
                  {" "}
                  <span className="ml-0.5">
                    {value || 0}
                    {value_unit}
                  </span>
                </p>

                {daily_value && daily_value > 0 && (
                  <p className="font-bold">
                    {daily_value}
                    %
                  </p>
                )}
              </div>
            );
          }

          return (
            <div
              className="flex flex-row items-center justify-between border-b py-1.5"
              key={key}
            >
              <p className="font-bold">
                {getNutritionLabel(nutri_id)}
                {" "}
                <span className="font-normal ml-0.5">
                  {value || 0}
                  {value_unit}
                </span>
              </p>

              {/* {daily_value && ( */}
              <p className="font-bold">
                {daily_value || 0}
                %
              </p>
              {/* )} */}
            </div>
          );
        })}

        <p className="text-sm mt-2">
          {/* eslint-disable-next-line max-len */}
          *The % Daily Value tells you how much a nutrient in a serving food contributes to a daily diet. 2000 calories a day is used for general nutrition advice.
        </p>

        <div className="flex justify-center w-full mt-5 mb-4">
          <button
            className="button-primary rounded-full px-14"
            onClick={close}
            type="button"
            title="Close"
          >
            Close
          </button>

        </div>
      </div>
    </div>
  );
}

export default memo(ModalNutritionFact);
