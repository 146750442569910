import { createApi } from "@reduxjs/toolkit/query/react";

import type {
  DataLanguage, HomeResponses, IngredientsWarningResponse, ItemResponse, MainResponse, OrderStatusResponseType,
} from "../app/types";
import baseQuery from "./base-service";

export type Language = {
  lang: string;
};

type PromoResponse = {
  min_order: number;
  promo_amount: number;
  type: "PERCENT" | "AMOUNT"
}

export const qrServices = createApi({
  reducerPath: "qr",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getMainData: builder.query<HttpResponse<MainResponse>, void>({
      query: () => ({
        method: "GET",
        url: "/main",
      }),
    }),
    getHomeData: builder.query<HttpResponse<HomeResponses>, Language>({
      query: ({ lang }) => ({
        method: "GET",
        url: `/${lang}/home`,
      }),
    }),
    getItemDetail: builder.query<
      HttpResponse<ItemResponse>,
      Language & { id?: string; categoryId?: string | number; sectionId?: string | number }
    >({
      query: ({
        lang, id, categoryId, sectionId,
      }) => ({
        method: "GET",
        url: sectionId
          ? `/${lang}/item?id=${id}&category_id=${categoryId}&section_id=${sectionId}`
          : `/${lang}/item?id=${id}&category_id=${categoryId}`,
      }),
    }),
    getLanguageList: builder.query<HttpResponse<DataLanguage>, void>({
      query: () => ({
        method: "GET",
        url: "/languageList",
      }),
    }),
    getIngredientList: builder.query<HttpResponse<IngredientsWarningResponse[]>, void>({
      query: () => ({
        method: "GET",
        url: "/ingredientslist",
      }),
    }),
    getOrderStatus: builder.query<HttpResponse<OrderStatusResponseType[]>, number>({
      query: (orderNumber) => ({
        method: "GET",
        url: `/checkOrder?id=${orderNumber}`,
      }),
    }),
    getPaymentList: builder.query<HttpResponse<string[]>, void>({
      query: () => ({
        url: "/paymentlist",
        method: "GET",
      }),
    }),
    checkPromo: builder.mutation<HttpResponse<PromoResponse>, { code: string, order_type: string }>({
      query: ({ code, order_type }) => ({
        method: "GET",
        url: `/checkPromo?code=${code}&order_type=${order_type}`,
      }),
    }),
  }),
});

export const {
  useGetMainDataQuery,
  useGetHomeDataQuery,
  useGetItemDetailQuery,
  useGetLanguageListQuery,
  useGetIngredientListQuery,
  useGetOrderStatusQuery,
  useGetPaymentListQuery,
  useCheckPromoMutation,
} = qrServices;
