/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import LoadingBar from "react-top-loading-bar";
import Carousel from "nuka-carousel";

import { ReactComponent as BackIcon } from "../assets/icons/ic_back.svg";
import { ReactComponent as CheckIcon } from "../assets/icons/ic_check.svg";
import { ReactComponent as ClockIcon } from "../assets/icons/ic_clock.svg";
import { ReactComponent as FireIcon } from "../assets/icons/ic_fire.svg";
import { ReactComponent as NewIcon } from "../assets/icons/ic_label_new.svg";
import { ReactComponent as SignatureIcon } from "../assets/icons/ic_label_signature.svg";
import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";

import { useQuery } from "../hooks";
// import { Portal,  } from "../components";
import { IMAGES_URL } from "../app/constants";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { DescriptionPriceResponse, IngredientsWarningResponse, ModifierSelectionType } from "../app/types";
import { ModalNutritionFact, Portal, withData } from "../components";
import {
  addToCart, changeCart, setNewItem,
} from "../features/cart";
import { setCurrentLanguage, setNoReload } from "../features/globalState";
import { useGetItemDetailQuery } from "../services";

interface FulfilledModifierType {
  name: string;
  is_fulfilled: boolean;
}

function Item() {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const {
    mainData: main, currentLanguage, ingredientsWarningData, token,
  } = useAppSelector(({ globalState }) => globalState);
  const { savedLanguage } = useAppSelector(({ cache }) => cache);

  const { data: itemData, error } = useGetItemDetailQuery(
    {
      lang: currentLanguage?.code || savedLanguage?.code || "en",
      id: query.get("id") || "",
      categoryId: query.get("category_id") || undefined,
      sectionId: query.get("section_id") || undefined,
    },
    {
      skip: !query.get("id") || !currentLanguage,
    },
  );

  const { carts } = useAppSelector(({ cart }) => cart);

  const { replace } = useHistory();
  const [selectedPrice, setSelectedPrice] = useState<DescriptionPriceResponse>();
  const [selectedModifier, setSelectedModifier] = useState<ModifierSelectionType[]>([]);
  const [requiredModifier, setRequiredModifier] = useState<FulfilledModifierType[]>([]);
  const [reachMaxModifier, setReachMaxModifier] = useState<FulfilledModifierType[]>([]);
  const [openNutritionFacts, setOpenNutritionFacts] = useState<boolean>(false);

  const itemOnCarts = carts?.find(({ id }) => id === query.get("id"));
  const isRTL = currentLanguage?.type === "RTL";

  // const videoRef = useRef<HTMLVideoElement>(null);
  const [itemCount, setItemCount] = useState<number>(1);
  // const [isAdded, setAdded] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [isButtonShow, setButtonShow] = useState(true);
  // const [isButtonShow, setButtonShow] = useState(false);

  // const ref = useRef(null);

  const goBack = () => {
    dispatch(setNoReload(true));
    replace(`/menu/${token}/home`);
  };

  const handleCountMinus = () => {
    if (itemCount === 1) return;
    setItemCount((state) => state - 1);
  };

  const findMaxModifier = (name: string) => {
    const found = itemData?.data?.modifiers.find((mod) => name === mod.name);
    return found ? found.max : 0;
  };

  const findMinModifier = (name: string) => {
    const found = itemData?.data?.modifiers.find((mod) => name === mod.name);
    return found ? found.min : 0;
  };

  const handleModifierChange = (
    modifierName: string,
    itemName: string,
    checked: boolean,
  ) => {
    const max = findMaxModifier(modifierName);

    if (max > 0 && checked) {
      const selected = selectedModifier
        ?.find((mod) => mod.name === modifierName)
        ?.items.filter((item) => item.is_selected).length ?? 0;
      if (selected >= max) return;
    }

    if (selectedModifier) {
      const changeModifier = selectedModifier.map((mod) => {
        if (mod.name === modifierName) {
          return {
            name: mod.name,
            items: mod.items.map((item) => {
              if (item.name === itemName) {
                return {
                  name: item.name,
                  price: item.price,
                  is_selected: checked,
                };
              }
              return item;
            }),
          };
        }
        return mod;
      });
      setSelectedModifier(changeModifier);
    } else {
      // eslint-disable-next-line no-console
      console.log("modifier is empty");
    }
  };

  const addItemToCart = () => {
    const id = query.get("id");
    if (!(itemData?.data && selectedPrice && selectedModifier && id)) return;

    const { data } = itemData;

    if (itemOnCarts) {
      const changedCarts = [...carts.map((cart) => {
        if (cart.id === id) {
          return {
            id: id ?? cart.id,
            category_id: query.get("category_id") ?? undefined,
            section_id: query.get("section_id") ?? undefined,
            name: data.name,
            note,
            image: data.image,
            price: selectedPrice ?? cart.price,
            modifiers: selectedModifier ?? cart.modifiers,
            count: itemCount ?? cart.count,
          };
        }
        return cart;
      })];

      dispatch(changeCart(changedCarts));
    } else {
      dispatch(addToCart({
        id,
        category_id: query.get("category_id") ?? undefined,
        section_id: query.get("section_id") ?? undefined,
        name: data.name,
        note,
        image: data.image,
        price: selectedPrice,
        modifiers: selectedModifier,
        count: itemCount,
      }));
    }

    dispatch(setNewItem({
      id,
      category_id: query.get("category_id") ?? undefined,
      section_id: query.get("section_id") ?? undefined,
      name: data.name,
      note,
      image: data.image,
      price: selectedPrice,
      modifiers: selectedModifier,
      count: itemCount,
    }));

    setTimeout(() => {
      setButtonShow(false);
      goBack();
    }, 1000);
  // }, 1500);
  };

  // const addToCart = () => {
  //   const id = query.get('id')
  //   if (!(data && selectedPrice && selectedModifier && id)) {
  //     return;
  //   }

  //   if (carts) {
  //     if (itemOnCarts) {
  //       const changeCharts = carts.map((cart) => {
  //         if (cart.id === id) {
  //           return {
  //             id: id ?? cart.id,
  //             name: data.name,
  //             note,
  //             image: data.image,
  //             price: selectedPrice ?? cart.price,
  //             modifiers: selectedModifier ?? cart.modifiers,
  //             count: itemCount ?? cart.count,
  //           };
  //         }
  //         return cart;
  //       });
  //       setCarts(changeCharts);
  //     } else {
  //       setCarts([
  //         ...carts,
  //         {
  //           id,
  //           name: data.name,
  //           note,
  //           image: data.image,
  //           price: selectedPrice,
  //           modifiers: selectedModifier,
  //           count: itemCount,
  //         },
  //       ]);
  //     }
  //   } else {
  //     setCarts([
  //       {
  //         id,
  //         name: data.name,
  //         note,
  //         image: data.image,
  //         price: selectedPrice,
  //         modifiers: selectedModifier,
  //         count: itemCount,
  //       },
  //     ]);
  //   }

  //   setAdded(true);
  //   setTimeout(() => {
  //     setButtonShow(false)
  //     goBack()
  //   }, 1000);
  // };

  useEffect(() => {
    if (carts && itemOnCarts) {
      setSelectedModifier(itemOnCarts.modifiers);
      setSelectedPrice(itemOnCarts.price);
      setItemCount(itemOnCarts.count);
      setNote(itemOnCarts.note);
    }
  }, []);

  // useEffect(() => {
  //   dispatch(saveItemId(id));

  //   if (getSavedItem && getSavedId !== id) dispatch(saveItem(null));
  // }, []);

  // useEffect(() => {
  //   if (responseData) {
  //     dispatch(saveItem(responseData));
  //   }
  // }, [responseData]);

  // useEffect(() => {
  //   const progress = ref.current as any;
  //   if (!data) {
  //     progress?.continuousStart();
  //   } else {
  //     progress?.complete();
  //   }
  // }, [data, error]);

  // useEffect(() => {
  //   if (main?.cart_is_show) setButtonShow(true);
  // }, [main?.cart_is_show]);

  /**
   * @description find ingredient warning
   * @returns ingredient warning data
   */
  const findIngredientsWarning = (): IngredientsWarningResponse[] | [] => {
    const warning: IngredientsWarningResponse[] = [];
    const copiedIngredientWarning: IngredientsWarningResponse[] = Array.from(ingredientsWarningData ?? []);

    // eslint-disable-next-line array-callback-return
    copiedIngredientWarning.filter((ingredient) => {
      const findById = itemData?.data?.ingredients_warning.find((id) => id === ingredient.id);

      if (findById) {
        warning.push(ingredient);
      }
    });

    return warning.sort((a, b) => (a.id > b.id ? 1 : -1));
  };

  useEffect(() => {
    // handle required modifier (min > 0)
    const mapRequiredModifier = requiredModifier.map((mod) => {
      const findMatch = selectedModifier.find(({ name }) => name === mod.name);

      if (findMatch) {
        const filter = findMatch.items.filter(({ is_selected }) => is_selected);
        const min = findMinModifier(mod.name);

        return {
          name: mod.name,
          is_fulfilled: filter.length >= min,
        };
      }

      return mod;
    });
    setRequiredModifier(mapRequiredModifier);

    // handle maximal modifier (<= max)
    const mapReachMaxModifier = reachMaxModifier.map((mod) => {
      const findMatch = selectedModifier.find(({ name }) => name === mod.name);

      if (findMatch) {
        const filter = findMatch.items.filter(({ is_selected }) => is_selected);
        const max = findMaxModifier(mod.name);

        return {
          name: mod.name,
          is_fulfilled: filter.length >= max,
        };
      }

      return mod;
    });
    setReachMaxModifier(mapReachMaxModifier);
  }, [selectedModifier]);

  useEffect(() => {
    if (itemData?.data?.modifiers) {
      const { modifiers } = itemData.data;

      const required = modifiers.filter(({ min }) => min > 0);
      setRequiredModifier(
        required.map(({ name }) => ({
          name,
          is_fulfilled: false,
        })),
      );

      const reachMax = modifiers.filter(({ max }) => max > 0);
      setReachMaxModifier(
        reachMax.map(({ name }) => ({
          name,
          is_fulfilled: false,
        })),
      );

      if (itemOnCarts) return;

      setSelectedModifier(
        modifiers.map((mod) => ({
          name: mod.name,
          items: mod.modifier_items.map((item) => ({
            name: item.name,
            price: item.price,
            is_selected: false,
          })),
        })),
      );
    }
  }, [itemData?.data?.modifiers]);

  useEffect(() => {
    if (itemData?.data?.prices && !itemOnCarts) {
      setSelectedPrice({ ...itemData.data.prices[0], priceIndex: 0 });
    }
  }, [itemData?.data?.prices, itemOnCarts]);

  useEffect(() => {
    if (error && "status" in error && error.status === 401) {
      replace(`/menu/${token}/unauth`);
    }
  }, [error]);

  useEffect(() => {
    if (!currentLanguage) {
      dispatch(setCurrentLanguage({ code: "en", name: "English", type: "LTR" }));
    }
  }, [!currentLanguage]);

  return (
    <div className="w-full">
      {/* <LoadingBar height={4} color="#dc5c4b" ref={ref} /> */}

      <button
        type="button"
        className="absolute p-3 top-4 left-4 shadow-button rounded z-20 bg-white"
        onClick={goBack}
      >
        <BackIcon className="h-4 w-4" />
      </button>

      {!itemData ? (
        // <div className="flex flex-col animate-pulse h-screen w-screen bg-white">
        //   <div className="aspect-w-16 aspect-h-16 bg-neutral-200 mb-8"></div>
        //   <div className="bg-white p-8 flex-1">
        //     <div className="h-3 bg-neutral-200 rounded-full mb-4"></div>
        //     <div className="h-3 bg-neutral-200 rounded-full mb-16 mr-16"></div>
        //     <div className="h-3 bg-neutral-200 rounded-full mb-4"></div>
        //     <div className="h-3 bg-neutral-200 rounded-full mb-16 mr-16"></div>
        //   </div>
        //   <div className="h-12 bg-neutral-200"></div>
        // </div>
        // <div className="flex items-center justify-center h-screen">
        //   <div className="border-4 border-t-primary border-b-primary border-r-transparent border-l-transparent w-7 h-7 animate-spin-fast rounded-full"></div>
        // </div>
        <div
          className="flex justify-center items-center h-screen w-full max-w-3xl transition-opacity duration-500 delay-200"
        >
          <SpinnerIcon className="animate-spin-fast h-8 w-8 mx-auto" />
        </div>
      ) : (
        <>
          {/* <button
            className="absolute p-3 top-4 left-4 shadow-button rounded z-20 bg-white"
            onClick={() => {
              // setButtonShow(false)
              goBack();
            }}
          >
            <BackIcon className="h-4 w-4" />
          </button> */}

          <div className="flex flex-col justify-start gap-4">
            {itemData?.data?.image || itemData?.data?.video ? (
              <Carousel
                style={{ background: "white" }}
                height="current"
                renderBottomCenterControls={
                  ({ currentSlide, goToSlide }) => (itemData?.data?.image && itemData?.data?.video ? (
                    <div className="flex flex-row items-center gap-2">
                      {[0, 1].map((slide) => (
                        <button
                          key={slide}
                          type="button"
                          className={`flex items-center justify-center ${
                            slide === currentSlide ? "text-black" : "text-secondary"
                          }`}
                          onClick={() => goToSlide(slide)}
                        >
                          &#8226;
                        </button>
                      ))}
                    </div>
                  ) : null)
                }
                renderCenterLeftControls={({ previousSlide }) => (itemData?.data?.image && itemData?.data?.video ? (
                  <button
                    className="text-3xl p-2"
                    type="button"
                    onClick={previousSlide}
                  >
                    &#8249;
                  </button>
                ) : null)}
                renderCenterRightControls={({ nextSlide }) => (itemData?.data?.image && itemData?.data?.video ? (
                  <button
                    className="text-3xl p-2"
                    type="button"
                    onClick={nextSlide}
                  >
                    &#8250;
                  </button>
                ) : null)}
              >
                {itemData?.data?.image ? (
                  <div className="aspect-w-16 aspect-h-16">
                    <img
                      src={IMAGES_URL + itemData.data.image}
                      className="object-cover"
                      alt="food"
                    />
                  </div>
                ) : null}
                {itemData?.data?.video ? (
                  <div className="aspect-w-16 aspect-h-16">
                    <video
                      src={IMAGES_URL + itemData.data.video}
                      autoPlay
                      loop
                      title={itemData?.data?.name}
                      className="object-cover"
                    >
                      <track kind="captions" />
                    </video>
                  </div>
                ) : null}
              </Carousel>
            ) : (
              <div className="mt-12" />
            )}

            <div className="relative">
              <h1 className="text-[22px]  text-center px-14 text-black">{itemData?.data?.name ?? ""}</h1>

              <div className={`flex flex-wrap gap-2 items-center justify-center ${isRTL ? "flex-row-reverse" : "flex-row"} mt-2`}>
                {main?.price_is_show
                  // ((itemData?.data?.prices ?? [{ price: 0, description: "" }]).length > 1 || (
                  //   <p className="font-normal text-black text-center">
                  //     {query.get("currency")} {itemData?.data?.prices[0].price}
                  //   </p>
                  // ))}
                  && itemData?.data?.prices
                  && itemData.data.prices.length === 1 && (
                  <>
                    <p className="font-normal text-black text-center">
                      {query.get("currency")}
                      {" "}
                      {itemData?.data?.prices[0].price}
                    </p>

                    {itemData?.data?.calories !== undefined && itemData?.data?.calories > 0 && (
                      <div className="w-1 h-1 rounded-full bg-black" />
                    )}
                  </>
                )}

                {itemData?.data?.calories !== undefined && itemData?.data?.calories > 0 && (
                  <>
                    {/* <div className="w-1 h-1 rounded-full bg-black"></div> */}
                    <div
                      className="flex items-center justify-center text-center gap-1"
                      style={{
                        direction: isRTL ? "rtl" : "ltr",
                      }}
                    >
                      <FireIcon className="h-6 w-6 text-black" />
                      <p className="text-sm text-black">
                        {itemData?.data?.calories}
                        {" "}
                        {isRTL ? "سعرات حراريه" : "Calories"}
                      </p>
                    </div>
                  </>
                )}

                {itemData?.data?.prep_time !== undefined && itemData?.data?.prep_time > 0 ? (
                  <>
                    {
                      ((main?.price_is_show
                      && itemData?.data?.prices
                      && itemData?.data?.prices?.length === 1)
                    || (itemData?.data?.calories !== undefined
                      && itemData?.data?.calories > 0)) ? (
                          // eslint-disable-next-line react/jsx-indent
                          <div className="w-1 h-1 rounded-full bg-black" />
                        ) : (
                          ""
                        )
                    }
                    <div
                      className="flex items-center justify-center text-center gap-1"
                      style={{ direction: isRTL ? "rtl" : "ltr" }}
                    >
                      <ClockIcon className="h-6 w-6 text-black" />
                      <p className="text-sm text-black">
                        {itemData?.data?.prep_time}
                        {" "}
                        {isRTL
                          ? "دﻗﺎﺋﻖ"
                          : itemData?.data?.prep_time && itemData.data.prep_time > 1
                            ? "Minutes"
                            : "Minute"}
                      </p>
                    </div>
                  </>
                ) : null}

                {itemData?.data?.nutrition_infos && (
                  <button
                    type="button"
                    className="button-default-outline py-1 px-2 rounded-sm text-[10.15px] font-normal"
                    title="Nutrition info"
                    onClick={() => setOpenNutritionFacts(true)}
                  >
                    {currentLanguage?.code === "ar" ? "معلومات التغذية" : "Nutrition info"}
                  </button>
                )}
              </div>

              {/* <div className="flex gap-2 items-center absolute top-0 bottom-0 right-4"> */}
              <div
                className="flex gap-2 items-center justify-center mt-3"
                style={{
                  direction: isRTL ? "rtl" : "ltr",
                }}
              >
                {itemData?.data?.isNew && <NewIcon className="h-6 w-6" />}
                {itemData?.data?.isSignature && <SignatureIcon className="h-6 w-6" />}
                {itemData?.data?.ingredients_warning && findIngredientsWarning().length > 0 ? (
                  <>
                    {findIngredientsWarning().flatMap((ingredient: IngredientsWarningResponse) => (
                      <div
                        className="flex items-center justify-center text-center"
                        key={ingredient.id}
                      >
                        <img
                          className="h-6 w-6 has-tooltip"
                          src={`${IMAGES_URL}ingredients/${ingredient.icon}`}
                          alt={ingredient.id}
                          style={{
                            filter:
                              "invert(49%) sepia(68%) saturate(3246%) hue-rotate(334deg) brightness(95%) contrast(81%)",
                          }}
                        />

                        {ingredient.name[ingredient.name.findIndex((name) => name.lang_id === currentLanguage?.code)]?.text ? (
                          <span className="tooltip">
                            {
                              ingredient.name[ingredient.name.findIndex((name) => name.lang_id === currentLanguage?.code)]
                                ?.text
                            }
                          </span>
                        ) : (
                          <span className="tooltip">
                            {ingredient.name[ingredient.name.findIndex((name) => name.lang_id === "en")]?.text}
                          </span>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            {itemData?.data?.description && (
              <p
                className="text-base px-5 pb-4 text-black"
                style={{
                  direction: isRTL ? "rtl" : "ltr",
                }}
              >
                {itemData.data.description}
              </p>
            )}

            {/* {(itemData?.data?.prices ?? [{ price: 0, description: "" }]).length > 1 && (
              <div className="border-b border-gray"></div>
            )} */}

            {main?.price_is_show && (itemData?.data?.prices ?? [{ price: 0, description: "" }]).length > 1 && (
              <div
                className="flex flex-col gap-2 px-4"
                style={{ direction: isRTL ? "rtl" : "ltr" }}
              >
                {/* <p className="text-sm text-secondary">Choose Option</p> */}
                {itemData?.data?.prices.map((price, index) => (
                  <label
                    key={index.toString()}
                    htmlFor={`price-${index}`}
                    className="flex items-center py-4 border-b border-b-primary last:border-b-0"
                  >
                    {/* <input
                      type="radio"
                      id={price?.priceIndex}
                      name={itemData?.data?.name ?? "food"}
                      onChange={() => setSelectedPrice(price)}
                      checked={price?.priceIndex === selectedPrice??.priceIndex}
                      disabled={!itemData.data}
                    /> */}
                    <input
                      type="radio"
                      className="price-checkbox !opacity-0 absolute -z-10"
                      id={`price-${index}`}
                      name={itemData?.data?.name ?? "food"}
                      onChange={() => setSelectedPrice({ ...price, priceIndex: index })}
                      checked={index === (selectedPrice?.priceIndex || 0)}
                      disabled={!itemData.data}
                    />
                    <div
                      id={`price-checkbox-${index}`}
                      className={`relative w-6 h-6 rounded-full border border-primary cursor-pointer ${
                        index === (selectedPrice?.priceIndex || 0) ? "bg-primary" : "bg-transparent"} ${isRTL ? "ml-2" : "mr-2"} flex items-center justify-center`}
                    >
                      {index === (selectedPrice?.priceIndex || 0) && <CheckIcon className="w-4 h-4 text-white" />}
                    </div>

                    <span className="flex-1 flex items-center text-black">{price.description}</span>

                    <span className="text-black font-normal">
                      {query.get("currency")}
                      {" "}
                      {price.price}
                    </span>
                  </label>
                ))}
              </div>
            )}

            {selectedModifier?.map((mod, index) => {
              const isReachMax = reachMaxModifier.find(({ name }) => name === mod.name)?.is_fulfilled;

              return (
                <div
                  className="flex flex-col gap-2 px-4 mb-4"
                  style={{ direction: isRTL ? "rtl" : "ltr" }}
                  key={index.toString()}
                >
                  <div className="flex justify-between">
                    <p className="text-base opacity-75 text-secondary">{mod.name}</p>

                    <div className="flex gap-1">
                      {findMinModifier(mod.name) > 0 && (
                        <p className="bg-error bg-opacity-10 text-error rounded py-1 px-2 text-xs">
                          Min
                          {" "}
                          {findMinModifier(mod.name)}
                        </p>
                      )}

                      {findMaxModifier(mod.name) > 0 && (
                        <p className="bg-success bg-opacity-10 text-success rounded py-1 px-2 text-xs">
                          Max
                          {" "}
                          {findMaxModifier(mod.name)}
                        </p>
                      )}
                    </div>
                  </div>

                  {mod.items.map((item, itemIndex) => (
                    <label
                      key={itemIndex.toString()}
                      htmlFor={item.name}
                      className={
                        `flex items-center py-4 border-b border-b-primary last:border-b-0 relative ${
                          isReachMax && !item.is_selected ? "opacity-50" : "opacity-100"}`
                      }
                    >
                      <input
                        className="!opacity-0 absolute -z-10 modifier-checkbox"
                        type="checkbox"
                        name={mod.name}
                        id={item.name}
                        onChange={(e) => handleModifierChange(mod.name, item.name, e.target.checked)}
                        checked={item.is_selected}
                        disabled={!itemData || (isReachMax && !item.is_selected)}
                      />

                      <div id={`modifier-checkbox-${itemIndex}`} className={`relative w-6 h-6 rounded-full border border-primary cursor-pointer ${item.is_selected ? "bg-primary" : "bg-transparent"} ${isRTL ? "ml-2" : "mr-2"} flex items-center justify-center`}>
                        {item.is_selected && <CheckIcon className="w-4 h-4 text-white" />}
                      </div>

                      <span className="flex-1 text-black">{item.name}</span>
                      <span className="text-black font-normal">
                        +
                        {" "}
                        {query.get("currency")}
                        {" "}
                        {item.price}
                      </span>
                    </label>
                  ))}
                </div>
              );
            })}

            <div className="px-4 pb-12">
              <textarea
                rows={3}
                className="input-base border-black rounded-lg"
                placeholder="Add note"
                disabled={!itemData}
                onChange={(e) => setNote(e.target.value)}
                value={note}
              />

              {/* {main?.cart_is_show && ( */}
              <div className="flex gap-4 justify-center items-center my-4">
                <button
                  className="rounded-full bg-transparent text-active ring-1 ring-primary w-8 h-8 flex justify-center items-center"
                  onClick={handleCountMinus}
                  disabled={!itemData}
                  type="button"
                >
                  <p className="text-2xl">-</p>
                </button>

                <p className="font-bold text-center text-lg">{itemCount}</p>

                <button
                  className="rounded-full bg-primary text-white w-8 h-8 flex justify-center items-center"
                  onClick={() => setItemCount((state) => state + 1)}
                  disabled={!itemData}
                  type="button"
                >
                  <p className="text-2xl">+</p>
                </button>
              </div>
              {/* )} */}
            </div>
          </div>

          {/* {main?.cart_is_show && ( */}
          <Portal>
            <div
              className={`w-full lg:w-1/3 bg-white fixed -bottom-8 left-0 transition-all ${isButtonShow ? "-translate-y-8 opacity-1 delay-300" : "opacity-0"}`}
              style={{ minWidth: 320 }}
            >
              <button
                type="button"
                className="w-full button-primary rounded-none disabled:opacity-50"
                onClick={addItemToCart}
                disabled={!itemData || !requiredModifier.every((mod) => mod.is_fulfilled)}
              >
                {itemOnCarts ? "Update Cart" : "Add to Cart"}
              </button>
            </div>
          </Portal>
          {/* )} */}

          {/* Modal Nutrition facts */}
          <Portal>
            <ModalNutritionFact
              open={openNutritionFacts}
              close={() => setOpenNutritionFacts(false)}
              nutrition_facts={itemData?.data?.nutrition_infos}
            />
          </Portal>
        </>
      )}
    </div>
  );
}

export default withData(Item);
