import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

export type OrderDineInRequestBody = {
  table_id: string;
  payment_type_id: string;
  notes: string;
  promo_code: string;
  orders: {
    item_id: string;
    name: string;
    qty: number;
    price_unit: number;
    notes: string;
    modifiers: {
      name: string;
      price_unit: number;
    }[];
  }[];
}

export type OrderDineInResponseBody = {
  date: string
  order_type_id: string
  payment_type_id: string
  table: string
  price_discount: number
  price_subtotal: number
  price_total: number
  orders: Array<{
    name: string
    qty: number
    price_unit: number
    price_total: number
    modifiers: Array<any>
  }>
}

export type OrderDeliveryRequestBody = {
  cust_name: string;
  cust_email: string;
  cust_phone: string;
  cust_address: string;
  cust_location: number[];
  cust_building: string;
  cust_zone: string;
  cust_street: string;
  cust_apart: string;
  cust_floor: string;
  payment_type_id: string;
  notes: string;
  promo_code: string;
  orders: OrderDineInRequestBody["orders"];
}

export type OrderDeliveryResponseData = {
  order_number: number;
  date: string;
  order_type_id: string;
  payment_type_id: string;
  price_discount: number;
  price_subtotal: number;
  price_total: number;
  cust_name: string;
  cust_email: string;
  cust_phone: string;
  cust_address: string;
  cust_building: string;
  cust_zone: string;
  cust_street: string;
  cust_apart: string;
  cust_floor: string;
  orders: {
    name: string;
    qty: number;
    price_unit: number;
    price_total: number;
    modifiers: {
      name: string;
      price_unit: number;
      price_total: number;
    }[];
  }[];
}

export type OrderDriveThroughRequestBody = Pick<OrderDeliveryRequestBody,
  "cust_name"
  | "cust_email"
  | "cust_phone"
  | "payment_type_id"
  | "notes"
  | "promo_code"
  | "orders"
> & {
  cust_plate_num: string;
}

export type OrderDriveThroughResponseData = {
  order_number: number;
  date: string;
  order_type_id: string;
  payment_type_id: string;
  price_discount: number;
  price_subtotal: number;
  price_total: number;
  cust_name: string;
  cust_email: string;
  cust_phone: string;
  cust_plate_num: string;
  orders: {
    name: string;
    qty: number;
    price_unit: number;
    price_total: number;
    modifiers: {
      name: string;
      price_unit: number;
      price_total: number;
    }[]
  }[]
}

export type OrderPickUpRequestBody = OrderDriveThroughRequestBody;
export type OrderPickUpResponseData = OrderDriveThroughResponseData;

export const orderServices = createApi({
  reducerPath: "create-order-reducer",
  baseQuery,
  endpoints: (builder) => ({
    createOrderDineIn: builder.mutation<HttpResponse<OrderDineInResponseBody>, OrderDineInRequestBody>({
      query: (body) => ({
        url: "/order/dinein",
        method: "POST",
        body,
      }),
    }),

    createOrderDelivery: builder.mutation<HttpResponse<OrderDeliveryResponseData>, OrderDeliveryRequestBody>({
      query: (body) => ({
        url: "/order/delivery",
        method: "POST",
        body,
      }),
    }),

    createOrderDriveThrough: builder.mutation<
      HttpResponse<OrderDriveThroughResponseData>, OrderDriveThroughRequestBody>({
        query: (body) => ({
          url: "/order/drivetru",
          method: "POST",
          body,
        }),
      }),

    createOrderPickUp: builder.mutation<HttpResponse<OrderPickUpResponseData>, OrderPickUpRequestBody>({
      query: (body) => ({
        url: "/order/pickup",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useCreateOrderDineInMutation,
  useCreateOrderDeliveryMutation,
  useCreateOrderDriveThroughMutation,
  useCreateOrderPickUpMutation,
} = orderServices;
