import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as EmailIcon } from "../../../assets/icons/ic_mail.svg";
import { ReactComponent as MapPinIcon } from "../../../assets/icons/ic_map_pin.svg";
import { ReactComponent as PhoneOutIcon } from "../../../assets/icons/ic_phone_outgoing.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/ic_user.svg";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Portal, PriceSummariesWithCheckoutButton } from "../../../components";
import { CheckoutForm, PaymentTypeForm } from "../../../components/forms/checkouts";
import ModalChooseOnMap from "../../../components/modals/modal-choose-on-map";
import { setData, submitOrder } from "../../../features/orders";
import { useGeolocation } from "../../../hooks";

export interface OrderDeliveryForm {
  cust_name: string,
  cust_email: string,
  cust_phone: string,
  cust_address: string,
  cust_location: number[],
  cust_building: string,
  cust_zone: string,
  cust_street: string,
  cust_apart: string,
  cust_floor: string,
  payment_type_id: string,
  notes: string,
}

function FinishOrderDelivery() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { coordinates } = useGeolocation();
  const { currentLanguage, currency } = useAppSelector(({ globalState }) => globalState);

  const [openMap, setOpenMap] = useState<boolean>(false);
  const [formState, setFormState] = useState<OrderDeliveryForm>({
    cust_name: "",
    cust_email: "",
    cust_phone: "",
    cust_address: "",
    cust_location: [coordinates.lat, coordinates.lng],
    cust_building: "",
    cust_zone: "",
    cust_street: "",
    cust_apart: "",
    cust_floor: "",
    payment_type_id: "",
    notes: "",
  });

  const isRTL = currentLanguage?.code === "ar";

  const handleOnChangeForm = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value, name } = event.target;

    if (!(Object.keys(formState).find((key) => key === name))) return;

    if (name === "cust_phone") {
      const pattern = /^[0-9+-.]/gi;
      const regex = new RegExp(pattern);

      if (value.length > 0 && !regex.test(value)) {
        // console.log("not valid");
        return;
      }
    }

    setFormState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSetPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setFormState((prevState) => ({
      ...prevState,
      payment_type_id: value,
    }));
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(setData({
      ...formState,
    }));
    dispatch(submitOrder(true));

    history.replace("order-summary");
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    if ("geolocation" in window.navigator) {
      setFormState((prevState) => ({
        ...prevState,
        cust_location: [coordinates.lat, coordinates.lng],
      }));
    }
  }, [coordinates.lat, coordinates.lng]);

  useEffect(() => {
    console.log(formState.cust_location);
  }, [formState.cust_location]);

  return (
    <>
      <form
        onSubmit={handleOnSubmit}
        className="p-4"
        style={{
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        {/* Name */}
        <CheckoutForm.Input
          className="mb-4"
          isRTL={isRTL}
          id="input-name"
          name="cust_name"
          label={isRTL ? "الاسم" : "Name"}
          showIcon
          icon={UserIcon}
          value={formState.cust_name}
          required
          onChange={handleOnChangeForm}
        />

        {/* Email */}
        <CheckoutForm.Input
          className="mb-4"
          isRTL={isRTL}
          id="input-email"
          name="cust_email"
          label={isRTL ? "البريد الالكتروني" : "E-mail"}
          showIcon
          icon={EmailIcon}
          value={formState.cust_email}
          onChange={handleOnChangeForm}
        />

        {/* Phone number */}
        <CheckoutForm.Input
          className="mb-4"
          isRTL={isRTL}
          id="input-phone"
          name="cust_phone"
          label={isRTL ? "رقم الهاتف" : "Phone number"}
          type="tel"
          showIcon
          icon={PhoneOutIcon}
          value={formState.cust_phone}
          required
          onChange={handleOnChangeForm}
        // onChangePhoneNumber={handleSetPhoneNumber}
        />

        {/* Address */}
        <CheckoutForm.TextArea
          className="mb-4"
          isRTL={isRTL}
          id="input-address"
          name="cust_address"
          label={isRTL ? "العنوان " : "Address"}
          value={formState.cust_address}
          required
          onChange={handleOnChangeForm}
        />

        {/* Choose on map */}
        <button
          type="button"
          className={clsx(
            "button-primary-outline rounded-full",
            "text-xs",
            "flex items-center justify-between gap-2",
            "mx-auto w-auto",
            "mb-6",
          )}
          onClick={() => setOpenMap(true)}
          title="Choose on map"
        >
          <MapPinIcon className="w-3 h-3" />
          <span>Choose on map</span>
        </button>

        {/* Building */}
        <CheckoutForm.Input
          className="mb-4"
          isRTL={isRTL}
          id="input-building"
          name="cust_building"
          label={isRTL ? "المبنى" : "Building"}
          value={formState.cust_building}
          onChange={handleOnChangeForm}
        />

        {/* Zone */}
        <CheckoutForm.Input
          className="mb-4"
          isRTL={isRTL}
          id="input-zone"
          name="cust_zone"
          label={isRTL ? "المنطقة" : "Zone"}
          value={formState.cust_zone}
          onChange={handleOnChangeForm}
        />

        {/* Street */}
        <CheckoutForm.Input
          className="mb-4"
          isRTL={isRTL}
          id="input-street"
          name="cust_street"
          label={isRTL ? "الشارع" : "Street"}
          value={formState.cust_street}
          onChange={handleOnChangeForm}
        />

        {/* Apartment */}
        <CheckoutForm.Input
          className="mb-4"
          isRTL={isRTL}
          id="input-apart"
          name="cust_apart"
          label={isRTL ? "الشقة" : "Apartment"}
          value={formState.cust_apart}
          onChange={handleOnChangeForm}
        />

        {/* Floor */}
        <CheckoutForm.Input
          className="mb-6"
          isRTL={isRTL}
          id="input-floor"
          name="cust_floor"
          label={isRTL ? "الطابق" : "Floor"}
          value={formState.cust_floor}
          onChange={handleOnChangeForm}
        />

        {/* Payment Type */}
        <h3 className="mt-2 mb-6 text-lg text-center">{isRTL ? "نوع الدفع" : "Payment Type"}</h3>

        <PaymentTypeForm
          value={formState.payment_type_id}
          onChange={handleSetPayment}
          required
          cardText={{
            cash: {
              title: isRTL ? "الدفع نقدًا" : "Cash",
              description: isRTL ? "ادفع نقدًا عند استلام طلبك" : "Pay at delivery with cash",
            },
            credit: {
              title: isRTL ? " بطاقة الائتمان" : "Credit Card",
              description: isRTL ? "ادفع ببطاقة الائتمان عند استلام طلبك" : "Pay at delivery with credit card",
            },
          }}
        />

        {/* Notes */}
        <div className="w-full mt-6 mb-8 text-center text-black">
          <h3 className="text-lg">{isRTL ? "إضافة ملاحظة" : "Add a note"}</h3>
          <p className="text-sm">{isRTL ? "أضف ملاحظة حول طلبك" : "Add a note about your order"}</p>

          <textarea
            name="notes"
            className="mt-2 border-black rounded-lg input-base"
            placeholder={isRTL ? "اختياري" : "Optional"}
            rows={4}
            value={formState.notes}
            onChange={handleOnChangeForm}
          />
        </div>

        <PriceSummariesWithCheckoutButton
          button={{
            type: "submit",
          }}
          rewriteClassName="fixed bottom-0 left-0 z-20 w-full bg-white"
          currency={currency}
          showPromoCodeForm
        />
      </form>

      <Portal>
        {openMap && (
          <ModalChooseOnMap
            open={openMap}
            onClose={() => setOpenMap(false)}
            defaultMapCenter={{
              lat: formState.cust_location[0],
              lng: formState.cust_location[1],
            }}
            onSubmit={({ center, address }) => {
              setFormState((prevState) => ({
                ...prevState,
                cust_location: [center.lat, center.lng],
                cust_address: address,
              }));
              setOpenMap(false);
            }}
          />
        )}
      </Portal>
    </>
  );
}

export default FinishOrderDelivery;
