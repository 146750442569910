import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as CallIcon } from "../assets/icons/ic_call.svg";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as CheckRoundedIcon } from "../assets/icons/ic_check_rounded.svg";

import { IMAGES_URL } from "../app/constants";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { LanguageModel } from "../app/types";
import { Layout, withData } from "../components";
import { setCurrentLanguage, setOrderType } from "../features/globalState";
import type { InitialState as GlobalStateTypes } from "../features/globalState/types";
import { SessionStorage } from "../utils";
import type { IsDineIn } from "../app/types";

// interface Props {
//   token: string | null;
// }

function FrontPage() {
  const dispatch = useAppDispatch();
  const { push, replace } = useHistory();
  const {
    currentLanguage, languageData, mainData, token,
  } = useAppSelector(({ globalState }) => globalState);
  const { isSubmitted: isOrderSubmitted } = useAppSelector(({ orders }) => orders);

  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const dineIn = SessionStorage.getValue<IsDineIn>("dineIn") || null;
  const isRTL = currentLanguage?.code === "ar";

  const setLanguage = (lang: LanguageModel) => {
    dispatch(setCurrentLanguage(lang));
  };

  /**
   * Set Order type and redirect to Homepage
   * @param {GlobalStateTypes['orderType']} orderType order type
   */
  const handleSetOrderType = (orderType: GlobalStateTypes["orderType"]) => {
    dispatch(setOrderType(orderType));
    push({
      pathname: `/menu/${token}/home`,
      state: {
        lang: currentLanguage,
      },
    });
  };

  // useEffect(() => {
  //   if (token) replace(`/${token}`);
  // }, [token]);

  // useEffect(() => {
  //   if (main?.welcome_texts && language) {
  //     const langID = language.code;
  //     const text = main.welcome_texts.find(({lang_id}) => lang_id === langID)
  //     // setWelcomeText(text?.name);
  //   }
  // }, [main?.welcome_texts, language])

  // useEffect(() => {
  //   if (data?.data) {
  //     const defaultLanguage = data.data.lang_list[0];
  //     setLanguage(currentLanguage || defaultLanguage);
  //   }
  // }, [data?.data]);

  useEffect(() => {
    if (mainData && !mainData.venue_is_active) {
      replace(`/menu/${token}/home`);
    }

    if (mainData?.phone_num) {
      // const countryCode = main?.phone_num.split("+")?.[0];
      const phoneNum = mainData?.phone_num.split("+")?.[1] ?? mainData?.phone_num ?? 0;
      setPhoneNumber(phoneNum);
    }
  }, [mainData]);

  return (
    <Layout>
      <div className="flex flex-col items-center h-full w-full max-w-3xl">
        <div className="flex-1 flex flex-col gap-4 items-center justify-center">
          {/* <h3 className="text-3xl">{welcomeText}</h3> */}
          {mainData?.logo ? (
            <img
              src={IMAGES_URL + mainData.logo}
              alt="logo"
              className="h-40 w-40 object-contain"
            />
          ) : (
            <Logo className="w-40 h-40" />
          )}

          <h1 className="text-lg font-medium">{mainData?.bus_name ?? ""}</h1>
          {/* <p className="text-sm text-secondary">Choose Language</p> */}

          <select
            name="language"
            id="language"
            className="select-base rounded-full w-44 px-4 py-3"
            value={currentLanguage?.code ?? "en"}
            onChange={(e) => {
              const selectedLang = languageData?.lang_list.find(({ code }) => code === e.target.value);
              if (selectedLang) setLanguage(selectedLang);
            }}
          >
            {languageData?.lang_list.map((lang) => (
              <option
                value={lang.code}
                key={lang.code}
              >
                {lang.name}
              </option>
            ))}
          </select>

          {/* <button
            type="button"
            className="button-primary rounded-full w-44 text-sm mt-2"
            onClick={() => push({
              pathname: `/menu/${token}/home`,
              state: {
                lang: currentLanguage,
              },
            })}
          >
            Tap to Start
          </button> */}

          {dineIn?.isDineIn ? (
            <button
              type="button"
              className="button-primary rounded-full w-44 text-sm mt-2"
              onClick={() => handleSetOrderType("dine-in")}
              title={isRTL ? "القائمة" : "Menu"}
            >
              {isRTL ? "القائمة" : "Menu"}
            </button>
          ) : (
            <div className="flex flex-col gap-2 mt-2">
              <button
                type="button"
                className="button-primary-outline rounded-full w-44 text-sm"
                onClick={() => handleSetOrderType("delivery")}
                title={isRTL ? "توصيل" : "Delivery"}
              >
                {isRTL ? "توصيل" : "Delivery"}
              </button>

              <button
                type="button"
                className="button-primary-outline rounded-full w-44 text-sm"
                onClick={() => handleSetOrderType("pick-up")}
                title={isRTL ? " حجز طلب" : "Pick Up"}
              >
                {isRTL ? " حجز طلب" : "Pick Up"}
              </button>

              <button
                type="button"
                className="button-primary-outline rounded-full w-44 text-sm"
                onClick={() => handleSetOrderType("drive-through")}
                title={isRTL ? "الطلب من السيارة" : "Drive-through"}
              >
                {isRTL ? "الطلب من السيارة" : "Drive-through"}
              </button>
            </div>
          )}

          {(isOrderSubmitted && dineIn?.isDineIn) && (
            <>
              <Link
                to={`/menu/${token}/checkout`}
                className="button-primary-outline rounded-full w-44 text-sm text-center"
                title={isRTL ? "طلب الحساب" : "Checkout"}
              >
                {isRTL ? "طلب الحساب" : "Checkout"}
              </Link>

              <div className="border border-primary rounded-lg p-2 w-full max-w-[273px] mt-12 flex flex-row items-start gap-4">
                <span style={{
                  direction: isRTL ? "rtl" : "ltr",
                }}
                >
                  {isRTL ? "تم إرسال طلبك بنجاح" : "Your order was sent successfuly"}
                </span>

                <CheckRoundedIcon className="h-6 w-6" />
              </div>
            </>
          )}
        </div>

        <div className="flex items-baseline pb-10">
          <a
            className="button-primary-outline rounded-full flex items-center justify-between w-44 text-sm self-end z-20"
            href={mainData?.phone_num ? `tel:+${phoneNumber}` : "#"}
          >
            <CallIcon className="h-4 w-4" />
            Call Restaurant
          </a>
        </div>
      </div>
    </Layout>
  );
}

export default withData(FrontPage);
