import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { ReactComponent as BackIcon } from "../../../assets/icons/ic_back.svg";

import { useAppSelector } from "../../../app/hooks";
import { withData } from "../../../components";
import { useURL } from "../../../hooks";

import FinishOrderDelivery from "./delivery";
import FinishOrderPickUp from "./pick-up";

function FinishOrderPage() {
  const { currentLanguage, orderType } = useAppSelector(({ globalState }) => globalState);
  const history = useHistory();
  const { getURL } = useURL();

  const isRTL = currentLanguage?.code === "ar";

  return (
    <>
      <div
        className="flex flex-col w-full h-screen pb-[200px] overflow-x-hidden pt-6 relative overflow-y-auto hide-scrollbar"
        style={{ direction: isRTL ? "rtl" : "ltr" }}
      >
        <button
          className="absolute p-3 top-4 left-4 shadow-button rounded z-10"
          onClick={() => history.goBack()}
          type="button"
          title="Back to menu"
        >
          <BackIcon className="h-4 w-4" />
        </button>

        <h2 className={clsx("text-[22px] text-center")}>
          {isRTL ? " تأكيد الطلب" : "Finish Order"}
        </h2>

        <div className="py-4 w-full">
          {(() => {
            switch (orderType) {
            case "dine-in":
              history.replace(getURL());
              return <div />;

            case "delivery":
              return <FinishOrderDelivery />;

            case "pick-up":
              return <FinishOrderPickUp />;

            case "drive-through":
              return <FinishOrderPickUp />;

            default:
              return <div />;
            }
          })()}
        </div>
      </div>

      <div />
    </>
  );
}

export default withData(FinishOrderPage);
