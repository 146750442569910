/* eslint-disable react/jsx-props-no-spreading */
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import QRCode from "react-qr-code";

import { format } from "date-fns";
import { ReactComponent as BackIcon } from "../assets/icons/ic_back.svg";
import { ReactComponent as CheckRoundedIcon } from "../assets/icons/ic_check_rounded.svg";
import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import type { DiscountTypes, IsDineIn } from "../app/types";
import { withData } from "../components";
import { OrderTypeIds, PaymentTypeId } from "../enumerations";
import { resetCart, setCartVisibility } from "../features/cart";
import {
  resetOrders,
  setOrderDiscount,
  submitOrder,
} from "../features/orders";
import type { OrderTypes } from "../features/orders/types";
import { useURL } from "../hooks";
import {
  useCreateOrderDeliveryMutation,
  useCreateOrderDineInMutation,
  useCreateOrderDriveThroughMutation,
  useCreateOrderPickUpMutation,
  type OrderDeliveryRequestBody,
  type OrderDineInRequestBody,
} from "../services";
import { SessionStorage } from "../utils";
// import DeliverySummaryPage from "../components/summaries/delivery";
// import DineInSummary from "../components/summaries/dine-in";

function RenderSection({ name, value }: { name: string, value: string }) {
  const { currentLanguage } = useAppSelector(({ globalState }) => globalState);

  const isRTL = currentLanguage?.code === "ar";

  return (
    <p
      className={clsx(
        "py-2",
        "border-b last:border-b-0",
        "flex flex-row items-start justify-between",
        "text-[12.57px] leading-[17.14px]",
      )}
      key={name}
    >
      <span className={clsx(
        "w-1/2",
        "block",
        isRTL ? "text-right" : "text-left",
      )}
      >
        {name}
      </span>
      <span className={clsx(
        "w-1/2",
        "block",
        isRTL ? "text-left" : "text-right",
      )}
      >
        {value}
      </span>
    </p>
  );
}

function OrderSummaryPage() {
  const { data, discount, isSubmitted } = useAppSelector(({ orders }) => orders);
  const { currency } = useAppSelector(({ globalState }) => globalState);
  const { orderType, currentLanguage } = useAppSelector(({ globalState }) => globalState);

  const [createOrderDineIn,
    { data: responseOrderDineIn, isLoading: isLoadingCreateOrderDineIn },
  ] = useCreateOrderDineInMutation();
  const [createOrderDelivery,
    { data: responseOrderDelivery, isLoading: isLoadingCreateOrderDelivery },
  ] = useCreateOrderDeliveryMutation();
  const [createOrderPickUp,
    { data: responseOrderPickUp, isLoading: isLoadingCreateOrderPickUp },
  ] = useCreateOrderPickUpMutation();
  const [createOrderDriveThru,
    { data: responseOrderDriveThru, isLoading: isLoadingCreateOrderDriveThru },
  ] = useCreateOrderDriveThroughMutation();

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { getURL } = useURL();

  const [orderData, setOrderData] = useState<{
    data: any,
    discount: DiscountTypes | null
  }>({
    data: null,
    discount: null,
  });

  const dineIn = SessionStorage.getValue<IsDineIn>("dineIn");
  const isRTL = currentLanguage?.code === "ar";
  const response = responseOrderDineIn || responseOrderDelivery || responseOrderPickUp || responseOrderDriveThru;
  const isLoading = isLoadingCreateOrderDineIn
    || isLoadingCreateOrderDelivery
    || isLoadingCreateOrderPickUp
    || isLoadingCreateOrderDriveThru;

  const orders = useMemo(() => {
    if (orderData.data?.orders && Array.isArray(orderData.data.orders)) {
      return orderData.data.orders as OrderTypes[];
    }

    return [];
  }, [orderData.data]);

  const prices = {
    /**
     * Count Prices
     * @param {CartItem} item Cart Item
     * @returns {number} total prices
     */
    countPrices: (item: OrderTypes): number => {
      let price = item.price_unit * item.qty;

      item.modifiers.forEach((modifier) => {
        price += (modifier.price_unit * item.qty);
      });

      return price;
    },

    /**
     * Get total prices
     * @returns total prices
     */
    getTotalPrices: () => {
      const arr: number[] = [];

      if (orders.length > 0) {
        for (let i = 0; i < orders.length; i += 1) {
          arr.push(prices.countPrices(orders[i]));
        }
      }

      const price = arr.length > 0 ? arr.reduce((a, b) => a + b) : 0.00;

      return price <= 0 ? 0 : Number(price.toFixed(2));
    },

    /**
     * Get total discount
     * @param {"amount" | "percentage"} type discount type
     * @return {number | string} discount
     */
    getTotalDiscount: (type?: "AMOUNT" | "PERCENT"): string | number => {
      const totalPrice = prices.getTotalPrices();
      const totalDiscount = discount ? (
        discount.type === "AMOUNT" ? discount.promo_amount : ((totalPrice / 100) * discount.promo_amount)
      ) : 0;

      return ((discount && discount.type === "PERCENT") && (type && type === "PERCENT")) ? `${discount.promo_amount}%` : Number(totalDiscount.toFixed(2));
    },

    /**
     * Get total price with discount
     * @returns {number} total prices
     */
    getTotalPriceWithDiscount: (): number => {
      const totalPrices = prices.getTotalPrices();
      const totalDiscount = prices.getTotalDiscount("AMOUNT") as number;

      return Number((totalPrices - totalDiscount).toFixed(2));
    },
  };

  const getOrderType = (type: string) => {
    switch (type) {
    case "order_dinein_qrmobile":
      return OrderTypeIds[isRTL ? "ar" : "en"].order_dinein_qrmobile;
    case "order_delivery":
      return OrderTypeIds[isRTL ? "ar" : "en"].order_dinein_qrmobile;
    case "order_pickup":
      return OrderTypeIds[isRTL ? "ar" : "en"].order_pickup;
    default:
      return "";
    }
  };

  const handleClickBack = () => {
    dispatch(resetCart());
    dispatch(setCartVisibility(false));
    dispatch(resetOrders());
    dispatch(setOrderDiscount(null));
    dispatch(submitOrder(false));

    history.push(getURL());
  };

  const handleClickOrderStatus = (orderNumber?: string | number) => {
    dispatch(resetCart());
    dispatch(setCartVisibility(false));
    dispatch(resetOrders());
    dispatch(setOrderDiscount(null));
    dispatch(submitOrder(false));

    history.replace(getURL(`order-status?id=${orderNumber}`));
  };

  const processOrder = () => {
    // Order type = Dine-in
    if (orderType === "dine-in") {
      if (!dineIn) {
        history.push(getURL());
        return;
      }

      createOrderDineIn({
        table_id: dineIn?.table_id || "",
        payment_type_id: data?.payment_type_id as string || "",
        notes: data?.notes as string || "",
        promo_code: discount?.discount_code || "",
        orders: data?.orders as OrderDineInRequestBody["orders"] || [],
      });
    }

    // Order type = Delivery
    if (orderType === "delivery") {
      createOrderDelivery({
        cust_name: data?.cust_name as string || "",
        cust_email: data?.cust_email as string || "",
        cust_phone: data?.cust_phone as string || "",
        cust_address: data?.cust_address as string || "",
        cust_location: data?.cust_location.length > 0 ? data?.cust_location as OrderDeliveryRequestBody["cust_location"] : [],
        cust_building: data?.cust_building as string || "",
        cust_zone: data?.cust_zone as string || "",
        cust_street: data?.cust_street as string || "",
        cust_apart: data?.cust_apart as string || "",
        cust_floor: data?.cust_floor as string || "",
        payment_type_id: data?.payment_type_id as string || "",
        notes: data?.notes as string || "",
        orders: data?.orders as OrderDeliveryRequestBody["orders"] || [],
        promo_code: discount?.discount_code as string || "",
      });
    }

    // Order type = Pick-up
    if (orderType === "pick-up") {
      createOrderPickUp({
        cust_name: data?.cust_name as string || "",
        cust_email: data?.cust_email as string || "",
        cust_phone: data?.cust_phone as string || "",
        cust_plate_num: data?.cust_plate_num as string || "",
        payment_type_id: data?.payment_type_id as string || "",
        notes: data?.notes as string || "",
        orders: data?.orders as OrderDeliveryRequestBody["orders"] || [],
        promo_code: discount?.discount_code as string || "",
      });
    }

    // Order type = Drive-through
    if (orderType === "drive-through") {
      createOrderDriveThru({
        cust_name: data?.cust_name as string || "",
        cust_email: data?.cust_email as string || "",
        cust_phone: data?.cust_phone as string || "",
        cust_plate_num: data?.cust_plate_num as string || "",
        payment_type_id: data?.payment_type_id as string || "",
        notes: data?.notes as string || "",
        orders: data?.orders as OrderDeliveryRequestBody["orders"] || [],
        promo_code: discount?.discount_code as string || "",
      });
    }
  };

  useEffect(() => {
    if (!orderType) history.push(getURL());

    if (data || discount) {
      setOrderData((prevState) => ({
        ...prevState,
        data,
        discount,
      }));

      if (isSubmitted) {
        processOrder();
      } else {
        history.replace(getURL());
      }
    }
  }, []);

  if (isLoading) {
    return (
      <div
        className={`flex justify-center items-center h-full w-full max-w-3xl transition-opacity duration-500 delay-200 ${
          isLoading ? "opacity-1" : "opacity-0"
        }`}
      >
        <SpinnerIcon className="animate-spin-fast h-8 w-8 mx-auto" />
      </div>
    );
  }

  return (
    <div
      className="w-full p-4 relative text-center pt-8"
      style={{
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <button
        className={clsx(
          "absolute z-10 top-4 left-4",
          "p-3 shadow-button rounded bg-white",
        )}
        // onClick={() => history.goBack()}
        onClick={handleClickBack}
        type="button"
        title="Back to menu"
      >
        <BackIcon className="h-4 w-4" />
      </button>

      <CheckRoundedIcon className={clsx(
        "w-14 h-14",
        "mx-auto mb-4",
      )}
      />

      <h1 className={clsx("text-[22px] leading-normal", "mb-4")}>
        {orderType === "dine-in" ? (
          isRTL ? "تم إرسال الطلب" : "Your checkout request is sent"
        ) : (
          isRTL ? "تم إرسال الطلب" : "Order Sent"
        )}
      </h1>

      {orderType !== "dine-in" && (
        <>
          <p className="text-center mb-6">
            {isRTL ? "أرسلنا ملخص طلبك إلى:" : "we sent the summary of your order to:"}
            <span className="block">
              {(response?.data && ("cust_email" in response.data) && (response?.data?.cust_email || data?.cust_email || ""))}
            </span>
          </p>

          <p>{isRTL ? "رقم الطلب :" : "Order Number :"}</p>

          <h3 className="text-[110.44px] leading-[154.61px] text-center font-extrabold font-body my-4">{(response?.data && ("order_number" in response.data)) ? response?.data?.order_number : ""}</h3>

          <button
            type="button"
            onClick={() => handleClickOrderStatus((response?.data && ("order_number" in response.data)) ? response?.data?.order_number : "")}
            className={clsx("block mb-8", "button-default-outline w-full")}
          >
            {isRTL ? "حالة الطلب" : "Order Status"}
          </button>
        </>
      )}

      {/* Details */}
      <div className="w-full mb-8">
        <h2 className={clsx("text-xl", "mb-4")}>{isRTL ? "التفاصيل" : "Details"}</h2>

        {Object.entries(response?.data || {}).map(([id, value]) => {
          if (id === "date") {
            return (
              <RenderSection
                key={id}
                // name="Date:"
                name={isRTL ? "تاريخ:" : "Date:"}
                value={format(new Date(response?.data?.date || Date.now()), "MM.dd.yyyy kk:mm:ss")}
              />
            );
          }

          if (id === "cust_name") {
            return (
              <RenderSection
                key={id}
                // name="Name:"
                name={isRTL ? "الاسم:" : "Name:"}
                value={value as unknown as string || ""}
              />
            );
          }

          if (id === "cust_email" && value) {
            return (
              <RenderSection
                key={id}
                // name="E-mail:"
                name={isRTL ? "البريد الإلكتروني:" : "E-mail:"}
                value={value as unknown as string || ""}
              />
            );
          }

          if (id === "cust_phone") {
            return (
              <RenderSection
                key={id}
                name={isRTL ? "رقم الهاتف:" : "Phone Number:"}
                value={value as unknown as string || ""}
              />
            );
          }

          if (id === "payment_type_id") {
            return (
              <RenderSection
                key={id}
                name={isRTL ? "نوع الدفع:" : "Payment Type:"}
                value={PaymentTypeId[value === "pay_credit" ? "pay_credit" : "pay_cash"] || "Cash"}
              />
            );
          }

          if (id === "table") {
            // if (id === "table" && value) {) {
            return (
              <RenderSection
                key={id}
                name={isRTL ? "طاولة" : "Table:"}
                value={value as unknown as string || ""}
              />
            );
          }

          if (id === "cust_address") {
            return (
              <RenderSection
                key={id}
                name={isRTL ? "العنوان:" : "Address:"}
                value={value as unknown as string || ""}
              />
            );
          }

          if (id === "order_type_id") {
            return (
              <RenderSection
                key={id}
                name={isRTL ? "نوع الطلب:" : "Order Type:"}
                value={getOrderType(value as string) as string || ""}
              />
            );
          }

          if (id === "cust_building") {
          // if (id === "cust_building" && value) {
            return (
              <RenderSection
                key={id}
                name={isRTL ? "المبنى:" : "Building:"}
                value={value as unknown as string || ""}
              />
            );
          }

          if (id === "cust_zone") {
          // if (id === "cust_zone" && value) {) {
            return (
              <RenderSection
                key={id}
                name={isRTL ? "منطقة:" : "Zone:"}
                value={value as unknown as string || ""}
              />
            );
          }

          if (id === "cust_street") {
          // if (id === "cust_street" && value) {) {
            return (
              <RenderSection
                key={id}
                name={isRTL ? "الشارع" : "Street:"}
                value={value as unknown as string || ""}
              />
            );
          }

          if (id === "cust_floor") {
            // if (id === "cust_floor" && value) {) {
            return (
              <RenderSection
                key={id}
                name={isRTL ? "الطابق:" : "Floor:"}
                value={value as unknown as string || ""}
              />
            );
          }

          if (id === "cust_apart") {
            // if (id === "cust_apart" && value) {) {
            return (
              <RenderSection
                key={id}
                name={isRTL ? "الشقة:" : "Apartment:"}
                value={value as unknown as string || ""}
              />
            );
          }

          if (id === "cust_plate_num") {
            // if (id === "cust_plate_num" && value) {) {
            return (
              <RenderSection
                key={id}
                name={isRTL ? "رقم الوحة:" : "Plate number:"}
                value={value as unknown as string || ""}
              />
            );
          }

          return null;
        })}

      </div>

      {/* Order Item */}
      <div className="w-full mb-8">
        <h2 className={clsx("text-xl", "mb-4")}>{isRTL ? "ملخص الطلب" : "Order Summary"}</h2>

        {/* {(response?.data?.orders || orders).map((item, index) => { */}
        {orders.map((item, index) => {
          const key = index.toString();

          return (
            <div className={clsx("flex flex-col w-full", "py-2", "border-b last:border-b-0")} key={key}>
              <p className="flex flex-row items-center justify-between w-full text-black mb-2 text-[12.57px] leading-[17.14px]">
                <span>
                  {item.name}
                  {" "}
                  {item.qty > 1 && `X${item.qty}`}
                </span>
                <span>
                  {currency}
                  {" "}
                  {prices.countPrices({
                    ...item,
                    notes: "notes" in item ? item.notes : "",
                    item_id: ("item_id" in item) ? item.item_id : key.toString(),
                  })}
                </span>
              </p>

              {item.modifiers.length > 0 && (
                <ul className={clsx("w-full")}>
                  {item.modifiers.map((modifier, idx) => {
                    const modKey = idx.toString();

                    return (
                      <li key={modKey} className="flex flex-row items-center justify-between w-full mb-1">
                        <span className="text-[11.5px] leading-[17.25px] italic">{modifier.name}</span>
                        <span className="text-[9.78px] leading-[13.34px]">
                          +
                          {" "}
                          {currency}
                          {" "}
                          {modifier.price_unit}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              )}

              {"notes" in item && (
                <p
                  className="text-xs w-full text-left italic"
                  style={{
                    direction: isRTL ? "rtl" : "ltr",
                  }}
                >
                  {item.notes}
                </p>
              )}
            </div>
          );
        })}
      </div>

      {/* Price and Discount */}
      <div className="w-full mb-4">
        <p className={clsx("mb-2", "flex flex-row items-center justify-between")}>
          <span>{isRTL ? "المجموع الجزئي" : "Subtotal"}</span>
          <span>
            {currency}
            {" "}
            {/* {prices.getTotalPrices()} */}
            {response?.data?.price_subtotal || prices.getTotalPrices()}
          </span>
        </p>

        <p className={clsx("mb-2", "flex flex-row items-center justify-between")}>
          <span>{isRTL ? "الخصم" : "Discount"}</span>
          <span>
            {/* {response?.data?.price_discount && `${currency} `} */}
            {/* {response?.data?.price_discount || (prices.getTotalDiscount(discount ? discount.type : "AMOUNT"))} */}

            {`${currency} `}
            {response?.data?.price_discount || (prices.getTotalDiscount("AMOUNT"))}
          </span>
        </p>

        <p className={clsx("mb-2", "flex flex-row items-center justify-between")}>
          <span>{isRTL ? "إجمالي" : "Total"}</span>
          <span>
            {currency}
            {" "}
            {response?.data?.price_total || prices.getTotalPriceWithDiscount()}
          </span>
        </p>
      </div>

      {orderType !== "dine-in" && (
        <>
          <div className="w-full flex items-center justify-center mb-4">
            <QRCode
              className="w-[128px] h-[128px]"
              value={`${process.env.REACT_APP_QR_URL + getURL()}`}
              height={128}
              width={128}
            />
          </div>

          <p className={clsx("text-center font-semibold font-body text-[11px] leading-[15px] italic", "mb-2")}>
            Scan to view menu
          </p>

          <p className={clsx("text-center font-semibold font-body text-[11px] leading-[15px] italic", "mb-4")}>
            Powered by cloudmenu.cloud
          </p>
        </>
      )}

      {/* {orderType === "dine-in" && (
        <DineInSummary isRTL={isRTL} />
      )} */}
      {/* {(() => {
        switch (orderType) {
        case "dine-in":
          return <DineInSummary isRTL={isRTL} />;

        case "delivery":
          return <DeliverySummaryPage />;

        default:
          return <div />;
        }
      })()} */}
    </div>
  );
}

export default OrderSummaryPage;
