import { useEffect, useReducer, useRef } from "react";
import { BASE_URL } from "../app/constants";
import { SessionStorage } from "../utils";

interface State<T> {
  data?: T;
  error?: Error;
}

type Cache<T> = { [url: string]: T };

// discriminated union type
type Action<T> =
  | { type: "loading" }
  | { type: "fetched"; payload: T }
  | { type: "error"; payload: Error };

function useFetch<T = unknown>(path: string, options?: RequestInit, skip?: boolean): State<T> {
  const cache = useRef<Cache<T>>({});
  const url = `${BASE_URL}/api${path}`;

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false);

  const initialState: State<T> = {
    error: undefined,
    data: undefined,
  };

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
    case "loading":
      return { ...initialState };
    case "fetched":
      return { ...initialState, data: action.payload };
    case "error":
      return { ...initialState, error: action.payload };
    default:
      return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    // Do nothing if the url is not given
    if (skip === true) return;

    const fetchData = async () => {
      dispatch({ type: "loading" });

      // If a cache exists for this url, return it
      if (cache.current[url]) {
        dispatch({ type: "fetched", payload: cache.current[url] });
        return;
      }

      try {
        const token = await SessionStorage.getValue("token") ?? "";
        const request = new Request(url, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const response = await fetch(request, options);

        if (!response.ok) {
          if (response.status === 401) {
            throw new Error("Unauthorized");
          } else {
            throw new Error(response.statusText);
          }
        }

        const data = (await response.json()) as T;

        cache.current[url] = data;

        if (cancelRequest.current) return;

        dispatch({ type: "fetched", payload: data });
      } catch (error) {
        if (cancelRequest.current) return;

        dispatch({ type: "error", payload: error as Error });
      }
    };

    fetchData();

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    // return () => {
    //   cancelRequest.current = true;
    // };
  }, [url, skip]);

  return state;
}

export default useFetch;
