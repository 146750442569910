import {
  type LatLngLiteral,
  type MarkerOptions,
} from "leaflet";
import {
  memo,
  useEffect,
  useState,
} from "react";
import { Marker, useMapEvent } from "react-leaflet";

import { useDebounce } from "../../hooks";

interface MapMarkerProps {
  position: LatLngLiteral;
  icon: MarkerOptions["icon"];
  onDrag?: (center: LatLngLiteral) => void;
}

function MapMarker({
  position, icon, onDrag,
}: MapMarkerProps) {
  const [center, setCenter] = useState<LatLngLiteral>(position);

  const debouncedMapCenter = useDebounce(center, 1000);

  useMapEvent("load", (event) => {
    const getCenter = event.target.getCenter();

    setCenter(getCenter);
  });

  useMapEvent("zoom", (event) => {
    const getCenter = event.target.getCenter();

    setCenter(getCenter);
  });

  useMapEvent("drag", (event) => {
    const getCenter = event.target.getCenter();

    setCenter(getCenter);
  });

  useEffect(() => {
    if (onDrag) onDrag(debouncedMapCenter);
  }, [debouncedMapCenter]);

  return (
    <Marker
      icon={icon}
      position={center}
    />
  );
}

export default memo(MapMarker);
