import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type {
  DataLanguage, HomeResponses, IngredientsWarningResponse, LanguageModel, MainResponse,
} from "../../app/types";
import type { InitialState } from "./types";
import { SessionStorage } from "../../utils";

const initialState: InitialState = {
  currency: SessionStorage.getValue("currency") || "USD",
  orderType: null,
  token: null,
  venueIsActive: false,
  currentLanguage: null,
  languageData: null,
  mainData: null,
  homeData: null,
  ingredientsWarningData: null,
  noReload: false,
};

export const globalSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    setCurrency: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      currency: payload,
    }),
    setOrderType: (state, { payload }: PayloadAction<InitialState["orderType"]>) => ({
      ...state,
      orderType: payload,
    }),
    setToken: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      token: payload,
    }),
    setVenueIsActive: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      venueIsActive: payload,
    }),
    setCurrentLanguage: (state, { payload }: PayloadAction<LanguageModel | null>) => ({
      ...state,
      currentLanguage: payload,
    }),
    setLanguageData: (state, { payload }: PayloadAction<DataLanguage | null>) => ({
      ...state,
      languageData: payload,
    }),
    setMainData: (state, { payload }: PayloadAction<MainResponse | null>) => ({
      ...state,
      mainData: payload,
    }),
    setHomeData: (state, { payload }: PayloadAction<HomeResponses | null>) => ({
      ...state,
      homeData: payload,
    }),
    setIngredientsWarningData: (state, { payload }: PayloadAction<IngredientsWarningResponse[] | null>) => ({
      ...state,
      ingredientsWarningData: payload,
    }),
    setNoReload: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      noReload: payload,
    }),
  },
});

export const {
  setCurrency,
  setOrderType,
  setToken,
  setVenueIsActive,
  setCurrentLanguage,
  setMainData,
  setNoReload,
  setHomeData,
  setIngredientsWarningData,
  setLanguageData,
} = globalSlice.actions;

export default globalSlice;
