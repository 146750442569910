/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  memo, useCallback, useEffect, useRef, useState,
} from "react";
// import LoadingBar from "react-top-loading-bar";
// import { useLocation } from "react-router-dom";

import { useHistory } from "react-router-dom";
import { ReactComponent as BackIcon } from "../assets/icons/ic_back.svg";
import { ReactComponent as ChevronIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";
import { ReactComponent as CartIcon } from "../assets/icons/ic_cart_new.svg";
import { ReactComponent as LogoIcon } from "../assets/logo.svg";
// import cartImage from "../assets/shopping_cart.png";

import { IMAGES_URL } from "../app/constants";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  HomeCategoryResponses,
} from "../app/types";
import {
  Layout, withData, Portal, ShoppingCart,
} from "../components";
import { CategoryCard, ListItemCard } from "../components/cards";
import {
  saveBannerUrl,
  saveCategory,
  // saveHomeData,
  saveLogoUrl,
} from "../features/cache/slice";
import { setNoReload } from "../features/globalState";
import { usePreviousState } from "../hooks";
import { SessionStorage } from "../utils";
import { setCartVisibility } from "../features/cart";

function Home() {
  const {
    currentLanguage, token, noReload, venueIsActive, homeData, mainData,
  } = useAppSelector(({ globalState }) => globalState);
  const { savedLogoUrl, savedCategory, savedBannerUrl } = useAppSelector(({ cache }) => cache);

  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const categorySliderRef = useRef<HTMLDivElement>(null);

  const [categories, setCategories] = useState<HomeCategoryResponses[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<HomeCategoryResponses>();
  const [currentSliderIndex, setCurrentSliderIndex] = useState<number>(0);
  const [categoryScrollPosition, setCategoryScrollPosition] = useState<number>(SessionStorage.getValue("categoryScrollPosition") || 0);
  const [openedSectionList, setOpenedSectionList] = useState<string[]>([]);
  // const [showCart, setShowCart] = useState<boolean>(false);
  const [showCart] = useState<boolean>(true);
  // Animations
  const [isLogoShow, setLogoShow] = useState<boolean>(false);
  const [isHeaderShow, setHeaderShow] = useState<boolean>(false);
  const [isContentShow, setContentShow] = useState<boolean>(false);
  const [isSliderShow, setSliderShow] = useState<boolean>(false);
  const [isFadeIn, setFadeIn] = useState<boolean>(false);

  // const [displayItems, setDisplayItems] = useState(selectedCategory?.id);
  // const [transitionStage, setTransistionStage] = useState<string>("fadeInHorizontally");
  const previousSelectedCategory = usePreviousState(selectedCategory);

  const isRTL = currentLanguage?.type === "RTL";

  // Carts
  const { carts } = useAppSelector(({ cart }) => cart);

  const handleOpenCart = useCallback(() => {
    dispatch(setCartVisibility(true));
  }, []);

  // const handleArrowSlider = async (type: "prev" | "next") => {
  //   const id = selectedCategory?.id;
  //   setSliderShow(false);
  //   setContentShow(false);

  //   if (id && type === "next") {
  //     const index = categories.findIndex((cat) => cat.id === id);
  //     const nextCategory = categories[index + 1];

  //     if (nextCategory) {
  //       setSelectedCategory(nextCategory);
  //       setCurrentSliderIndex(index + 2);
  //       setTimeout(() => {
  //         sliderRef.current?.scrollTo({
  //           left: (index + 1) * (isRTL ? -140 : 140),
  //           behavior: "smooth"
  //         });
  //       }, 1);
  //       setTimeout(() => {
  //         setSliderShow(true);
  //         setContentShow(true);
  //       }, 250);
  //     }

  //     return;
  //   }

  //   if (id && type === "prev") {
  //     const index = categories.findIndex((cat) => cat.id === id);
  //     const prevCategory = categories[index - 1];

  //     if (prevCategory) {
  //       setSelectedCategory(prevCategory);
  //       setCurrentSliderIndex(index - 1);
  //       setTimeout(() => {
  //         sliderRef.current?.scrollTo({
  //           left: (index - 1) * (isRTL ? -140 : 140),
  //           behavior: "smooth"
  //         });
  //       }, 1);
  //       setTimeout(() => {
  //         setSliderShow(true);
  //         setContentShow(true);
  //       }, 250);
  //     }
  //     return;
  //   }
  // };

  function goToDetail(item_id: string, section_id: string | null = null) {
    if (!selectedCategory) return;

    if (section_id) {
      push(`/menu/${token}/detail?category_id=${selectedCategory.id}&id=${item_id}&section_id=${section_id}&currency=${homeData?.currency ?? ""}`);
      return;
    }

    push(`/menu/${token}/detail?category_id=${selectedCategory.id}&id=${item_id}&currency=${homeData?.currency ?? ""}`);
  }

  /**
   * handle back to front page
   */
  function handleBackToFrontPage() {
    setSliderShow(false);
    setContentShow(false);

    if (venueIsActive) {
      push(`/menu/${token}`, {
        state: {
          from: "homepage",
        },
      });
    } else {
      setCurrentSliderIndex(1);
      setTimeout(() => {
        categorySliderRef.current?.scrollTo({
          left: 0 * (isRTL ? -130 : 130),
        });
        setContentShow(true);
        setSliderShow(true);
        setSelectedCategory(categories[0]);
      }, 100);
    }
  }

  /**
   * Handle select category
   * @param category<HomeCategoryResponses>
   */
  async function handleSelectCategory(category: HomeCategoryResponses) {
    setContentShow(false);
    setSliderShow(false);
    setOpenedSectionList([]);
    SessionStorage.setValue("openedSectionList", []);
    dispatch(setNoReload(false));

    const id = selectedCategory?.id;
    const categoryIndex = categories.findIndex((cat) => cat.id === category.id);
    const selectedCategoryIndex = categories.findIndex((cat) => cat.id === id);

    // if the selected category is smaller than the clicked category
    if (selectedCategoryIndex < categoryIndex) {
      setCurrentSliderIndex(categoryIndex + 1);
      setCategoryScrollPosition(categoryIndex * (isRTL ? -120 : 120));
    }

    // if the selected category is bigger than the clicked category
    if (selectedCategoryIndex > categoryIndex) {
      setCurrentSliderIndex(categoryIndex === 0 ? 0 : (categoryIndex - 1));
      setCategoryScrollPosition((categoryIndex === 0 ? 0 : (categoryIndex - 1)) * (isRTL ? -120 : 120));
    }

    setTimeout(() => {
      setSelectedCategory(category);
      setContentShow(true);
      setSliderShow(true);
    }, 100);
  }

  /**
   * Function to check if the section is opened or not
   * @param {string} id Section ID
   * @returns `boolean`
   */
  function isSectionOpen(id: string) {
    return !!openedSectionList.find((section: string) => section === id);
  }

  /**
   * Function to handle click section
   * @param {string} id Section ID
   */
  function handleClickSection(id: string) {
    dispatch(setNoReload(false));

    if (isSectionOpen(id)) {
      setOpenedSectionList(
        (prevState) => prevState.filter((sectionId) => sectionId !== id),
      );
    } else {
      setOpenedSectionList((prevState) => [
        ...prevState,
        id,
      ]);
    }
  }

  // useEffect(() => {
  //   const progress = ref.current as any;

  //   if (!data) {
  //     progress?.continuousStart();
  //   } else {
  //     progress?.complete();
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (mainData?.cart_is_show) setShowCart(true);
  // }, [mainData?.cart_is_show]);

  // useEffect(() => {
  //   // avoid first selection to fadeout
  //   if (previousSelectedCategory === undefined) return;
  //   if (selectedCategory?.id !== displayItems) setTransistionStage("fadeOutHorizontally");
  // }, [selectedCategory?.id]);

  /**
   * Handle save opened section list
   */
  useEffect(() => {
    if (openedSectionList.length > 0) {
      SessionStorage.setValue("openedSectionList", openedSectionList);
    }
  }, [openedSectionList]);

  /**
   * Handle save selected category
   */
  useEffect(() => {
    if (selectedCategory) {
      dispatch(saveCategory(selectedCategory));
    }
  }, [selectedCategory]);

  /**
   * Handle current slider index
   */
  useEffect(() => {
    if (isRTL) {
      if (categories.findIndex((cat) => cat.id === previousSelectedCategory?.id) > currentSliderIndex) {
        setFadeIn(false);
      } else {
        setFadeIn(true);
      }

      return;
    }

    if (categories.findIndex((cat) => cat.id === previousSelectedCategory?.id) > currentSliderIndex) {
      setFadeIn(true);
      return;
    }

    setFadeIn(false);
  }, [currentSliderIndex]);

  /**
   * Handle category scroll position
   */
  useEffect(() => {
    SessionStorage.setValue("categoryScrollPosition", categoryScrollPosition);

    // const handler = setTimeout(() => {
    //   categorySliderRef.current?.scrollTo({
    //     left: categoryScrollPosition,
    //   });
    // }, 100);
    const handler = setTimeout(() => {
      categorySliderRef.current?.scrollTo({
        left: categoryScrollPosition,
      });
    }, 100);
    // categorySliderRef.current?.scrollTo({
    //   left: categoryScrollPosition,
    // });

    return () => {
      clearTimeout(handler);
      // categorySliderRef.current?.scrollTo({
      //   left: categoryScrollPosition,
      // });
    };
  }, [categoryScrollPosition]);

  /**
   * Handle home animation, etc.
   */
  useEffect(() => {
    if (homeData) {
      const { categories: homeDataCategories } = homeData;
      const selectedCatIndex = homeDataCategories.findIndex((cat) => cat.id === savedCategory?.id);

      setCategories(homeDataCategories);
      setSelectedCategory(homeDataCategories[selectedCatIndex] || homeDataCategories[0]);

      dispatch(saveBannerUrl(homeData?.banner_image));
      dispatch(saveLogoUrl(homeData?.logo));

      setTimeout(() => {
        categorySliderRef.current?.scrollTo({
          left: categoryScrollPosition || 0,
        });
      }, 100);

      setTimeout(() => setLogoShow(true), 250);
      setTimeout(() => {
        setHeaderShow(true);
        setSliderShow(true);
      }, 500);
      setTimeout(() => setContentShow(true), 750);
    }
  }, [homeData]);

  /**
   * Handle no-reload after returning from details page
   */
  useEffect(() => {
    const getOpenedSectionList = SessionStorage.getValue<string[]>("openedSectionList");

    if (noReload && homeData) {
      const selectedCat = savedCategory || homeData?.categories[0] || null;

      setCategories(homeData?.categories || []);
      setSelectedCategory(selectedCat);
      setOpenedSectionList(getOpenedSectionList || []);

      dispatch(saveLogoUrl(homeData?.logo));
      dispatch(saveBannerUrl(homeData?.banner_image));

      setLogoShow(true);
      setContentShow(true);
      setHeaderShow(true);
      setSliderShow(true);

      setTimeout(() => {
        categorySliderRef.current?.scrollTo({
          left: categoryScrollPosition || 0,
        });
      }, 100);
    }
  }, [noReload]);

  return (
    <div
      className="flex flex-col w-full min-h-screen overflow-y-hidden"
      style={{ direction: isRTL ? "rtl" : "ltr" }}
    >
      <section id="wrapper" className="flex-1 overflow-y-hidden">
        <section
          id="top-bar"
          className="flex items-start p-4 pb-0"
          style={{ direction: "ltr" }}
        >
          <div className="flex-1">
            <button
              type="button"
              title={venueIsActive ? "Back to Welcome Page" : "Back to first cateogry"}
              className="p-3 shadow-button rounded"
              onClick={handleBackToFrontPage}
            >
              <BackIcon className="h-4 w-4" />
            </button>
          </div>

          {/* Restaurant Logo */}
          {(savedLogoUrl || (homeData && homeData.logo)) && (
            <section
              className={`h-22 w-22 -top-2 relative transition-all duration-500 opacity-1 ${
                noReload ? "opacity-1" : isLogoShow ? "opacity-1 translate-y-0" : "opacity-0 translate-y-10"
              }`}
            >
              <img
                src={IMAGES_URL + (savedLogoUrl || (homeData && homeData.logo))}
                className="h-20 object-contain"
                alt="logo"
              />
            </section>
          )}

          <div className="flex-1" />
        </section>

        {/* Loading indicator */}
        {!homeData && (
          <div
            className={`flex justify-center items-center h-full w-full max-w-3xl transition-opacity duration-500 delay-200 ${
              !homeData ? "opacity-1" : "opacity-0"
            }`}
          >
            <SpinnerIcon className="animate-spin-fast h-8 w-8 mx-auto" />
          </div>
        )}

        {/* Header */}
        <header
          className={
            `text-center w-full mt-[1pt] transition-all duration-500 ${
              noReload ? "opacity-1" : isHeaderShow ? "opacity-1 translate-y-0" : "opacity-0 translate-y-10"}`
          }
        >
          <p className="text-[22px] text-black">
            {homeData?.menu_name ?? "Menu"}
          </p>
          <p className="text-base text-black ">
            {homeData?.menu_desc ?? ""}
          </p>
        </header>

        {/* Categories slider */}
        <section
          id="category-slider"
          className={
            `slider-categories transition-all duration-500 w-full ${
              noReload ? "opacity-1" : isHeaderShow ? "opacity-1 translate-y-0" : "opacity-0 translate-y-10"}`
          }
        >
          {/* <button
              className="rounded-full bg-white h-6 w-6 flex items-center justify-center opacity-1 z-10 transform -translate-y-full absolute top-1/2 left-2"
              onClick={() => handleArrowSlider(isRTL ? "next" : "prev")}
              disabled={!isRTL ? currentSliderIndex === 0 : currentSliderIndex === categories.length - 1}
            >
              <ChevronIcon className="h-4 w-4 transform rotate-90" color="black" />
            </button> */}
          <div
            className="overflow-x-auto whitespace-nowrap w-full pt-4 pb-2 px-4 hide-scrollbar scroll-smooth"
            ref={categorySliderRef}
          >
            {categories.map((category) => {
              const isSelected = category.id === selectedCategory?.id;

              return (
                <CategoryCard
                  key={category.id}
                  onClickCategory={() => handleSelectCategory(category)}
                  isSelected={isSelected}
                  isFadeIn={isFadeIn}
                  isSliderShow={isSliderShow}
                  noReload={noReload}
                  {...category}
                />
              );
            })}
          </div>
          {/* <button
              className="rounded-full bg-white h-6 w-6 flex items-center justify-center opacity-1 z-10 transform -translate-y-full absolute top-1/2 right-2"
              onClick={() => handleArrowSlider(isRTL ? "prev" : "next")}
              disabled={!isRTL ? currentSliderIndex === categories.length - 1 : currentSliderIndex === 0}
            >
              <ChevronIcon className="h-4 w-4 transform -rotate-90" color="black" />
            </button> */}
        </section>

        {/* Banner */}
        {savedBannerUrl && (
          <div
            className={
              `p-4 transition-all duration-500 delay-100 ${
                noReload ? "opacity-1" : isHeaderShow ? "opacity-1 translate-y-0" : "opacity-0 translate-y-10"}`
            }
          >
            <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
              <img
                src={IMAGES_URL + savedBannerUrl}
                alt="banner"
                className="object-cover"
              />
            </div>
          </div>
        )}

        {/* Menu items */}
        <div
          className={` ${
            noReload ? "opacity-1" : isContentShow ? "opacity-1 fadeInHorizontally" : "opacity-0 translate-y-10"
          }`}
        >
          {isContentShow && selectedCategory && (
            <>
              <div className="pt-1 pb-2 mx-4 text-center">
                <p className="text-xl text-black">{selectedCategory?.name}</p>
                <p className="text-base text-black">{selectedCategory?.description}</p>
              </div>

              <ul className="list-none p-0 m-4 mt-1 5">
                {selectedCategory?.items.length === 0 ? (
                  <p className="pt-6 text-center">No items found</p>
                ) : (
                  selectedCategory?.items.map((item) => {
                    // Object type = section
                    if (item.object === "section") {
                      const isOpen = isSectionOpen(item.id);

                      return (
                        <li key={item.id} className="mb-4 relative">
                          <button
                            type="button"
                            className={`w-full rounded shadow-card-item bg-white flex items-center justify-between p-4 mb-1 border ${
                              isOpen ? "" : "border-transparent"
                            }`}
                            onClick={() => handleClickSection(item.id)}
                          >
                            <p className=" text-base">{item.name}</p>
                            <ChevronIcon
                              className="ml-2 h-4 w-4"
                              color={isOpen ? "#DC5C4B" : "currentColor"}
                            />
                          </button>

                          {isOpen && (
                            <ul
                              className={
                                `list-none p-0 my-4 transition-all duration-500 delay-150 ${
                                  noReload
                                    ? "opacity-1"
                                    : isOpen
                                      ? "opacity-1 fadeInHorizontally"
                                      : "opacity-0 translate-y-10"}`
                              }
                            >
                              <p className={` text-center text-xl ${item.description === "" && "mb-3"}`}>
                                {item.name}
                              </p>
                              {item.description && (
                                <p className="text-base  text-black text-center mb-3">{item.description}</p>
                              )}

                              {item.items
                                && item.items.length > 0
                                && item.items.map((subItem) => (
                                  <ListItemCard
                                    key={subItem.id}
                                    goToDetail={() => goToDetail(subItem.id, item.id)}
                                    {...subItem}
                                  />
                                ))}
                            </ul>
                          )}
                        </li>
                      );
                    }

                    return (
                      <ListItemCard
                        key={item.id}
                        goToDetail={() => goToDetail(item.id)}
                        {...item}
                      />
                    );
                  })
                )}
              </ul>
            </>
          )}
        </div>
      </section>

      {/* {mainData?.cart_is_show && ( */}
      <Portal>
        <button
          type="button"
          className={`fixed z-10 -bottom-4 right-4 lg:right-2/3 lg:mr-8 h-[68px] w-[68px] flex justify-center items-center shadow-button rounded-full bg-white transition-all duration-500 ${showCart ? "-translate-y-8 opacity-1 delay-500" : "opacity-0"}`}
          onClick={handleOpenCart}
        >
          {/* <img src={cartImage} className="h-8 w-8" alt="cart" /> */}
          <CartIcon className="h-6 w-6" />

          {carts.length > 0 && (
            <span className="absolute -top-[1px] -right-[1px] bg-primary rounded-full h-6 w-6 text-xs text-white flex items-center justify-center">
              {carts.reduce((count, current) => count + current.count, 0)}
            </span>
          )}
        </button>

        {/* Shopping Cart */}
        {/* <ShoppingCart token={token} currency={homeData?.currency} /> */}
        <ShoppingCart currency={homeData?.currency} />
      </Portal>
      {/* )} */}

      {/* Footer */}
      <footer className="text-active text-center pt-8 pb-10">
        <p>
          Powered by
          {" "}
          <LogoIcon className="inline-block h-4 w-4" />
          {" "}
          CloudMenu
        </p>
      </footer>
    </div>
  );
}

const MemoizeHome = memo(Home);

function HomeContainer() {
  return (
    <Layout>
      <MemoizeHome />
    </Layout>
  );
}

export default withData(HomeContainer);
