import Lottie from "lottie-react";
import type { LanguageModel } from "../../app/types";

import EmailSuccessfullySentIcon from "../../assets/animation/email_successfully_sent.json";
import { ReactComponent as CloseIcon } from "../../assets/icons/ic_close.svg";
import successImage from "../../assets/success.png";

type ModalType = "feedback" | "cart" | "clear";

interface PropTypes {
  type: ModalType;
  language?: LanguageModel | null;
  close?: () => void;
}

// const ModalStatus = ({type, languages}: PropTypes) => (
//   <div
//     className="fixed inset-0 lg:right-auto w-full lg:w-1/3 flex items-center justify-center bg-black bg-opacity-70 z-30"
//     style={{minWidth: 320}}
//   >
//     <div className="flex flex-col items-center justify-center gap-2 w-72 h-72 text-lg bg-white rounded-lg">
//       {type === 'cart' && (
//         <>
//           <img src={successImage} alt="success" className="w-24 h-24" />
//           <p className="font-bold">Success</p>
//           <p>successfully added to cart</p>
//         </>
//       )}
//       {type === 'clear' && (
//         <>
//           <img src={successImage} alt="success" className="w-24 h-24" />
//           <p className="font-bold">Thank you</p>
//         </>
//       )}
//       {type === 'feedback' && (
//         <>
//           <Lottie animationData={EmailSuccessfullySentIcon} />
//           <p className="font-bold">Feedback Sent</p>
//           <p>Thank you very much!</p>
//         </>
//       )}
//     </div>
//   </div>
// )

function ModalStatus({ type, language, close }: PropTypes) {
  if (type === "feedback") {
    return (
      <div
        className="fixed inset-0 lg:right-auto w-full lg:w-1/3 z-30 bg-white flex flex-col justify-center items-center p-4 gap-4 transition-all duration-200 ease-in-out fadeInVertically"
        style={{ minWidth: 320 }}
      >
        <button
          className="absolute p-3 top-4 right-4 shadow-button rounded z-20"
          onClick={close}
          type="button"
        >
          <CloseIcon className="h-4 w-4" />
        </button>

        <div className="w-full max-w-[207px] flex items-center justify-center">
          <Lottie animationData={EmailSuccessfullySentIcon} />
        </div>

        {language?.code === "en" && <p className="font-semibold text-black">Feedback was sent successfully</p>}
        {language?.code === "ru" && <p className="font-semibold text-black">Отзыв был успешно отправлен</p>}
        {language?.code === "ar" && <p className="font-semibold text-black">تم إرسال رآيك بنجاح</p>}
        {(language?.code === "ua" || language?.code === "uk") && (
          <p className="font-semibold text-black">Відгук успішно надіслано</p>
        )}
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 lg:right-auto w-full lg:w-1/3 flex items-center justify-center bg-black bg-opacity-70 z-30"
      style={{ minWidth: 320 }}
    >
      <div className="flex flex-col items-center justify-center gap-2 w-72 h-72 text-lg bg-white rounded-lg">
        {type === "cart" && (
          <>
            <img
              src={successImage}
              alt="success"
              className="w-24 h-24"
            />
            <p className="font-bold">Success</p>
            <p>successfully added to cart</p>
          </>
        )}

        {type === "clear" && (
          <>
            <img
              src={successImage}
              alt="success"
              className="w-24 h-24"
            />
            <p className="font-bold">Thank you</p>
          </>
        )}
      </div>
    </div>
  );
}

export default ModalStatus;
