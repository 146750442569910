/* eslint-disable func-names */
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { saveCategory, saveLanguage } from "../features/cache";
import {
  setCurrency,
  setCurrentLanguage,
  setHomeData, setIngredientsWarningData, setLanguageData, setMainData, setVenueIsActive,
} from "../features/globalState";
import {
  useGetHomeDataQuery, useGetIngredientListQuery, useGetLanguageListQuery, useGetMainDataQuery,
} from "../services";

import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";

export const withData = (Component: () => JSX.Element) => function () {
  const {
    mainData, homeData, languageData, token, currentLanguage, ingredientsWarningData,
  } = useAppSelector(({ globalState }) => globalState);
  const { savedLanguage, savedCategory } = useAppSelector(({ cache }) => cache);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    data: mainDataResponse, isLoading: loadingMainData,
    isError: isErrorMainData,
  } = useGetMainDataQuery(undefined, {
    skip: !!mainData || !token,
  });
  const {
    data: homeDataResponse, isLoading: loadingHomeData, refetch: refetchHomeData,
    isError: isErrorHomeData,
  } = useGetHomeDataQuery({
    lang: currentLanguage?.code || savedLanguage?.code || "en",
  }, {
    skip: !token || !mainData,
  });
  const {
    data: languageDataResponse, isLoading: loadingLanguageData,
    isError: isErrorLanguageData,
  } = useGetLanguageListQuery(undefined, {
    skip: !!languageData || !token,
  });
  const { data: ingredientsWarningDataResponse } = useGetIngredientListQuery(undefined, {
    skip: !!ingredientsWarningData,
  });

  const loading = loadingMainData || loadingHomeData || loadingLanguageData;
  const isError = isErrorMainData || isErrorHomeData || isErrorLanguageData;
  // const isError = mainDataError || homeDataError || languageDataError;

  useEffect(() => {
    if (isError) {
      history.push(`/menu/${token}/unauth`);
    }
  }, [isError]);

  useEffect(() => {
    if (mainDataResponse) {
      dispatch(setVenueIsActive(mainDataResponse?.data?.venue_is_active ?? false));
      dispatch(setMainData(mainDataResponse?.data ?? null));
    }
  }, [mainDataResponse]);

  useEffect(() => {
    if (homeDataResponse && homeDataResponse.data && homeDataResponse.data.menu_name !== homeData?.menu_name) {
      dispatch(setHomeData(homeDataResponse.data || null));

      const selectedCategoryIndex = homeDataResponse.data.categories
        .findIndex((item) => item.id === savedCategory?.id) || 0;

      dispatch(saveCategory(homeDataResponse.data.categories[selectedCategoryIndex]));
      dispatch(setCurrency(homeDataResponse.data.currency || "USD"));
    }
  }, [homeDataResponse]);

  useEffect(() => {
    if (languageDataResponse?.data) {
      dispatch(setCurrentLanguage(savedLanguage || languageDataResponse?.data.lang_list[0]));
      dispatch(setLanguageData(languageDataResponse?.data ?? null));
    }
  }, [languageDataResponse]);

  useEffect(() => {
    if (ingredientsWarningDataResponse) {
      dispatch(setIngredientsWarningData(ingredientsWarningDataResponse?.data ?? null));
    }
  }, [ingredientsWarningDataResponse]);

  useEffect(() => {
    if (currentLanguage) {
      dispatch(saveLanguage(currentLanguage));
      refetchHomeData();
    }
  }, [currentLanguage]);

  if ((!location.pathname.split("/").find((path) => path === "home" || path === "detail") && loading)) {
    return (
      <div className="flex justify-center items-center h-full w-full max-w-3xl">
        <SpinnerIcon className="animate-spin-fast h-8 w-8 mx-auto" />
      </div>
    );
  }

  return (
    <Component />
  );
};
