import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { HomeCategoryResponses, LanguageModel } from "../../app/types";
import { SessionStorage } from "../../utils";
import type { InitialState } from "./types";

const initialState: InitialState = {
  // token: SessionStorage.getValue<string>("token") || null,
  savedLanguage: SessionStorage.getValue<LanguageModel>("lang") || null,
  savedLogoUrl: SessionStorage.getValue<string>("logoUrl") || null,
  savedBannerUrl: SessionStorage.getValue<string>("bannerUrl") || null,
  savedCategory: SessionStorage.getValue<HomeCategoryResponses>("category") || null,
};

const slice = createSlice({
  name: "base",
  initialState,
  reducers: {
    // saveToken: (state, { payload }: PayloadAction<string | null>) => ({
    //   ...state,
    //   token: payload,
    // }),
    saveLanguage: (state, { payload }: PayloadAction<LanguageModel | null>) => ({
      ...state,
      savedLanguage: payload,
    }),
    saveLogoUrl: (state, { payload }: PayloadAction<string | null>) => ({
      ...state,
      savedLogoUrl: payload,
    }),
    saveBannerUrl: (state, { payload }: PayloadAction<string | null>) => ({
      ...state,
      savedBannerUrl: payload,
    }),
    saveCategory: (state, { payload }: PayloadAction<HomeCategoryResponses | null>) => ({
      ...state,
      savedCategory: payload,
    }),
    resetAll: (state) => ({
      ...state,
      token: null,
      savedLanguage: null,
      savedLogoUrl: null,
      savedBannerUrl: null,
      savedCategory: null,
    }),
  },
});

export const {
  // saveToken,
  saveLanguage,
  saveLogoUrl,
  saveBannerUrl,
  saveCategory,
} = slice.actions;

export default slice;
