/* eslint-disable no-console */
import { memo } from "react";

import { ReactComponent as EditIcon } from "../../assets/icons/ic_edit_new.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/ic_trash_new.svg";
import imagePlaceholder from "../../assets/image_placeholder.png";

import { IMAGES_URL } from "../../app/constants";

interface ShoppingItemCardProps {
  name: string;
  note: string;
  image?: string;
  price: number;
  currency: string;
  modifiers: {
    name: string;
    price_unit: number;
  }[];
  quantity: number;
  isRTL: boolean;
  className?: string;
  options?: {
    showImage?: boolean;
    showModifier?: boolean;
    showCounterButtons?: boolean;
    showOptionButtons?: boolean;
    showNotes?: boolean;
  }
  counterButtonEvents?: {
    onClickPlus: () => void;
    onClickMinus: () => void;
  }
  optionButtonEvents?: {
    onClickEdit: () => void;
    onClickDelete: () => void;
  }
}

const ShoppingItemCard = memo(({
  name, note, image, price, modifiers, currency, quantity,
  isRTL, options, counterButtonEvents, optionButtonEvents,
  className,
}: ShoppingItemCardProps) => {
  if ((counterButtonEvents?.onClickMinus || counterButtonEvents?.onClickPlus) && !options?.showCounterButtons) {
    throw new Error("`options.showCounterButtons` must be true");
  }

  return (
    <li
      className={`rounded-lg bg-white p-4 relative flex flex-col gap-2 shadow-card-item ${className || "mb-4"}`}
    >
      <div className="flex justify-between gap-5">
        <div className="flex flex-col gap-2 w-[calc(100%-120px)]">
          <p className={`overflow-hidden overflow-ellipsis whitespace-nowrap ${isRTL ? "" : "font-semibold"}`}>
            {name}
          </p>

          <p className="text-sm font-semibold text-black">
            {currency}
            {" "}
            {price}
          </p>

          {options?.showModifier && (
            <div className="text-xs text-secondary">
              <ul className="my-0 w-full">
                {modifiers.map((modifier, modIndex) => {
                  const modKey = modIndex.toString();

                  return (
                    <li className="overflow-hidden overflow-ellipsis whitespace-nowrap flex items-center justify-between" key={modKey}>
                      <span className="italic">{modifier.name}</span>

                      <span className="font-semibold text-black text-[9.4px]">
                        +
                        {" "}
                        {currency}
                        {" "}
                        {modifier.price_unit}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {options?.showNotes && (
            <p className="text-xs text-secondary truncate italic">
              {note}
            </p>
          )}
        </div>

        {options?.showImage && (
          <div className="min-w-[96px] overflow-hidden">
            <img
              src={image ? (`${IMAGES_URL + image}`) : imagePlaceholder}
              alt={name}
              className="object-cover w-24 h-24 rounded"
            />
          </div>
        )}
      </div>

      {(options?.showCounterButtons || options?.showOptionButtons) && (
        <div className="flex justify-between items-center mt-4">
          {options?.showCounterButtons && (
            <>
              {/* Increment / Decrement */}
              <div className="flex gap-2 justify-center items-center">
                <button
                  type="button"
                  className="rounded-full bg-transparent text-active ring-1 ring-primary w-6 h-6 flex justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed"
                  title="-1"
                  onClick={counterButtonEvents?.onClickMinus ?? (() => console.log("`minus` button clicked!"))}
                  disabled={quantity <= 1}
                >
                  <p className="text-sm font-bold">-</p>
                </button>

                <p className="font-bold text-center text-lg w-5">
                  {quantity}
                </p>

                <button
                  type="button"
                  className="rounded-full bg-primary text-white w-6 h-6 flex justify-center items-center"
                  title="+1"
                  onClick={counterButtonEvents?.onClickPlus ?? (() => console.log("`plus` button clicked"))}
                >
                  <p className="text-sm">+</p>
                </button>
              </div>
            </>
          )}

          {options?.showOptionButtons && (
            <>
              {/* Item Options */}
              <div className={`flex ${isRTL ? "flex-row-reverse" : "flex-row"} items-center justify-end gap-2`}>
                <button
                  type="button"
                  className="p-1 transform scale-125"
                  title="Edit"
                  onClick={optionButtonEvents?.onClickEdit ?? (() => console.log("`edit` button clicked"))}
                >
                  <EditIcon className="h-5 w-5" color="#000000" />
                </button>

                <button
                  type="button"
                  className="p-1 transform scale-125"
                  title="Delete"
                  onClick={optionButtonEvents?.onClickDelete ?? (() => console.log("`delete` button clicked"))}
                >
                  <DeleteIcon className="h-5 w-5" color="#000000" />
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </li>
  );
});

export default ShoppingItemCard;
