import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as BackIcon } from "../../assets/icons/ic_back.svg";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import type { IsDineIn } from "../../app/types";
import { PaymentTypeForm } from "../../components/forms/checkouts";
import { setData, submitOrder } from "../../features/orders";
import { useURL } from "../../hooks";
import { SessionStorage } from "../../utils";

function FinalCheckoutPage() {
  const { currentLanguage } = useAppSelector(({ globalState }) => globalState);
  const { discount } = useAppSelector(({ orders }) => orders);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { getURL } = useURL();

  const [payment, setPayment] = useState<string>("");

  const isRTL = currentLanguage?.code === "ar";
  const dineIn = SessionStorage.getValue<IsDineIn>("dineIn");

  const handleClickCheckout = () => {
    // if (!payment) return;
    if (!payment) {
      toast.error("Please choose a payment method");
      return;
    }

    dispatch(setData({
      table_id: dineIn?.table_id || "",
      payment_type_id: payment,
      promo_code: discount?.discount_code || "",
    }));
    dispatch(submitOrder(true));

    history.push(getURL("order-summary"));
  };

  useEffect(() => {
    if (dineIn && "isDineIn" in dineIn && !dineIn.isDineIn) {
      history.push(getURL());
    }
  }, [dineIn?.isDineIn]);

  return (
    <div className="w-full py-4 relative">
      <div className="relative flex flex-row justify-center items-center w-full h-11">
        <button
          className="absolute p-3 top-0 left-4 shadow-button rounded z-20"
          onClick={() => history.goBack()}
          type="button"
          title="Back to menu"
        >
          <BackIcon className="h-4 w-4" />
        </button>

        <h2 className="text-[22px]">
          {isRTL ? "اختر نوع الدفع" : "Payment Type"}
        </h2>
      </div>

      <div className="mt-10 p-4">
        <PaymentTypeForm
          value={payment}
          onChange={(event) => setPayment(event.target.value)}
        />
      </div>

      <button
        type="button"
        className={clsx(
          "fixed bottom-0",
          "w-full button-primary rounded-none disabled:opacity-50 disabled:cursor-not-allowed",
          isRTL && "font-normal",
        )}
        onClick={handleClickCheckout}
      >
        {isRTL ? "طلب الحساب" : "CHECKOUT"}
      </button>
    </div>
  );
}

export default FinalCheckoutPage;
