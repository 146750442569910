import {
  memo, useCallback, useEffect, useState,
} from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as BackIcon } from "../assets/icons/ic_back.svg";
import { ReactComponent as OrderNotFoundIcon } from "../assets/icons/ic_order_status_empty.svg";
// import { ReactComponent as StarIcon } from "../assets/icons/ic_star.svg";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import type {
  CartItem,
  DiscountTypes,
  IsDineIn,
  ModifierSelectionType,
} from "../app/types";
import {
  decrementItemCount, deleteOneItem, incrementItemCount, setCartVisibility,
} from "../features/cart";
import { setData, setOrderDiscount, submitOrder } from "../features/orders";
import { SessionStorage } from "../utils";
import { ShoppingItemCard } from "./cards";
import { PromoCodeForm } from "./forms";
import { useURL } from "../hooks";
import PriceSummariesWithCheckoutButton from "./price-summaries-with-checkout-button";

interface ShoppingCartProps {
  // token: string | null;
  currency?: string;
}

const ShoppingCart = memo(({
  // token,
  currency,
}: ShoppingCartProps) => {
  const { carts, isCartOpened: isOpen } = useAppSelector(({ cart }) => cart);
  const { currentLanguage, orderType } = useAppSelector(({ globalState }) => globalState);
  const { data: orderData, discount: orderDiscount } = useAppSelector(({ orders }) => orders);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { getURL } = useURL();

  const [notes, setNotes] = useState<string>("");
  const [discount, setDiscount] = useState<DiscountTypes | null>(null);
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const dineIn = SessionStorage.getValue<IsDineIn>("dineIn") || null;
  const isRTL = currentLanguage?.code === "ar";

  const closeCart = useCallback(() => {
    dispatch(setCartVisibility(false));
  }, []);

  const prices = {
    /**
     * Count Prices
     * @param {CartItem} item Cart Item
     * @returns {number} total prices
     */
    countPrices: (item: CartItem): number => {
      let price = item.price.price * item.count;

      for (let i = 0; i < item.modifiers.length; i += 1) {
        for (let j = 0; j < item.modifiers[i].items.length; j += 1) {
          if (item.modifiers[i].items[j].is_selected) {
            const modifier = item.modifiers[i].items[j];

            price += (modifier.price * item.count);
          }
        }
      }

      return price;
    },

    /**
     * Get total prices
     * @returns total prices
     */
    getTotalPrices: () => {
      const arr: number[] = [];

      if (carts.length > 0) {
        carts.forEach((item) => arr.push(prices.countPrices(item)));
      }

      const price = arr.length > 0 ? arr.reduce((a, b) => a + b) : 0.00;

      return price <= 0 ? 0 : Number(price.toFixed(2));
    },

    /**
     * Get total discount
     * @param {"AMOUNT" | "PERCENT"} type discount type
     * @return {number | string} discount
     */
    getTotalDiscount: (type?: "AMOUNT" | "PERCENT"): string | number => {
      const totalPrice = prices.getTotalPrices();
      const totalDiscount = discount ? (
        discount.type === "AMOUNT" ? discount.promo_amount : ((totalPrice / 100) * discount.promo_amount)
      ) : 0;

      return ((discount && discount.type === "PERCENT") && (type && type === "PERCENT")) ? `${discount.promo_amount}%` : Number(totalDiscount.toFixed(2));
    },

    /**
     * Get total price with discount
     * @returns {number} total prices
     */
    getTotalPriceWithDiscount: (): number => {
      const totalPrices = prices.getTotalPrices();
      const totalDiscount = prices.getTotalDiscount("AMOUNT") as number;

      return Number((totalPrices - totalDiscount).toFixed(2));
    },
  };

  /**
   * Increment or decrement item count
   * @param {"minus" | "plus"} type "minus" or "plus"
   * @param {String} id item id
   */
  const handleItemCount = (type: "minus" | "plus", id: string) => {
    if (type === "minus") {
      dispatch(decrementItemCount(id));
    }

    if (type === "plus") {
      dispatch(incrementItemCount(id));
    }
  };

  /**
   * Handle click edit on the item
   * @param {String} id item id
   * @param {String | undefined} category_id category id
   * @param {String | undefined} section_id section id
   */
  const handleClickEdit = (id: string, category_id?: string, section_id?: string) => {
    if (category_id && section_id) {
      history.push(
        getURL(`detail?category_id=${category_id}&section_id=${section_id}&id=${id}&currency=${currency}`),
      );
    } else {
      history.push(
        getURL(`detail?category_id=${category_id}&id=${id}&currency=${currency}`),
      );
    }
  };

  /**
   * Handle click delete on the item
   * @param {String} id item id
   */
  const handleClickDeleteItem = (id: string) => {
    dispatch(deleteOneItem(id));
  };

  /**
   * Get selected modifiers
   * @param {ModifierSelectionType[]} modifiers modifier arrays
   */
  const getSelectedModifier = (modifiers: ModifierSelectionType[]) => {
    const selectedModifiers: { name: string, price_unit: number }[] = [];

    modifiers.forEach((modifier) => {
      for (let i = 0; i < modifier.items.length; i += 1) {
        if (modifier.items[i].is_selected) {
          selectedModifiers.push({
            name: modifier.items[i].name,
            price_unit: modifier.items[i].price,
          });
        }
      }
    });

    return selectedModifiers;
  };

  /**
   * Handle Click "Submit Order"
   */
  const handleClickSubmitOrder = () => {
    const orderItems = [...carts.map(({
      id: item_id, name, note, price, modifiers, count: qty,
    }) => {
      const selectedModifiers = [];

      for (let i = 0; i < modifiers.length; i += 1) {
        for (let j = 0; j < modifiers[i].items.length; j += 1) {
          if (modifiers[i].items[j].is_selected) {
            const modifier = modifiers[i].items[j];

            selectedModifiers.push({
              name: modifier.name,
              price_unit: modifier.price,
            });
          }
        }
      }

      return {
        item_id,
        name,
        qty,
        price_unit: price.price,
        notes: note,
        modifiers: selectedModifiers,
      };
    })];

    dispatch(setData({
      notes: notes.trim(),
      orders: orderItems,
    }));

    if (dineIn?.isDineIn) {
      dispatch(submitOrder(true));

      closeCart();
      // dispatch(setData({
      //   notes: notes.trim(),
      //   orders: orderItems,
      // }));
      // dispatch(submitOrder(true));

      // closeCart();
      history.push(getURL());
    } else {
      // dispatch(setData({
      //   notes: notes.trim(),
      //   orders: orderItems
      // }));
      // dispatch(submitOrder(true));
      // closeCart();

      history.push(getURL("finish-order"));
    }
  };

  useEffect(() => {
    if (discount) {
      dispatch(setOrderDiscount(discount));
    }
  }, [discount]);

  useEffect(() => {
    if (orderDiscount) {
      setDiscount(orderDiscount);
    }

    if (orderData.notes) {
      setNotes(orderData.notes as string);
    }
  }, []);

  return (
    <div
      className={
        `fixed inset-0 lg:right-auto w-full lg:w-1/3 z-30 bg-white
         pb-[200px] px-0 pt-6 gap-4 transition-opacity duration-200 ease-in min-h-screen hide-scrollbar overflow-y-auto overflow-x-hidden ${
    isOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"}`
      }
      style={{
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <div className="relative flex flex-row justify-center items-center w-full h-11">
        <button
          className="absolute p-3 top-0 left-4 shadow-button rounded z-20"
          onClick={closeCart}
          type="button"
          title="Back to menu"
        >
          <BackIcon className="h-4 w-4" />
        </button>

        <h2 className="text-[22px]">
          {dineIn?.isDineIn ? `Table ${dineIn.table}` : (isRTL ? "حالة الطلب" : "Cart")}
        </h2>
      </div>

      {carts.length > 0 ? (
        // <ul className="list-none w-full mt-10 mb-2 p-4 min-h-[calc(60vh-0px)]">
        <ul className="list-none w-full mt-10 mb-2 p-4">
          {carts.map((item) => {
            const price = prices.countPrices(item);

            return (
              <ShoppingItemCard
                key={item.id}
                name={item.name}
                note={item.note}
                image={item.image}
                price={price}
                modifiers={getSelectedModifier(item.modifiers)}
                currency={currency || "USD"}
                isRTL={isRTL}
                quantity={item.count}
                options={{
                  showCounterButtons: true,
                  showImage: true,
                  showModifier: true,
                  showNotes: true,
                  showOptionButtons: true,
                }}
                counterButtonEvents={{
                  onClickMinus: () => handleItemCount("minus", item.id),
                  onClickPlus: () => handleItemCount("plus", item.id),
                }}
                optionButtonEvents={{
                  onClickEdit: () => handleClickEdit(item.id, item.category_id, item.section_id),
                  onClickDelete: () => handleClickDeleteItem(item.id),
                }}
              />
            );
          })}
        </ul>
      ) : (
        <div className="min-h-[calc(100vh-200px)] text-center flex flex-col items-center justify-center">
          <OrderNotFoundIcon className="mb-4" />
          <p className="text-black text-opacity-25">
            {isRTL ? "السلّة خالية!" : "Cart is empty!"}
          </p>
        </div>
      )}

      {carts.length > 0 && (
        <>
          {/* Notes */}
          {(orderType === "dine-in" || orderType === "drive-through") && (
            <div className="text-black text-center w-full px-4 mb-8">
              <h3 className="text-lg">{isRTL ? "إضافة ملاحظة" : "Add a note"}</h3>
              <p className="text-sm">{isRTL ? "أضف ملاحظة حول طلبك" : "Add a note about your order"}</p>

              <textarea
                className="input-base border-black rounded-lg mt-2"
                placeholder={isRTL ? "اختياري" : "Add note"}
                rows={3}
                value={notes}
                onChange={(event) => setNotes(event.target.value)}
              />
            </div>
          )}

          {/* Promo Code */}
          {/* <PromoCodeForm
            defaultValue={discount}
            discount={discount}
            currency={currency}
            setDiscount={setDiscount}
            totalPrice={prices.getTotalPrices()}
          /> */}
        </>
      )}

      {/* <div
        className="fixed bottom-0 left-0 z-30 w-full lg:w-1/3 bg-white transition-all pt-2 mt-2 rounded-t-2xl shadow-card-item"
        style={{ minWidth: 320 }}
      >
        <button
          type="button"
          className="w-full flex items-center justify-center"
          onClick={() => setIsExpand((prevState) => !prevState)}
        >
          <BackIcon className={`h-6 w-6 ${isExpand ? "-rotate-90" : "rotate-90"}`} />
        </button>

        <div className={`w-auto ${isExpand ? "h-full m-4 mb-0 border-b pb-4" : "h-0 overflow-hidden"}`}>
          <div className="flex items-center justify-between text-lg font-semibold mb-1">
            <span className={isRTL ? "font-normal" : ""}>{isRTL ? " المجموع الجزئي" : "Subtotal"}</span>
            <span>
              {currency}
              {" "}
              {prices.getTotalPrices()}
            </span>
          </div>

          {prices.getTotalDiscount() !== 0 && (
            <div className="flex items-center justify-between">
              <span className={isRTL ? "font-normal" : ""}>{isRTL ? "الخصم" : "Discount"}</span>
              <span
                className={`text-sm ${isRTL ? "-translate-x-2" : ""}`}
                style={{
                  direction: isRTL ? "rtl" : "ltr",
                }}
              >
                {isRTL ? (
                  <>
                    {currency}
                    {" "}
                    {prices.getTotalDiscount()}
                    {" "}
                    -
                  </>
                ) : (
                  <>
                    {discount?.type === "AMOUNT" && (
                      <>
                        -
                        {"  "}
                        {currency}
                      </>
                    )}
                    {" "}
                    {prices.getTotalDiscount(discount?.type === "PERCENT" ? "PERCENT" : "AMOUNT")}
                  </>
                )}
              </span>
            </div>
          )}
        </div>

        <div className={`w-full flex items-center justify-between text-xl font-semibold ${isExpand ? "p-4" : "p-4 pt-2"}`}>
          <span className={isRTL ? "font-normal" : ""}>{isRTL ? "إجمالي " : "Total"}</span>
          <span>
            {currency}
            {" "}
            {prices.getTotalPriceWithDiscount()}
          </span>
        </div>

        <button
          type="button"
          className={`w-full button-primary rounded-none disabled:opacity-50 disabled:cursor-not-allowed ${isRTL ? "font-normal" : ""}`}
          disabled={carts.length === 0}
          onClick={handleClickSubmitOrder}
        >
          {isRTL ? "تأكيد الطلب" : "SUBMIT ORDER"}
        </button>
      </div> */}
      <PriceSummariesWithCheckoutButton
        onClickSubmit={handleClickSubmitOrder}
        currency={currency}
        showPromoCodeForm={carts.length > 0}
      />
    </div>
  );
});

export default ShoppingCart;
