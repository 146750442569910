/* eslint-disable consistent-return */
import { useEffect, useRef, useState } from "react";
import {
  // BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import { ReactComponent as CheckRoundedIcon } from "./assets/icons/ic_check_rounded.svg";
import imagePlaceholder from "./assets/image_placeholder.png";

import { IMAGES_URL } from "./app/constants";
import { useAppSelector } from "./app/hooks";
import { Portal } from "./components";
import { SessionStorage } from "./utils";
import type { IsDineIn } from "./app/types";

// pages
import { useURL } from "./hooks";
import CheckoutPage from "./pages/checkout";
import FinalCheckoutPage from "./pages/checkout/final-checkout";
import DetailPage from "./pages/detail";
import FrontPage from "./pages/frontpage";
import HomePage from "./pages/home";
import NotFoundPage from "./pages/not-found";
import OrderSummaryPage from "./pages/summary-page";
import UnAuthPage from "./pages/unauth";
import FinishOrderPage from "./pages/checkout/finish-order";
import OrderStatusPage from "./pages/order-status";

type TransitionStageType = "fadeInVertically" | "fadeOutVertically";
// type RouteType = {
//   path: string;
//   component: () => JSX.Element;
// }

type AnimatedAppProps = {
  isUnAuth: boolean;
  isDineIn: boolean;
};

export default function AnimatedApp({ isUnAuth, isDineIn }: AnimatedAppProps) {
  const location = useLocation();
  const scrollRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { getURL, getCurrentPath } = useURL();

  const { token, currentLanguage, orderType } = useAppSelector(({ globalState }) => globalState);
  const { isNewItemAddedToCart, item } = useAppSelector(({ cart }) => cart);

  const [displayLocation, setDisplayLocation] = useState<typeof location>(location);
  const [transitionStage, setTransistionStage] = useState<TransitionStageType>("fadeInVertically");
  const [homeScrollPosition, setHomeScrollPosition] = useState<number>(SessionStorage.getValue("scrollPosition") || 0);

  const dineIn = SessionStorage.getValue<IsDineIn>("dineIn");

  useEffect(() => {
    scrollRef.current?.addEventListener("scroll", () => {
      setHomeScrollPosition(scrollRef.current?.scrollTop ?? 0);
    });

    return () => {
      scrollRef.current?.removeEventListener("scroll", () => {
        setHomeScrollPosition(scrollRef.current?.scrollTop ?? 0);
      });
    };
  }, []);

  useEffect(() => {
    const getScrollPosition: number = SessionStorage.getValue("scrollPosition") || 0;

    if (!scrollRef.current) {
      return;
    }

    if (getCurrentPath() === "home") {
      // if (location.pathname.split("/").find((path) => path === "home")) {
      setTimeout(() => {
        scrollRef.current?.scrollTo({
          top: getScrollPosition,
        });
      }, 750);
    }
  }, [location.pathname, scrollRef.current]);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    if (getCurrentPath() === "home") {
      // if (location.pathname.split("/").find((path) => path === "home")) {
      const handler = setTimeout(() => {
        SessionStorage.setValue("scrollPosition", homeScrollPosition);
      }, 250);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [homeScrollPosition]);

  useEffect(() => {
    if (location.pathname !== displayLocation.pathname) {
      setTransistionStage("fadeOutVertically");
    }
  }, [location]);

  useEffect(() => {
    if (isUnAuth) {
      history.push(getURL("unauth"));
    }
  }, [isUnAuth]);

  useEffect(() => {
    if (getCurrentPath() === "order-status") return;

    if ((token && !orderType && !isDineIn) && getCurrentPath() !== "order-summary") {
      history.push(getURL());
    }
  }, [orderType, token]);

  return (
    // <Router>
    <div
      className={`${transitionStage} relative min-h-screen w-full lg:w-1/3 overflow-x-hidden bg-white hide-scrollbar`}
      style={{ minWidth: 320 }}
      ref={scrollRef}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOutVertically") {
          setTransistionStage("fadeInVertically");
          setDisplayLocation(location);
        }
      }}
    >
      <Switch location={displayLocation}>
        <Route
          exact
          path="/menu/:token"
          component={FrontPage}
        />
        <Route
          exact
          path="/menu/:token/home"
          component={HomePage}
        />
        <Route
          exact
          path="/menu/:token/detail"
          component={DetailPage}
        />
        <Route
          exact
          path="/menu/:token/unauth"
          component={UnAuthPage}
        />

        <Route
          exact
          path="/menu/:token/checkout"
          component={CheckoutPage}
        />

        <Route
          exact
          path="/menu/:token/final-checkout"
          component={FinalCheckoutPage}
        />

        <Route
          exact
          path="/menu/:token/order-summary"
          component={OrderSummaryPage}
        />

        <Route
          exact
          path="/menu/:token/finish-order"
          component={FinishOrderPage}
        />

        <Route
          exact
          path="/menu/:token/order-status"
          component={OrderStatusPage}
        />

        <Route
          // path="*"
          component={NotFoundPage}
        />
      </Switch>

      {/* Notification when new items are added to cart */}
      <Portal>
        <div
          className={`fixed z-40 bottom-4 w-full lg:w-1/3 flex items-ceter justify-center transition-all duration-500 ${isNewItemAddedToCart ? "bottom-4 opacity-1 visible" : "-bottom-10 opacity-0 invisible"
          }`}
        >
          <div
            className="bg-white rounded flex items-center box-content border border-primary relative min-w-[311px]"
            style={{
              direction: currentLanguage?.code === "ar" ? "rtl" : "ltr",
            }}
          >
            <CheckRoundedIcon className={`h-6 w-6 absolute ${currentLanguage?.code === "ar" ? "left-2 top-2" : "right-2 top-2"}`} />

            <div className={`h-full w-[72px] overflow-hidden aspect-2 ${currentLanguage?.code === "ar" ? "rounded-r-sm" : "rounded-l-sm"}`}>
              <img
                src={item?.image ? `${IMAGES_URL}${item.image}` : imagePlaceholder}
                alt="item"
                className="object-cover h-full w-full"
              />
            </div>
            <div className="whitespace-nowrap p-3 text-base">
              <p className="mb-1 font-body">{item?.name ?? ""}</p>
              <p>{currentLanguage?.code === "ar" ? "تمت الإضافة بنجاح" : "Successfully added to cart"}</p>
            </div>
          </div>
        </div>
      </Portal>
    </div>
    // </Router>
  );
}
