function useURL() {
  /**
   * Get token
   * @returns {string | null} token
   */
  const getToken = (): string | null => {
    if (typeof window !== undefined) {
      const location = window.location.pathname;
      const token = location
        .split("/")
        .filter((path) => path)[1] || null;

      return token;
    }

    return null;
  };

  /**
   * Get URL
   * @param {string | undefined} path url path
   * @example
   * ```ts
   * getUrl('home') // /menu/asdoiasj1jk23h12jk3h/home
   * ```
   * @returns {string} url
   */
  const getURL = (path?: string): string => `/menu/${getToken() || "null"}${path ? `/${path}` : ""}`;

  /**
   * Get current path
   * @returns string
   */
  const getCurrentPath = (): string => {
    const location = window.location.pathname;
    const splitLocation = location.split("/").filter((path) => path);

    // remove "menu"
    splitLocation.shift();
    // remove token
    splitLocation.shift();

    const path = splitLocation.join("/");

    return path;
  };

  return {
    getToken,
    getURL,
    getCurrentPath,
  };
}

export default useURL;
