/* eslint-disable react/destructuring-assignment */
import { IMAGES_URL } from "../../app/constants";
import type { HomeCategoryResponses } from "../../app/types";

import imagePlaceholder from "../../assets/image_placeholder.png";

export type CategoryCardProps = HomeCategoryResponses & {
  isSelected: boolean
  isSliderShow: boolean
  isFadeIn: boolean
  noReload: boolean
  onClickCategory: () => void
}

export function CategoryCard(category: CategoryCardProps) {
  const {
    isSelected, isFadeIn, isSliderShow, noReload, onClickCategory,
  } = category;

  const cardImage = category.image ? `${IMAGES_URL + category.image}` : imagePlaceholder;
  const fade = isFadeIn ? "fadeInRight" : "fadeInLeft";

  return (
    <button
      type="button"
      className="rounded w-[120px] inline-block text-center mx-2 relative disabled:cursor-not-allowed"
      onClick={onClickCategory}
      disabled={isSelected}
    >
      <div className="aspect-w-16 aspect-h-9 rounded overflow-hidden h-[80px]">
        <img
          src={cardImage}
          alt={category.name}
          className="object-cover"
        />
      </div>
      <p
        className={
          ` text-base mt-1 overflow-hidden overflow-ellipsis mb-2 ${
            isSelected ? "text-active" : "text-black"}`
        }
      >
        {category.name}
      </p>

      {isSelected && isSliderShow && (
        <hr className={`w-[120px] h-[1px] absolute border-primary ${!noReload && fade}`} />
      )}
    </button>
  );
}
