import { useState, useEffect } from "react";
import { LocalStorage } from "../utils";

interface UseGeolocationReturnTypes {
  loaded: boolean;
  error: boolean;
  coordinates: {
    lat: number;
    lng: number;
    default: {
      lat: number;
      lng: number;
    }
  };
}

const useGeolocation = (): UseGeolocationReturnTypes => {
  const [location, setLocation] = useState(
    !("navigator" in window && "geolocation" in window.navigator)
      ? {
        loaded: false,
        error: false,
        coordinates: {
          lat: 50.450001,
          lng: 30.523333,
        },
      }
      : {
        loaded: true,
        error: false,
        coordinates: {
          lat: (LocalStorage.getValue("coord_lat") as unknown as number) || 50.450001,
          lng: (LocalStorage.getValue("coord_lng") as unknown as number) || 30.523333,
        },
      },
  );

  const onSuccess = (position: any) => {
    LocalStorage.setValue("coord_lat", position.coords.latitude);
    LocalStorage.setValue("coord_lng", position.coords.longitude);

    setLocation((prevState) => ({
      ...prevState,
      coordinates: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
    }));
  };

  const onError = () => {
    setLocation((prevState) => ({
      ...prevState,
      error: true,
    }));
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    if ("geolocation" in window.navigator) {
      // if ("navigator" in window && "geolocation" in window.navigator) {
      window.navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
  }, [window?.navigator]);

  return {
    ...location,
    coordinates: {
      ...location.coordinates,
      default: {
        lat: 50.450001,
        lng: 30.523333,
      },
    },
  };
};

export default useGeolocation;
