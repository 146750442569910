import {
  BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

import { API_URL } from "../app/constants";
import type { RootState } from "../app/store";

// type ResultError = {
//   status: number;
//   data?: {
//     message: string;
//   };
// };

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: async (headers, { getState }) => {
    const { token } = (getState() as RootState).globalState;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    headers.set("accept", "application/json");

    return headers;
  },
});

const baseQueryFn: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (url, api, options) => {
  const result = await baseQuery(url, api, options);

  // eslint-disable-next-line no-console
  if (result.error) console.error(`ERROR: ${result.error}`);
  // if (result.error?.status === 401) {
  // const { message } = result.error?.data as { message: string };
  // toast.error(message || "Unauthorized");
  // api.dispatch(saveToken(null));
  // return result;
  // }
  if (result.error?.status === "FETCH_ERROR") toast.error("Fetch Error: Please Check your internet connection");

  return result;
};

export default baseQueryFn;
