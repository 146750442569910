/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-constructed-context-values */
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { store } from "./app/store";

import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as SpinnerIcon } from "./assets/icons/ic_spinner.svg";
import { ReactComponent as Logo } from "./assets/logo.svg";
import "./index.css";

import AnimatedApp from "./animated-app";
import { IMAGES_URL } from "./app/constants";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { setToken } from "./features/globalState";
import { useURL } from "./hooks";
import * as serviceWorker from "./serviceWorker";
import { useGetMainDataQuery } from "./services/qr-services";
import { SessionStorage, safariHeightFix } from "./utils";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN as string,
  //   // integrations: [new BrowserTracing()],
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function App() {
  // const query = new URLSearchParams(window.location.search);
  // const pathname = window.location.pathname.split("/")[2];
  // const getToken = pathname !== "home" && pathname !== "detail" && pathname !== "unauth" ? pathname : null;

  const location = window.location.pathname;
  // const getToken = location.split("/").filter((path) => path)[1] || null;
  const { getToken } = useURL();
  const { table_id, table } = queryString.parse(window.location.search) as { table_id?: string, table?: string };
  // const [carts, setCarts] = useState<CartItems>([]);

  const dispatch = useAppDispatch();
  const { token } = useAppSelector(({ globalState }) => globalState);
  const [isLoading, setLoading] = useState<boolean>(!location.split("/").find((path) => path === "home" || path === "detail"));

  const {
    data: mainData,
    isError: isErrorMainData,
    error: errorMainData,
  } = useGetMainDataQuery(undefined, {
    skip: !token,
  });

  useEffect(() => {
    // const savedCarts = SessionStorage.getValue<CartItems>("carts");
    // if (savedCarts) setCarts(savedCarts);
    dispatch(setToken(getToken() || ""));

    safariHeightFix();
    window.addEventListener("resize", safariHeightFix);

    return () => {
      window.removeEventListener("resize", safariHeightFix);
    };
  }, []);

  useEffect(() => {
    if (errorMainData) {
      setLoading(false);
    }
  }, [isErrorMainData, errorMainData]);

  useEffect(() => {
    if (mainData) {
      setLoading(false);
    }
  }, [mainData]);

  useEffect(() => {
    if (table && table_id) {
      SessionStorage.setValue("dineIn", {
        isDineIn: true,
        table_id,
        table,
      });
    } else {
      SessionStorage.setValue("dineIn", {
        isDineIn: false,
        table_id: null,
        table: null,
      });
    }
  }, []);

  // useEffect(() => {
  //   SessionStorage.setValue("carts", carts);
  // }, [carts]);

  if (isLoading && getToken()) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <SpinnerIcon className="animate-spin h-8 w-8 mx-auto" />
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <ToastContainer position="top-center" />
      {/* <CartContext.Provider value={{ carts, setCarts }}> */}
      <Router>
        <AnimatedApp isUnAuth={!!errorMainData} isDineIn={!!(table && table_id)} />
      </Router>
      {/* </CartContext.Provider> */}

      <div className="hidden lg:flex lg:w-2/3 flex-col gap-4 items-center justify-center h-screen border-l border-gray p8">
        {mainData?.data?.logo ? (
          <img
            src={`${IMAGES_URL + mainData.data.logo}`}
            alt="logo"
            // className="h-32 w-32 object-contain"
            className="h-40 w-40 object-contain"
          />
        ) : (
          <Logo className="w-40 h-40" />
        )}
      </div>
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
