import { createApi } from "@reduxjs/toolkit/query/react";
import type { FormFeedback, FormFeedbackRequest } from "../app/types";
import baseQuery from "./base-service";

export const feedbackServices = createApi({
  reducerPath: "feedback",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getFeedbackForm: builder.query<HttpResponse<FormFeedback>, string | null>({
      query: (lang) => ({
        method: "GET",
        url: `/${lang}/form`,
      }),
    }),
    sendFeedback: builder.mutation<HttpResponse<unknown>, FormFeedbackRequest>({
      query: (body) => ({
        method: "POST",
        url: "/feedback",
        body,
      }),
    }),
  }),
});

export const { useGetFeedbackFormQuery, useSendFeedbackMutation } = feedbackServices;
