import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { CartItem } from "../../app/types";
import type { CartSliceTypes } from "./types";
import { LocalStorage } from "../../utils";

const initialState: CartSliceTypes = {
  carts: LocalStorage.getValue("carts") || [],
  isNewItemAddedToCart: false,
  isCartOpened: false,
  item: null,
};

const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    /**
     * Add item to cart
     * @param {CartSliceTypes} state cart state
     * @param {PayloadAction<CartItem>} parameter
     */
    addToCart: (state: CartSliceTypes, { payload }: PayloadAction<CartItem>) => ({
      ...state,
      carts: [
        ...state.carts,
        payload,
      ],
    }),

    /**
     * Rewrite carts
     * @param {CartSliceTypes} state cart state
     * @param {PayloadAction<CartItem[]>} parameter
     */
    changeCart: (state: CartSliceTypes, { payload }: PayloadAction<CartItem[]>) => ({
      ...state,
      carts: payload,
    }),

    /**
     * Set `item` object with item product
     * @param {CartSliceTypes} state cart state
     * @param {PayloadAction<CartItem | null>} parameter
     */
    setNewItem: (state: CartSliceTypes, { payload }: PayloadAction<CartItem | null>) => ({
      ...state,
      item: payload,
      isNewItemAddedToCart: payload !== null,
    }),

    /**
     * Set `isNewItemAddedToCart` status
     * @param {CartSliceTypes} state cart state
     * @param {PayloadAction<boolean>} parameter
     */
    setIsNewItemAddedToCart: (state: CartSliceTypes, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isNewItemAddedToCart: payload,
    }),

    /**
     * Set cart visibility
     * @param {CartSliceTypes} state cart state
     * @param {PayloadAction<boolean>} parameter
     */
    setCartVisibility: (state: CartSliceTypes, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isCartOpened: payload,
    }),

    /**
     * Increment Item Count
     * @param {CartSliceTypes} state cart state
     * @param {PayloadAction<string>} parameter Item ID
     */
    incrementItemCount: (state: CartSliceTypes, { payload }: PayloadAction<string>) => ({
      ...state,
      carts: state.carts.map((item) => {
        if (item.id === payload) {
          return {
            ...item,
            count: item.count + 1,
          };
        }

        return item;
      }),
    }),

    /**
     * Decrement Item Count
     * @param {CartSliceTypes} state cart state
     * @param {PayloadAction<string>} parameter Item ID
     */
    decrementItemCount: (state: CartSliceTypes, { payload }: PayloadAction<string>) => ({
      ...state,
      carts: state.carts.map((item) => {
        if (item.id === payload) {
          if (item.count <= 1) {
            return item;
          }

          return {
            ...item,
            count: item.count - 1,
          };
        }

        return item;
      }),
    }),

    /**
     * Delete one item
     * @param {CartSliceTypes} state cart state
     * @param {PayloadAction<string>} parameter
     * @returns
     */
    deleteOneItem: (state: CartSliceTypes, { payload }: PayloadAction<string>) => ({
      ...state,
      carts: state.carts.filter(({ id }) => id !== payload),
    }),

    resetCart: (state) => ({
      ...state,
      carts: [],
    }),
  },
});

export const {
  addToCart,
  changeCart,
  setIsNewItemAddedToCart,
  setNewItem,
  setCartVisibility,
  incrementItemCount,
  decrementItemCount,
  deleteOneItem,
  resetCart,
} = slice.actions;

export default slice;
