import { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as MenuIcon } from "../assets/icons/ic_menu.svg";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { LanguageModel } from "../app/types";
import {
  ModalMenuFeedback, ModalMenuLanguage, Portal,
} from "../components";
import { setCurrentLanguage } from "../features/globalState";
import ModalMenu from "./modals/modal-menu";
// import ModalOrderStatus from "./modals/modal-order-status";

interface Props {
  children: ReactNode;
  // languages?: LanguageModel[];
}

function Layout({ children }: Props) {
  const {
    mainData: main, currentLanguage, languageData,
  } = useAppSelector(({ globalState }) => globalState);
  const dispatch = useAppDispatch();

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openMenuLanguage, setOpenMenuLanguage] = useState<boolean>(false);
  const [openMenuFeedback, setOpenMenuFeedback] = useState<boolean>(false);
  // const [openOrderStatus, setOpenOrderStatus] = useState<boolean>(false);
  const {
    push,
    goBack,
    location: { hash },
    // replace,
  } = useHistory();

  const setLanguage = (lang: LanguageModel) => {
    dispatch(setCurrentLanguage(lang));
  };

  useEffect(() => {
    const menu = "#menu";
    if (openMenu) {
      push(menu);
    } else if (hash === menu) {
      goBack();
    }
  }, [openMenu]);

  useEffect(() => {
    if (languageData && currentLanguage === null) {
      const defaultLanguage = languageData.lang_list[0];
      dispatch(setCurrentLanguage(defaultLanguage));
    }
  }, [languageData, currentLanguage]);

  return (
    <>
      {(main?.feedback_is_show || main?.lang_is_show) && (
        <button
          className="absolute top-4 right-4 shadow-button rounded z-20 align-middle px-2 pt-3 pb-1"
          onClick={() => setOpenMenu(true)}
          type="button"
        >
          <MenuIcon
            className="h-6 w-6"
            color="#dc5c4b"
          />
        </button>
      )}

      {children}

      {/* modal menu */}
      <Portal>
        <ModalMenu
          open={openMenu}
          closeMenu={() => setOpenMenu(false)}
          openLanguage={() => setOpenMenuLanguage(true)}
          openFeedback={() => setOpenMenuFeedback(true)}
          // openOrderStatus={() => setOpenOrderStatus(true)}
          language={currentLanguage}
          isShowFeedback={main?.feedback_is_show ?? false}
          isShowLanguage={main?.lang_is_show ?? false}
        />

        {/* modal languages */}
        {main?.lang_is_show && (
          <ModalMenuLanguage
            open={openMenuLanguage}
            close={() => setOpenMenuLanguage(false)}
            onLanguageChange={() => {
              setOpenMenuLanguage(false);
              setOpenMenu(false);
            }}
            language={currentLanguage ?? null}
            languages={languageData?.lang_list ?? []}
            setLanguage={setLanguage}
          />
        )}

        {/* modal feedback */}
        {main?.feedback_is_show && (
          <ModalMenuFeedback
            open={openMenuFeedback}
            close={() => setOpenMenuFeedback(false)}
            language={currentLanguage}
          />
        )}

        {/* Modal Order Status */}
        {/* <ModalOrderStatus
          open={openOrderStatus}
          back={() => setOpenOrderStatus(false)}
        /> */}
      </Portal>
    </>
  );
}

export default Layout;
