import { memo, useEffect, useState } from "react";

import clsx from "clsx";
import { ReactComponent as BackIcon } from "../assets/icons/ic_back.svg";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import type { DiscountTypes, CartItem } from "../app/types";
import { PromoCodeForm } from "./forms";
import { setOrderDiscount } from "../features/orders";

interface PriceSummariesWithCheckoutButtonProps {
  // buttonTitle?: string;
  button?: {
    title?: string;
    type?: "submit" | "button";
  };
  rewriteClassName?: string;
  currency?: string;
  onClickSubmit?: () => void;
  showPromoCodeForm?: boolean;
}

const PriceSummariesWithCheckoutButton = memo(({
  button,
  currency,
  onClickSubmit,
  rewriteClassName,
  showPromoCodeForm,
}: PriceSummariesWithCheckoutButtonProps) => {
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [discount, setDiscount] = useState<DiscountTypes | null>(null);

  const dispatch = useAppDispatch();
  const { carts } = useAppSelector(({ cart }) => cart);
  const { discount: orderDiscount } = useAppSelector(({ orders }) => orders);
  const { currentLanguage } = useAppSelector(({ globalState }) => globalState);

  const isRTL = currentLanguage?.code === "ar";

  const prices = {
    /**
     * Count Prices
     * @param {CartItem} item Cart Item
     * @returns {number} total prices
     */
    countPrices: (item: CartItem): number => {
      let price = item.price.price * item.count;

      for (let i = 0; i < item.modifiers.length; i += 1) {
        for (let j = 0; j < item.modifiers[i].items.length; j += 1) {
          if (item.modifiers[i].items[j].is_selected) {
            const modifier = item.modifiers[i].items[j];

            price += (modifier.price * item.count);
          }
        }
      }

      return price;
    },

    /**
     * Get total prices
     * @returns total prices
     */
    getTotalPrices: () => {
      const arr: number[] = [];

      if (carts.length > 0) {
        carts.forEach((item) => arr.push(prices.countPrices(item)));
      }

      const price = arr.length > 0 ? arr.reduce((a, b) => a + b) : 0.00;

      return price <= 0 ? 0 : Number(price.toFixed(2));
    },

    /**
     * Get total discount
     * @param {"AMOUNT" | "PERCENT"} type discount type
     * @return {number | string} discount
     */
    getTotalDiscount: (type?: "AMOUNT" | "PERCENT"): string | number => {
      const totalPrice = prices.getTotalPrices();
      const totalDiscount = discount ? (
        discount.type === "AMOUNT" ? discount.promo_amount : ((totalPrice / 100) * discount.promo_amount)
      ) : 0;

      return ((discount && discount.type === "PERCENT") && (type && type === "PERCENT")) ? `${discount.promo_amount}%` : Number(totalDiscount.toFixed(2));
    },

    /**
     * Get total price with discount
     * @returns {number} total prices
     */
    getTotalPriceWithDiscount: (): number => {
      const totalPrices = prices.getTotalPrices();
      const totalDiscount = prices.getTotalDiscount("AMOUNT") as number;

      return Number((totalPrices - totalDiscount).toFixed(2));
    },
  };

  useEffect(() => {
    if (discount) {
      dispatch(setOrderDiscount(discount));
    }
  }, [discount]);

  useEffect(() => {
    if (orderDiscount) {
      setDiscount(orderDiscount);
    }
  }, []);

  return (
    <div className={clsx(
      rewriteClassName || "fixed bottom-0 left-0 z-20 w-full lg:w-1/3 bg-white",
    )}
    >
      {/* Promo Code */}
      {showPromoCodeForm && (
        <PromoCodeForm
          defaultValue={discount}
          discount={discount}
          currency={currency}
          setDiscount={setDiscount}
          totalPrice={prices.getTotalPrices()}
        />
      )}

      <div
        className="w-full bg-white transition-all pt-2 mt-2 rounded-t-2xl shadow-card-item"
        style={{ minWidth: 320 }}
      >
        <button
          type="button"
          className="w-full flex items-center justify-center"
          onClick={() => setIsExpand((prevState) => !prevState)}
        >
          <BackIcon className={`h-6 w-6 ${isExpand ? "-rotate-90" : "rotate-90"}`} />
        </button>

        <div className={`w-auto ${isExpand ? "h-full m-4 mb-0 border-b pb-4" : "h-0 overflow-hidden"}`}>
          <div className="flex items-center justify-between text-lg font-semibold mb-1">
            <span className={isRTL ? "font-normal" : ""}>{isRTL ? " المجموع الجزئي" : "Subtotal"}</span>
            <span>
              {currency}
              {" "}
              {prices.getTotalPrices()}
            </span>
          </div>

          {prices.getTotalDiscount() !== 0 && (
            <div className="flex items-center justify-between">
              <span className={isRTL ? "font-normal" : ""}>{isRTL ? "الخصم" : "Discount"}</span>
              <span
                className={`text-sm ${isRTL ? "-translate-x-2" : ""}`}
                style={{
                  direction: isRTL ? "rtl" : "ltr",
                }}
              >
                {isRTL ? (
                  <>
                    {currency}
                    {" "}
                    {prices.getTotalDiscount()}
                    {" "}
                    -
                  </>
                ) : (
                  // <>
                  //   {discount?.type === "AMOUNT" && (
                  //     <>
                  //       -
                  //       {"  "}
                  //       {currency}
                  //     </>
                  //   )}
                  //   {" "}
                  //   {prices.getTotalDiscount(discount?.type === "PERCENT" ? "PERCENT" : "AMOUNT")}
                  // </>
                  <>
                    -
                    {"  "}
                    {currency}
                    {" "}
                    {prices.getTotalDiscount("AMOUNT")}
                  </>
                )}
              </span>
            </div>
          )}
        </div>

        <div className={`w-full flex items-center justify-between text-xl font-semibold ${isExpand ? "p-4" : "p-4 pt-2"}`}>
          <span className={isRTL ? "font-normal" : ""}>{isRTL ? "إجمالي " : "Total"}</span>
          <span>
            {currency}
            {" "}
            {prices.getTotalPriceWithDiscount()}
          </span>
        </div>

        <button
          // type="button"
          // eslint-disable-next-line react/button-has-type
          type={button?.type || "submit"}
          className={`w-full button-primary rounded-none disabled:opacity-50 disabled:cursor-not-allowed ${isRTL ? "font-normal" : ""}`}
          disabled={carts.length === 0}
          onClick={onClickSubmit}
        >
          {button?.title || (isRTL ? "تأكيد الطلب" : "SUBMIT ORDER")}
          {/* {isRTL ? "تأكيد الطلب" : "SUBMIT ORDER"} */}
        </button>
      </div>
    </div>
  );

  // return (
  //   <>
  //     {/* Promo Code */}
  //     {showPromoCodeForm && (
  //       <PromoCodeForm
  //         defaultValue={discount}
  //         discount={discount}
  //         currency={currency}
  //         setDiscount={setDiscount}
  //         totalPrice={prices.getTotalPrices()}
  //       />
  //     )}

  //     <div
  //       className="fixed bottom-0 left-0 z-30 w-full lg:w-1/3 bg-white transition-all pt-2 mt-2 rounded-t-2xl shadow-card-item"
  //       style={{ minWidth: 320 }}
  //     >
  //       <button
  //         type="button"
  //         className="w-full flex items-center justify-center"
  //         onClick={() => setIsExpand((prevState) => !prevState)}
  //       >
  //         <BackIcon className={`h-6 w-6 ${isExpand ? "-rotate-90" : "rotate-90"}`} />
  //       </button>

  //       <div className={`w-auto ${isExpand ? "h-full m-4 mb-0 border-b pb-4" : "h-0 overflow-hidden"}`}>
  //         <div className="flex items-center justify-between text-lg font-semibold mb-1">
  //           <span className={isRTL ? "font-normal" : ""}>{isRTL ? " المجموع الجزئي" : "Subtotal"}</span>
  //           <span>
  //             {currency}
  //             {" "}
  //             {prices.getTotalPrices()}
  //           </span>
  //         </div>

  //         {prices.getTotalDiscount() !== 0 && (
  //           <div className="flex items-center justify-between">
  //             <span className={isRTL ? "font-normal" : ""}>{isRTL ? "الخصم" : "Discount"}</span>
  //             <span
  //               className={`text-sm ${isRTL ? "-translate-x-2" : ""}`}
  //               style={{
  //                 direction: isRTL ? "rtl" : "ltr",
  //               }}
  //             >
  //               {isRTL ? (
  //                 <>
  //                   {currency}
  //                   {" "}
  //                   {prices.getTotalDiscount()}
  //                   {" "}
  //                   -
  //                 </>
  //               ) : (
  //                 <>
  //                   {discount?.type === "AMOUNT" && (
  //                     <>
  //                       -
  //                       {"  "}
  //                       {currency}
  //                     </>
  //                   )}
  //                   {" "}
  //                   {prices.getTotalDiscount(discount?.type === "PERCENT" ? "PERCENT" : "AMOUNT")}
  //                 </>
  //               )}
  //             </span>
  //           </div>
  //         )}
  //       </div>

  //       <div className={`w-full flex items-center justify-between text-xl font-semibold ${isExpand ? "p-4" : "p-4 pt-2"}`}>
  //         <span className={isRTL ? "font-normal" : ""}>{isRTL ? "إجمالي " : "Total"}</span>
  //         <span>
  //           {currency}
  //           {" "}
  //           {prices.getTotalPriceWithDiscount()}
  //         </span>
  //       </div>

  //       <button
  //         type="button"
  //         className={`w-full button-primary rounded-none disabled:opacity-50 disabled:cursor-not-allowed ${isRTL ? "font-normal" : ""}`}
  //         disabled={carts.length === 0}
  //         onClick={onClickSubmit}
  //       >
  //         {isRTL ? "تأكيد الطلب" : "SUBMIT ORDER"}
  //       </button>
  //     </div>
  //   </>
  // );
});

export default PriceSummariesWithCheckoutButton;
