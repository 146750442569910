import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAppSelector } from "../../app/hooks";
import { LanguageModel } from "../../app/types";

import { ReactComponent as CloseIcon } from "../../assets/icons/ic_close.svg";
import { ReactComponent as CallIcon } from "../../assets/icons/ic_phone_outgoing.svg";
import { useURL } from "../../hooks";

interface Props {
  open: boolean;
  closeMenu: () => void;
  openLanguage: () => void;
  openFeedback: () => void;
  // openOrderStatus: () => void;
  language: LanguageModel | null;
  isShowLanguage: boolean;
  isShowFeedback: boolean;
}

type MenuType = {
  onClick: () => void
  text: string
  disabled: boolean
}

function ModalMenu({
  open,
  closeMenu,
  openLanguage,
  openFeedback,
  // openOrderStatus,
  language,
  isShowLanguage,
  isShowFeedback,
}: Props) {
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const main = useAppSelector(({ globalState }) => globalState.mainData);
  const history = useHistory();
  const { getURL } = useURL();

  const lang = language?.code;

  useEffect(() => {
    if (main?.phone_num) {
      const phoneNum = main?.phone_num.split("+")?.[1] ?? main?.phone_num ?? 0;
      setPhoneNumber(phoneNum);
    }
  }, [main]);

  const menus: MenuType[] = [
    {
      onClick: openLanguage,
      disabled: !isShowLanguage,
      text: lang === "ar" ? " اللغة" : "Language",
    },
    {
      onClick: openFeedback,
      disabled: !isShowFeedback,
      text: lang === "ar" ? "رأيكم يهمنا" : "Give Feedback",
    },
    {
      // onClick: openOrderStatus,
      onClick: () => history.push(getURL("order-status")),
      disabled: false,
      text: lang === "ar" ? "حالة الطلب" : "Order Status",
    },
  ];

  return (
    <div
      className={
        `fixed inset-0 lg:right-auto w-full lg:w-1/3 z-10 bg-white flex flex-col justify-start items-center p-12 pt-36 gap-4 transition-opacity duration-200 ease-in hide-scrollbar ${
          open ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"}`
      }
      style={{ minWidth: 320 }}
    >
      <button
        className="absolute top-4 right-4 shadow-button rounded z-20 align-middle p-3"
        onClick={closeMenu}
        title="Close menu"
        type="button"
      >
        <CloseIcon
          className="h-4 w-4"
          color="black"
          title="Close icon"
        />
      </button>

      {menus.map(({ onClick, disabled, text }, index) => (
        <Fragment key={index.toString()}>
          <button
            onClick={onClick}
            className="text-[22px] disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={disabled}
            type="button"
            title={text}
          >
            {text}
          </button>

          {(index !== (menus.length - 1)) && (
            <div className="border-t border-gray w-full" />
          )}
        </Fragment>
      ))}

      {/* {isShowLanguage && ( */}
      {/* <button
        onClick={openLanguage}
        className="font-semibold text-[22px] disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={!isShowLanguage}
        type="button"
      >
        Language */}
      {/* ({language?.code.toLocaleUpperCase() ?? ""}) */}
      {/* {language?.code === "ar" ? "العربية" : "Language"} */}
      {/* </button> */}
      {/* )} */}

      {/* {isShowLanguage && isShowFeedback && <div className="border-t border-gray w-full"></div>} */}
      {/* <div className="border-t border-gray w-full" /> */}

      {/* {isShowFeedback && ( */}
      {/* <button
        onClick={openFeedback}
        className="font-semibold text-[22px] disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={!isShowFeedback}
        type="button"
      >
        Give Feedback
      </button> */}
      {/* )} */}

      <a
        className="button-primary-outline rounded-full flex items-center justify-between absolute bottom-12 self-center w-auto text-sm z-20"
        href={main?.phone_num ? `tel:+${phoneNumber}` : "#"}
        title={lang === "ar" ? "اتصل بنا" : "Call Us"}
      >
        <CallIcon className="h-4 w-4 mr-4" />
        {lang === "ar" ? "اتصل بنا" : "Call Us"}
      </a>
    </div>
  );
}

export default ModalMenu;
