import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as BackIcon } from "../../assets/icons/ic_back.svg";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import type { DiscountTypes, IsDineIn } from "../../app/types";
import { PriceSummariesWithCheckoutButton } from "../../components";
import { ShoppingItemCard } from "../../components/cards";
import { setOrderDiscount } from "../../features/orders";
import type { OrderTypes } from "../../features/orders/types";
import { useURL } from "../../hooks";
import { SessionStorage } from "../../utils";

function CheckoutPage() {
  const { currentLanguage, homeData } = useAppSelector(({ globalState }) => globalState);
  const { data, isSubmitted, discount: orderDiscount } = useAppSelector(({ orders }) => orders);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { getURL } = useURL();

  const [discount, setDiscount] = useState<DiscountTypes | null>(null);

  const dineIn = SessionStorage.getValue<IsDineIn>("dineIn") || null;
  const isRTL = currentLanguage?.code === "ar";
  const currency = homeData?.currency || "USD";

  const orders: OrderTypes[] = useMemo(() => [...(data?.orders as OrderTypes[] || [])], [data]);

  const prices = {
    /**
     * Count Prices
     * @param {CartItem} item Cart Item
     * @returns {number} total prices
     */
    countPrices: (item: OrderTypes): number => {
      let price = item.price_unit * item.qty;

      item.modifiers.forEach((modifier) => {
        price += (modifier.price_unit * item.qty);
      });

      return price;
    },

    /**
     * Get total prices
     * @returns total prices
     */
    getTotalPrices: () => {
      const arr: number[] = [];

      if (orders.length > 0) {
        for (let i = 0; i < orders.length; i += 1) {
          arr.push(prices.countPrices(orders[i]));
        }
      }

      const price = arr.length > 0 ? arr.reduce((a, b) => a + b) : 0.00;

      return price <= 0 ? 0 : Number(price.toFixed(2));
    },

    /**
     * Get total discount
     * @param {"amount" | "percentage"} type discount type
     * @return {number | string} discount
     */
    getTotalDiscount: (type?: "AMOUNT" | "PERCENT"): string | number => {
      const totalPrice = prices.getTotalPrices();
      const totalDiscount = discount ? (
        discount.type === "AMOUNT" ? discount.promo_amount : ((totalPrice / 100) * discount.promo_amount)
      ) : 0;

      return ((discount && discount.type === "PERCENT") && (type && type === "PERCENT")) ? `${discount.promo_amount}%` : Number(totalDiscount.toFixed(2));
    },

    /**
     * Get total price with discount
     * @returns {number} total prices
     */
    getTotalPriceWithDiscount: (): number => {
      const totalPrices = prices.getTotalPrices();
      const totalDiscount = prices.getTotalDiscount("AMOUNT") as number;

      return Number((totalPrices - totalDiscount).toFixed(2));
    },
  };

  useEffect(() => {
    if (discount) {
      dispatch(setOrderDiscount(discount));
    }
  }, [discount]);

  useEffect(() => {
    if (dineIn?.isDineIn && !isSubmitted) {
      history.goBack();
    }

    if (orderDiscount) {
      setDiscount(orderDiscount);
    }
  }, []);

  return (
    <div
      className="flex flex-col w-full h-screen pb-[200px] overflow-x-hidden pt-6 relative overflow-y-auto hide-scrollbar"
      style={{ direction: isRTL ? "rtl" : "ltr" }}
    >
      <div className="relative flex flex-row justify-center items-center w-full h-11">
        <button
          className="absolute p-3 top-0 left-4 shadow-button rounded z-20"
          onClick={() => history.goBack()}
          type="button"
          title="Back to menu"
        >
          <BackIcon className="h-4 w-4" />
        </button>

        <h2 className="text-[22px]">
          {dineIn?.isDineIn ? `Table ${dineIn.table}` : (isRTL ? " تأكيد الطلب" : "Finish Order")}
        </h2>
      </div>

      <div className="flex flex-col gap-4 w-full p-4 mt-10 min-h-[45vh] mb-8">
        {orders.map((item) => (
          <ShoppingItemCard
            key={item.item_id}
            name={item.name}
            price={prices.countPrices(item)}
            note={item.notes}
            currency={currency || "USD"}
            modifiers={item.modifiers}
            quantity={item.qty}
            isRTL={isRTL}
            className="mb-0"
            options={{
              showImage: false,
              showModifier: false,
              showCounterButtons: false,
              showOptionButtons: false,
              showNotes: false,
            }}
          />
        ))}
      </div>

      <PriceSummariesWithCheckoutButton
        button={{
          type: "button",
        }}
        currency={currency}
        onClickSubmit={() => history.push(getURL("final-checkout"))}
        showPromoCodeForm
      />
    </div>
  );
}

export default CheckoutPage;
