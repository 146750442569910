import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { LocalStorage, SessionStorage } from "../utils";
// Features
import cacheSlice from "../features/cache/slice";
import { CartSlice, setIsNewItemAddedToCart } from "../features/cart";
import globalSlice from "../features/globalState/slice";
import orderSlice from "../features/orders/slice";
// Services
import { feedbackServices, orderServices, qrServices } from "../services";

const reducer = combineReducers({
  // Add your reducers here
  cache: cacheSlice.reducer,
  globalState: globalSlice.reducer,
  orders: orderSlice.reducer,
  [CartSlice.name]: CartSlice.reducer,
  [qrServices.reducerPath]: qrServices.reducer,
  [feedbackServices.reducerPath]: feedbackServices.reducer,
  [orderServices.reducerPath]: orderServices.reducer,
});

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    // Add your middleware here
    qrServices.middleware,
    feedbackServices.middleware,
    orderServices.middleware,
  ],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

store.subscribe(() => {
  const {
    // token,
    savedBannerUrl,
    savedCategory,
    savedLogoUrl,
    savedLanguage,
  } = store.getState().cache;
  const { isNewItemAddedToCart, carts } = store.getState().cart;
  const { currency } = store.getState().globalState;

  // if (token) {
  //   SessionStorage.setValue("token", token);
  // } else {
  //   SessionStorage.setValue("token", null);
  // }

  if (savedBannerUrl) {
    SessionStorage.setValue("bannerUrl", savedBannerUrl);
  } else {
    SessionStorage.setValue("bannerUrl", null);
  }

  if (savedCategory) {
    SessionStorage.setValue("category", savedCategory);
  } else {
    SessionStorage.setValue("category", null);
  }

  if (savedLogoUrl) {
    SessionStorage.setValue("logoUrl", savedLogoUrl);
  } else {
    SessionStorage.setValue("logoUrl", null);
  }

  if (savedLanguage) {
    SessionStorage.setValue("lang", savedLanguage);
  } else {
    SessionStorage.setValue("lang", null);
  }

  if (isNewItemAddedToCart) {
    setTimeout(() => {
      store.dispatch(setIsNewItemAddedToCart(false));
    }, 4000);
  }

  if (carts.length > 0) {
    LocalStorage.setValue("carts", carts);
  } else {
    LocalStorage.setValue("carts", []);
  }

  if (currency) {
    SessionStorage.setValue("currency", currency);
  } else {
    SessionStorage.setValue("currency", "USD");
  }
});
