import clsx from "clsx";
import {
  useEffect,
  useMemo,
  useState,
  type ChangeEvent,
} from "react";
import { useHistory } from "react-router-dom";

import queryString from "query-string";
import { ReactComponent as BackIcon } from "../assets/icons/ic_back.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/ic_close_bordered.svg";
import { ReactComponent as OrderNotFoundIcon } from "../assets/icons/ic_order_status_empty.svg";
import { ReactComponent as CallIcon } from "../assets/icons/ic_phone_outgoing.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/ic_search.svg";

import { STAT_ORDER } from "../app/constants";
import { useAppSelector } from "../app/hooks";
import { OrderStatusCard } from "../components/cards";
import { useDebounce } from "../hooks";
import { useGetOrderStatusQuery } from "../services";

function OrderStatusPage() {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [orderNumber, setOrderNumber] = useState<string>("");

  const { mainData: main, currentLanguage } = useAppSelector(({ globalState }) => globalState);
  const debouncedOrderNumber = useDebounce(orderNumber, 500);
  const history = useHistory();

  const { data, refetch } = useGetOrderStatusQuery(Number.parseInt(debouncedOrderNumber, 10), {
    skip: !debouncedOrderNumber,
  });

  const isRTL = currentLanguage?.code === "ar";
  const lang = currentLanguage?.code;
  const query = queryString.parse(history.location.search || window.location.search);

  const mergedData = useMemo(() => data?.data?.map((item) => {
    const getStatTitle = STAT_ORDER.find(({ id }) => id === item.id)?.title || null;

    if (getStatTitle) {
      return {
        ...item,
        title: getStatTitle,
      };
    }

    return {
      ...item,
      title: item.id,
    };
  }) || [], [data]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const regex = /^[0-9]+$/g;

    if (value.length > 0 && !regex.test(value)) return;

    setOrderNumber(() => value);
  };

  useEffect(() => {
    if (main?.phone_num) {
      const phoneNum = main?.phone_num.split("+")?.[1] ?? main?.phone_num ?? 0;
      setPhoneNumber(phoneNum);
    }
  }, [main]);

  useEffect(() => {
    refetch();
  }, [debouncedOrderNumber]);

  useEffect(() => {
    if ("id" in query) {
      setOrderNumber(query.id as unknown as string);
    }
  }, []);

  return (
    <div
      className={
        clsx(
          "w-full relative",
          "bg-white overflow-y-auto hide-scrollbar",
          "flex flex-col justify-start items-center gap-4",
          "pb-12 px-[18px] pt-6",
        )
      }
    >
      <button
        className="absolute p-3 top-4 left-4 shadow-button rounded z-20"
        onClick={() => {
          setOrderNumber("");
          history.goBack();
        }}
        type="button"
        title="Back to menu"
      >
        <BackIcon className="h-4 w-4" />
      </button>

      {/* Content */}
      <h2 className="text-[22px]">
        {isRTL ? "حالة الطلب" : "Order Status"}
      </h2>
      <p className="text-center mt-2">
        {isRTL ? "يمكن الحصول على رقم الطلب من الايميل المرسل  " : "You can find your order number in the e-mail we've sent to you"}
      </p>

      <section id="input-group" className="relative w-full flex items-center mt-4">
        {orderNumber.length === 0 && (
          <label
            htmlFor="order-number"
            className={
              `text-xs absolute top-2 ${isRTL ? "right-5" : "left-5"} tracking-[0.4px]`
            }
            style={{
              direction: isRTL ? "rtl" : "ltr",
            }}
          >
            {isRTL ? "رقم الطلب :" : "Order number :"}
          </label>
        )}

        <input
          type="text"
          id="order-number"
          onChange={handleOnChange}
          className="input-base py-6 px-5 w-full"
          style={{
            direction: isRTL ? "rtl" : "ltr",
          }}
          value={orderNumber}
        />

        {orderNumber.length === 0 ? (
          <button
            type="button"
            title="Search"
            className={`absolute ${isRTL ? "left-5" : "right-5"}`}
          >
            <SearchIcon className="h-[18px] w-[18px]" />
          </button>
        ) : (
          <button
            type="button"
            title="Clear"
            className={`absolute ${isRTL ? "left-5" : "right-5"}`}
            onClick={() => {
              setOrderNumber("");
              history.replace("order-status");
            }}
          >
            <CloseIcon className="h-5 w-5" />
          </button>
        )}
      </section>

      {/* <div className="p-6 w-full h-full min-h-[calc(100vh-320px)]"> */}
      {data ? (data.data ? (
        <div className="p-6 w-full h-full min-h-[calc(100vh-360px)]">
          <OrderStatusCard data={mergedData} lang={lang || "en"} />
        </div>
      ) : (
        <div className="p-6 w-full h-full min-h-[calc(100vh-360px)] flex items-center">
          <div className="h-full my-auto text-center flex flex-col items-center justify-center">
            <OrderNotFoundIcon className="mb-4" />
            <p className="text-[#A6A6A6]">
              {isRTL ? "لم يتم العثور على طلب بالرقم المدخل" : "We couldn't find an order with that number"}
            </p>
          </div>
        </div>
      )) : (
        <div className="p-6 w-full h-full min-h-[calc(100vh-360px)]" />
      )}
      {/* </div> */}
      {/* End: Content */}

      <a
        className="button-primary-outline rounded-full flex items-center justify-between self-center w-auto text-sm z-20"
        // className="button-primary-outline rounded-full flex items-center justify-between absolute bottom-12 self-center w-auto text-sm z-20"
        href={main?.phone_num ? `tel:+${phoneNumber}` : "#"}
        title={isRTL ? "اتصل بنا" : "Call Us"}
      >
        <CallIcon className="h-4 w-4 mr-4" />
        {isRTL ? "اتصل بنا" : "Call Us"}
      </a>
    </div>
  );
}

export default OrderStatusPage;
