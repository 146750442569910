/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from "react";

import type { FeedbackQuestionRequest, FormFeedback, LanguageModel } from "../../app/types";
import { FeedbackAnswerForms } from "../forms";
import ModalStatus from "./modal-status";

import { useGetFeedbackFormQuery, useSendFeedbackMutation } from "../../services";

interface MenuFeedbackProps {
  open: boolean;
  close: () => void;
  language: LanguageModel | null;
}

function ModalMenuFeedback({ open, close, language }: MenuFeedbackProps) {
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<FormFeedback>();
  const [answerForm, setAnswerForm] = useState<FeedbackQuestionRequest[]>([]);

  const [sendFeedback] = useSendFeedbackMutation();
  const { data: feedbackQuestions } = useGetFeedbackFormQuery(language?.code ?? null, {
    skip: !language,
  });

  const submitFeedback = () => {
    if (!dataForm) return;

    const blankAnswer = answerForm.filter((form) => {
      if (form.answer === "") {
        const isRequired = dataForm.questions.find(
          ({ question_id, required }) => question_id === form.q_id && required,
        );

        return isRequired;
      }

      return false;
    });

    if (blankAnswer.length > 0) {
      console.log("found blank!");

      return;
    }

    sendFeedback({
      form_id: dataForm.form_id,
      form_ver: dataForm.form_ver,
      createdIn: new Date().toISOString(),
      datas: answerForm,
    })
      .unwrap()
      .then(() => {
        setSuccess(true);

        setTimeout(() => {
          setSuccess(false);
          close();
        }, 4000);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!language) return;
    if (feedbackQuestions) setDataForm(feedbackQuestions.data);
  }, [language, feedbackQuestions]);

  useEffect(() => {
    if (!open && dataForm) {
      // reset states
      setAnswerForm(
        dataForm.questions.map((quest) => ({
          q_id: quest.question_id,
          answer: "",
        })),
      );
    }
  }, [dataForm, open]);

  return (
    <>
      <div
        className={`fixed top-0 lg:right-auto w-full lg:w-1/3 z-30 bg-white flex flex-col justify-start sm:justify-center items-center p-4 gap-4 transition-opacity duration-200 ease-in overflow-y-auto h-full pt-16 hide-scrollbar ${
          open ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
        }`}
        style={{ minWidth: 320 }}
      >
        {/* <button className="absolute p-3 top-4 left-4 shadow-button rounded z-20" onClick={close}>
          <BackIcon className="h-4 w-4" />
        </button> */}
        <div className="flex flex-col gap-4 w-full text-center mb-8">
          <div className="text-center min-h-fit">
            <p className="font-semibold mb-4 text-xl">{dataForm?.header}</p>

            {dataForm?.questions.map((quest, index) => (
              <div
                key={`${quest.question_id}-${index.toString()}`}
                className="mb-1"
              >
                <p className="font-medium mb-2">
                  {quest.question}
                  {" "}
                  {quest.required && <span className="text-active">*</span>}
                </p>

                <FeedbackAnswerForms
                  type={quest.type}
                  id={quest.question_id}
                  answers={answerForm}
                  setAnswers={setAnswerForm}
                />
              </div>
            ))}
          </div>

          {/* if language is english */}
          {language?.code === "en" && (
            <>
              <button
                className="button-primary mt-0"
                onClick={submitFeedback}
                type="button"
              >
                Send Feedback
              </button>
              <button
                type="button"
                className="button-default-outline"
                onClick={close}
              >
                Cancel
              </button>
            </>
          )}

          {/* if language is russia */}
          {language?.code === "ru" && (
            <>
              <button
                className="button-primary mt-0"
                onClick={submitFeedback}
                type="button"
              >
                Отправить отзыв
              </button>
              <button
                type="button"
                className="button-default-outline"
                onClick={close}
              >
                Отмена
              </button>
            </>
          )}

          {/* if language is ukrainian */}
          {(language?.code === "ua" || language?.code === "uk") && (
            <>
              <button
                className="button-primary mt-0"
                onClick={submitFeedback}
                type="button"
              >
                Надіслати відгук
              </button>
              <button
                type="button"
                className="button-default-outline"
                onClick={close}
              >
                Скасувати
              </button>
            </>
          )}

          {/* if language is arabic */}
          {language?.code === "ar" && (
            <>
              <button
                className="button-primary mt-0"
                onClick={submitFeedback}
                type="button"
              >
                ارسل رأيك
              </button>
              <button
                type="button"
                className="button-default-outline"
                onClick={close}
              >
                الغاء
              </button>
            </>
          )}
        </div>
      </div>

      {isSuccess && (
        <ModalStatus
          type="feedback"
          language={language || null}
          close={() => {
            setSuccess(false);
            close();
          }}
        />
      )}
    </>
  );
}

export default ModalMenuFeedback;
