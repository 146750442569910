import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { InitialOrderState, OrderTypes } from "./types";
import { DiscountTypes } from "../../app/types";

const initialState: InitialOrderState = {
  isSubmitted: false,
  discount: null,
  data: {
    orders: [] as OrderTypes[],
  },
};

const slice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetOrders: (state: InitialOrderState) => ({
      ...state,
      data: {
        orders: [],
      },
    }),

    /**
     * Submit Order for checkout
     * @param {InitialOrderState} state order state
     * @param {PayloadAction<boolean>} parameter
     */
    submitOrder: (state: InitialOrderState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isSubmitted: payload,
    }),

    /**
     * Set Discount Variables
     * @param {InitialOrderState} state order state
     * @param {PayloadAction<DiscountTypes>} parameter
     */
    setOrderDiscount: (state: InitialOrderState, { payload }: PayloadAction<DiscountTypes | null>) => ({
      ...state,
      discount: payload !== null ? (
        state.discount ? ({
          ...state.discount,
          ...payload,
        }) : payload
      ) : null,
    }),

    /**
     * Set Data Properties
     * @param {InitialOrderState} state
     * @param {PayloadAction<{ [key: string]: string | number[] | OrderTypes[] }>} parameter
     */
    setData: (state: InitialOrderState, { payload }: PayloadAction<{
      [key: string]: string | number[] | OrderTypes[] }>) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),

    /**
     * Set Order array
     * @param {InitialOrderState} state order state
     * @param {PayloadAction<OrderTypes[]>} parameter
     */
    setOrders: (state: InitialOrderState, { payload }: PayloadAction<OrderTypes[]>) => ({
      ...state,
      data: {
        ...state.data,
        orders: payload,
      },
    }),
  },
});

export const {
  resetOrders, submitOrder, setOrderDiscount, setData, setOrders,
} = slice.actions;
export default slice;
