import { Fragment } from "react";

import { LanguageModel } from "../../app/types";
// import { ReactComponent as BackIcon } from "../assets/icons/ic_back.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/ic_close.svg";

interface Props {
  open: boolean;
  close: () => void;
  languages: LanguageModel[]; // list of language
  language: LanguageModel | null;
  setLanguage: (arg0: LanguageModel) => void;
  onLanguageChange: () => void;
}

function ModalMenuLanguage({
  open,
  close,
  language,
  languages,
  setLanguage,
  onLanguageChange,
}: Props) {
  return (
    <div
      className={
        `fixed inset-0 lg:right-auto w-full lg:w-1/3 z-30 bg-white flex flex-col justify-start items-center p-12 pt-36 gap-4 transition-opacity duration-200 ease-in ${
          open ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"}`
      }
      style={{ minWidth: 320 }}
    >
      <button
        className="absolute p-3 top-4 right-4 shadow-button rounded z-20"
        onClick={close}
        title="Back to menu"
        type="button"
      >
        <CloseIcon className="h-4 w-4" />
      </button>

      {languages.map((lang, index) => (
        <Fragment key={lang.code}>
          <button
            type="button"
            onClick={() => {
              onLanguageChange();
              setLanguage(lang);
            }}
            className={`text-[22px] ${lang.code === language?.code ? "text-active" : "text-black"}`}
          >
            {lang.code === "ar" ? "العربية" : lang.name}
          </button>

          {((languages.length - 1) !== index) && <div className="border-t border-gray w-full" />}
        </Fragment>
      ))}
    </div>
  );
}

export default ModalMenuLanguage;
