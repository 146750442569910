import type { ReactNode } from "react";
import { createPortal } from "react-dom";

type PortalProps = {
  children: ReactNode;
  containerId?: string;
}

function Portal({ children, containerId }: PortalProps) {
  const portalRoot = document.getElementById(containerId || "portal-root");
  // const portalRoot = containerId ? document.getElementById(containerId) : document.getElementById("portal-root");

  if (portalRoot) return createPortal(children, portalRoot);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

export default Portal;
