/* eslint-disable react/jsx-props-no-spreading */
import clsx from "clsx";

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  showIcon?: boolean;
  icon?: any;
  label: string;
  isRTL?: boolean;
}

type TextAreaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
  showIcon?: boolean;
  icon?: any;
  label: string;
  isRTL?: boolean;
}

function Input({
  showIcon, icon: Icon, label, id, className, isRTL, ...props
}: InputProps) {
  return (
    <label
      htmlFor={id}
      className={clsx(
        "relative flex items-center",
        isRTL ? "flex-row-reverse" : "flex-row",
        className,
      )}
      style={{
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      {showIcon && (
        <div className={clsx("w-6 h-6 mr-2", "absolute", isRTL ? "right-4" : "left-4")}>
          {Icon && <Icon className={clsx("w-6 h-6")} />}
        </div>
      )}

      <label htmlFor={id} className={clsx("absolute z-0 top-2", "text-[12.84px] leading-[17.12px]", showIcon ? (isRTL ? "right-14" : "left-14") : (isRTL ? "right-4" : "left-4"))}>{label}</label>

      <input
        id={id}
        {...props}
        className={clsx(
          "input-base pr-4 py-7",
          // "input-base pr-4 pb-4 pt-8",
          showIcon ? (isRTL ? "pr-14" : "pl-14") : (isRTL ? "pr-4" : "pl-4"),
        )}
      />

      <span className={clsx("absolute z-0 top-2", isRTL ? "left-4" : "right-4", "text-secondary text-[12.5px] leading-[17.12px]")}>
        {props.required ? (isRTL ? "مطلوب" : "Required") : (isRTL ? "اختياري" : "Optional")}
      </span>

    </label>
  );
}

function TextArea({
  showIcon, icon: Icon, label, id, className, isRTL, ...props
}: TextAreaProps) {
  return (
    <label
      htmlFor={id}
      className={clsx(
        "relative flex flex-row items-center",
        isRTL ? "flex-row-reverse" : "flex-row",
        className,
      )}
      style={{
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      {showIcon && (
        <div className={clsx(
          "w-6 h-6 mr-2",
          "absolute",
          isRTL ? "right-4" : "left-4",
        )}
        >
          {Icon && <Icon className={clsx("w-6 h-6")} />}
        </div>
      )}

      <label
        htmlFor={id}
        className={clsx(
          "absolute z-0 top-2",
          "text-[12.84px] leading-[17.12px]",
          showIcon ? (isRTL ? "right-14" : "left-14") : (isRTL ? "right-4" : "left-4"),
        )}
      >
        {label}

      </label>

      <textarea
        id={id}
        {...props}
        className={clsx(
          "input-base pr-4 py-7",
          // "input-base pr-4 pb-4 pt-8",
          showIcon ? "pl-14" : "pl-4",
        )}
        rows={4}
      />

      <span className={clsx("absolute z-0 top-2", isRTL ? "left-4" : "right-4", "text-secondary text-[12.5px] leading-[17.12px]")}>
        {props.required ? (isRTL ? "مطلوب" : "Required") : (isRTL ? "اختياري" : "Optional")}
      </span>

    </label>
  );
}

export {
  Input,
  TextArea,
};
